import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  ThumbUp as ThumbUpIcon,
} from "@material-ui/icons";

import classNames from "classnames";
import { Badge, Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";

import UserAvatar from "../UserAvatar/UserAvatar";
import useStyles from "./styles";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

import config from '../../config/config';



// var notifications = [];
// var notification = JSON.parse(localStorage.getItem('notification')) || [];
// var errorData = JSON.parse(localStorage.getItem('errorMsg'));
// notification.map((val, inz) => {
//   notifications.push({
//     id: inz,
//     color: "success",
//     type: "alert",
//     message: errorData[val]
//   })
// });

export default function Header(props) {
  var classes = useStyles();
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  var [notifications, setNotifications] = useState([]);
  var data = JSON.parse(localStorage.getItem('data'));
  var roleMenu = JSON.parse(localStorage.getItem('roleMenu'));
  var notification = JSON.parse(localStorage.getItem('notification')) || [];
  var errorData = JSON.parse(localStorage.getItem('errorMsg'));
  var email = '';
  var firstName = '';
  var lastName = '';
  var tempNotif = [];
  notification.map((val, inz) => {
    tempNotif.push({
      id: inz,
      color: "success",
      type: "alert",
      message: errorData[val]
    })
  });
  useEffect(() => {
    setNotifications(tempNotif);
  }, []);




  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => {
            toggleSidebar(layoutDispatch)
          }}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Clarity Insight
          </Typography>
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
          // onClick={() => setSearchOpen(!isSearchOpen)}
          >
            {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>

        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >

        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >


          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
              <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>


        <Menu
          id="notifications-menu"
        
          open={ notification.length != 0 && Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}

          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
     
              className={classes.headerMenuItem}

            >
              <Notification {...notification} typographyVariant="inherit" />
              
           
            </MenuItem>
          ))}
        </Menu>


        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h3" weight="medium">
              {data.map((name) => {
                firstName += name.first_name
                lastName += name.last_name
              })}
              {firstName + ' ' + lastName}
            </Typography>
            <Typography>
              {'(' + roleMenu.roles.find((val) => {
                if (val.id == data[0]['role_id']) {
                  return val.role_name;
                }
              })['role_name'] + ')'}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              href="#"
            >
              {
                data.map((test) => {
                  email += test.email
                })
              }
              {email}

            </Typography>
          </div>






          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
              </Typography>
          </div>
        </Menu>

      </Toolbar>
    </AppBar>
  );
}
