import React, {  useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import DialogActions from '@material-ui/core/DialogActions';
// import Container from '@material-ui/core/Container';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import { ClientSide } from "../../context/clientContext";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import Td from '@material-ui/core/Td';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

//
import axios from 'axios';
import superagent from 'superagent';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import DialogContentText from '@material-ui/core/DialogContentText';
import CommonFunction from '../../lib/commonFunction';
// import Portal from '@material-ui/core/Portal';
import Fade from '@material-ui/core/Fade';
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import CancelIcon from '@material-ui/icons/Cancel';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'copy-to-clipboard';
// import AirplanemodeActiveSharpIcon from '@material-ui/icons/AirplanemodeActiveSharp';
// import AirplanemodeInactiveSharpIcon from '@material-ui/icons/AirplanemodeInactiveSharp';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import config from '../../config/config';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';




const marketCountry1 = [
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'US',
    label: 'United States',
  },
  {
    value: 'UK',
    label: 'United Kingdom',
  },
  {
    value: 'LK',
    label: 'Srilanka',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
];

const currencies = [
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'GDP',
    label: 'GDP',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'AUS',
    label: 'AUS',
  },
  {
    value: 'LKR',
    label: 'LKR',
  },



];


const useStyles = makeStyles(theme => ({
  fab: {
    //   margin: theme.spacing(1),
    size: 'small',
    variant: 'extended',
    position: 'absolute',
    top: theme.spacing(9),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginLeft: theme.spacing(10),

  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: 200,
  // },
  menu: {
    width: 200,
    maxHeight: 200,
  },
  root1: {
    width: '100%',
    paddingTop:'22px',
    paddingBottom:'26px',
    paddingLeft:'16px',
    
    // overflowX: 'auto',
  },
  grid: {
    overflowX: "auto",

  },
  dialog: {
    marginTop: "6%",
  },
 
  AuthButton:{
    margin: theme.spacing(3, 1),
    right: theme.spacing(2),
    top: theme.spacing(10),
    cursor: 'Pointer',
   
  },
divider:{
  margin: theme.spacing(3, 0),
},
header:{
  margin: theme.spacing(0, 2),
},
CancelIcon:{
   
  variant: 'extended',
  position: 'absolute',
  // top: theme.spacing(1),
  right: theme.spacing(2),
  '&:hover': {
    // backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  cursor: 'Pointer',
},
button: {
  left: theme.spacing(1),
  // margin: theme.spacing(1, 35),
  // alignItems:'Center',

  '&:hover': {
    // backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  cursor: 'Pointer',
},

Pointer: {
  cursor: 'Pointer',
},
tableCell:{
  paddingRight: 4,
  paddingLeft: 5,
  paddingTop:10,
  paddingBottom:10
},
table: {
  // display: 'block',
  // width: '100%',
  // overflowX: 'auto',
  // minWidth: 100,
borderCollapse:'collapse'
},

}));


export default function Client(props) {
  var userDispatch = useUserDispatch();
  const classes = useStyles();
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [state, setState] = React.useState({
    isActive: 1
  });




  //
  var [data, setData] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [targetElement, setTargetElement] = React.useState();
  const [alertMsg ,setAlertMsg] =  React.useState(false)
  const [alertUser ,setAlertUser] =  React.useState(false)


  const [rows, setRows] = React.useState({
    client_id: null,
    client_name: '',
    client_code: '',
    description: '',
    market_country: '',
    allowed_currency: '',
    // is_active: '',
    // items: [],
  });


  const [edit, setEdit] = React.useState({
    client_id1: null,
    client_name1: '',
    client_code1: '',
    description1: '',
    market_country1: '',
    allowed_currency1: '',
    authKey:'',
    is_active1: '',
  });

  const [client_id1, setClient_id1] = React.useState({
    client_id1: ''
  })
  const [authKey1, setAuthKey1] = React.useState('');
  const [error, setError] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [ copyShow , setCopyShow ] =React.useState(false);
  const [alertDelete, setAlertDelete] = React.useState('')
  const [alertAdd, setAlertAdd] = React.useState('')
  
  

  // const [state1, setState1] = React.useState({
  //   isActive1:0
  // });

  const Authkey  = () => {
    setCopyShow(false)
    superagent.post(
      config.apiUrl+'/api/Flight/updateAuthKey')
      .set('login-key', localStorage.getItem('token'))
      .send({
        "clientId":client_id1,
      })
      .then((response) => {
        if(response.body.status === true){
              setAuthKey1(response.body.authKey)
        }
        else{
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
      
            alert(errorData[response.body.message])
            signOut(userDispatch, props.history)
             }
             else if(response.body.status === false){
              alert(errorData[response.body.message])
              fetchData();
              }
        }
      })
      
      // if(!show)
      // {
      //   setShow(!show);
      // }
      setShow(true);

   


      
      

  };
  const copyFn = (e) =>{
    show?copy(authKey1):copy(edit.authKey)
    
    setCopyShow(true)

  

  }

 
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleCloseInit = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertMsg(false);
  }
  const handleCloseAdd = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertUser(false);
  }

     

  const handleChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    setRows({
      ...rows,
      [name]: value
    })
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

 


  const handleClose = () => {
    setOpen(false);
    setError(false)
    setRows({
      // client_id: null,
      ...rows,
      client_name: '',
      client_code: '',
      description: '',
      market_country: '',
      allowed_currency: '',
    })
  };



  const activefn = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };




  const activefn1 = name => event => {
    setEdit({ ...edit, [name]: event.target.checked });
  };




  const handleClickOpenAlert = (e) => {
    setTargetElement(e.target);
    setOpenAlert(true);
  };
  
  const handleClose2 = () => {
    setOpenAlert(false);
  };


  const handleClose3 = () => {
    setOpen1(false);
    setShow(false);
    setCopyShow(false)
   
  };

  


  const fetchData = async () => {
    const result = await axios(config.apiUrl+'/api/Flight/clientDetails',
    { headers: { 'login-key': localStorage.getItem('token') } }
    );
    if(result.data.status === true){

      setData(result.data.data)
    }
    else{
      
      if(result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4'|| result.data.message === 'ERMWMSG2'|| result.data.message === 'ERMWMSG5' ){
 
        alert(errorData[result.data.message])
     signOut(userDispatch, props.history)
      }
      else if(result.data.status === false){
        alert(errorData[result.data.message])
       props.history.goBack();
      
       }
      }
  };
  useEffect(() => {
    fetchData();

  }, []);
    


const addClient = (e) => {
    e.preventDefault();

if(rows.description.length  === ''){
  setError(true)
}
else if(rows.client_code.length  === ''){
  setError(true)
}
else if(rows.client_name.length  === ''){
  setError(true)
}
else if(rows.market_country.length  === ''){
  setError(true)
}
else if(rows.allowed_currency.length  === ''){
  setError(true)
}


else {

  setError(false)
    superagent.post(
      config.apiUrl+'/api/Flight/addClient')
      .set('login-key', localStorage.getItem('token'))
      .send({
        "clientName": rows.client_name,
        "description": rows.description,
        "clientCode": rows.client_code,
        "marketCountry": rows.market_country,
        "allowedCurrency": rows.allowed_currency,
        "isActive": state.isActive ? 1 : 0
      })
      .then((response) => {
        if (response.body.status === true) {
          var text = JSON.parse(response.text)
          data.push(text.data[0]);
          setAlertMsg(true)
          setAlertAdd(errorData[response.body.message])
          setData([...data]);
          // fetchData();        
        }else{
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
       
      
            alert(errorData[response.body.message])
            signOut(userDispatch, props.history)
             }
             else if(response.body.status === false){
              alert(errorData[response.body.message])
              handleClose();
              }
        }
        
      
        
      })
      handleClose();
    }
    
      
      setRows({
        // client_id: null,
        ...rows,
        client_name: '',
        client_code: '',
        description: '',
        market_country: '',
        allowed_currency: '',
      })
    
    }
    
    const deleteUser = (e) => {
      e.preventDefault();
      superagent.post(
        config.apiUrl+'/api/Flight/deleteClient')
        .set('login-key', localStorage.getItem('token'))
        .send({
          "clientId": CommonFunction.elementSelector(targetElement, 'td').getAttribute('value')
        })
        .then((response) => {
          if(response.body.status === true){
            setAlertUser(true)
            setAlertDelete(errorData[response.body.message])
            fetchData();
          }
          else{
            if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
          
        
              alert(errorData[response.body.message])
              signOut(userDispatch, props.history)
               }
               else if(response.body.status === false){
                alert(errorData[response.body.message])
                fetchData();
                }
              }
        })
      handleClose2();
    }
  
    
      const editUser = (e) => {
        e.preventDefault();
        superagent.post(config.apiUrl+'/api/Flight/clientDetails')
        .send({
          "clientId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value')
        })
        .set('login-key', localStorage.getItem('token'))
        .then((response) => {
          if(response.body.status ===  true){

          
          var edit1;
          edit1 = response.body.data;
          setOpen1(true)
          setClient_id1(edit1[0].client_id)
          // setState1(edit1[0].is_active)
          setEdit({
            client_name1: edit1[0].client_name,
            description1: edit1[0].description,
            client_code1: edit1[0].client_code,
            market_country1: edit1[0].market_country,
            allowed_currency1: edit1[0].allowed_currency,
            authKey:edit1[0].auth_key,
            is_active1: edit1[0].is_active,
        
          })
        }else{
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
        
            alert(errorData[response.body.message])
            signOut(userDispatch, props.history)
             }
             else if(response.body.status === false){
              alert(errorData[response.body.message])
              fetchData();
              }
        }
        })
    }
    
  const handleInputChange = event => {
    const { name, value } = event.target

    setEdit({ ...edit, [name]: value })
  }
  
  
    const updateUserAPI = (e) => {
      // setId(e.target.parentElement.parentElement.getAttribute('value'));
      e.preventDefault();
      superagent.post(
        config.apiUrl+'/api/Flight/updateClient')
        .set('login-key', localStorage.getItem('token'))
        .send({
          "clientId": client_id1,
          "clientName": edit.client_name1,
          "description": edit.description1,
          "clientCode": edit.client_code1,
          "marketCountry": edit.market_country1,
          "allowedCurrency": edit.allowed_currency1,
          "isActive": edit.is_active1 ? 1 : 0,
          "authKey":authKey1,
          "isArchived": '0'
        })
  
        .then((response) => {
        

          
          var response = response.body;
          // fetchData();
          if (response.status) {
            data = data.map((val) => {
              if (val.client_id == response.data[0].client_id)
                return response.data[0];
              else
                return val;
            });
            setData([...data]);
            // fetchData();
          }
          else{
            if(response.message === 'ERMWMSG3' || response.message === 'ERMWMSG4'||response.message === 'ERMWMSG2'|| response.message === 'ERMWMSG5' ){
        
              alert(errorData[response.message])
              signOut(userDispatch, props.history)
               }
               else if(response.status === false){
                alert(errorData[response.message])
                handleClose3();
                }
          }
          
         
          handleClose3();
        })
      }
  
  
  
          
          
  
  
          
    
       
    
            
            
    
    

      
  
     
  
      
          
  
  





  

    return (
  
  
  
      <React.Fragment>

        <div className = {classes.header}>
        <Typography className={classes.Typography} variant="h3"  component="h2">
           API
        </Typography>
        </div>
  
        <Fab color="primary" onClick={handleClickOpen} aria-label="add" className={classes.fab}>
          <AddIcon onClick={handleClickOpen} />
        </Fab>
  
      <div className={classes.divider} />

        <div>
          <Dialog open={open} onClose={handleClose} className={classes.dialog} aria-labelledby="form-dialog-title">
  
            <DialogContent >
            <CancelIcon className ={classes.CancelIcon} onClick={handleClose}  />

              <Typography variant="h3" component="h2"  color="primary" >
                <center>  Add Client   </center>
              </Typography>
              {error?<Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                   
                    <div>
                      All fields are required
                     </div>


                  </Typography>
                </Fade>:''}
              <Grid container spacing={1} className={classes.grid}>
            
                <Grid item sm={6}>
                  <TextField
                    id="standard-basic"
                    // className={classes.textField}
                    name="client_name"
                    label=" Name"
                    margin="normal"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={rows.client_name}
                    margin="normal"
                    type="name"
                    onChange={handleChange}
                    
                  />
                </Grid>
  
                <Grid item sm={4} >
                  <TextField
                    id="standard-multiline-flexible"
                    label="Description"
                    name="description"
                    multiline
                    rowsMax="4"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={rows.description}
                    // onChange={handleChange}
                    onChange={handleChange}
                    // className={classes.textField}
                    margin="normal"
                    type="name"
                    
                  />
                </Grid>
  
                <Grid item sm={6} >
                  <TextField
                    id="standard-select-country"
                    select
                    label="Market Country"
                    name="market_country"
                    // className={classes.textField}
                    value={rows.market_country}
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please Select Your Country"
                    margin="normal"
                    
                  >
                    {marketCountry1.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
  
                  </TextField>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Currency"
                    name="allowed_currency"
                    // className={classes.textField}
                    value={rows.allowed_currency}
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please Select Your Currency"
                    margin="normal"
                    
                  >
                    {currencies.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="standard-basic"
                    // className={classes.textField}
                    label="Client Code"
                    name="client_code"
                    // margin="normal"
                    inputProps={
                      { maxLength: 8 }
                    }
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                        maxLength: 8,
                      },
  
                    }}
                    onChange={handleChange}
                    value={rows.client_code}
                    
                  />
                </Grid>
                <Grid item sm={4}>
                  <FormControlLabel
                    name="is_active"
                    checked={state.isActive}
                    value="isActive"
                    onChange={activefn('isActive')}
                    
  
                    // onChange={handleChange}
                    // inputProps={{
                    //   'aria-label': 'primary checkbox',
                    // }}
                    control={<Checkbox color="primary" />}
                    label="Is Active"
                    
  
                  />
                </Grid>
              </Grid>
  
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}
                  className={classes.button}
                color="primary"
                type="submit"
                variant="contained"
                onClick={
  
                  addClient
                }
              >
                Save
            </Button>
              <Button onClick={handleClose} className={classes.button}color="primary">
                Cancel
            </Button>
            </DialogActions>
  
          </Dialog>
        </div>
  
  
        {/* ################################################### */}
        <div>
          <Dialog open={open1} onClose={handleClose3} className={classes.dialog} aria-labelledby="form-dialog-title">
  
            <DialogContent >
            <CancelIcon className ={classes.CancelIcon} onClick={handleClose3}  />
              <Typography variant="h3" component="h2" color="primary">
                <center>  Edit  Client  </center>
              </Typography>
              <Grid container spacing={1} className={classes.grid}>
             
            
               
                <Grid item sm={6}>
                  <TextField
                    id="standard-basic"
                    className={classes.textField}
                    name="client_name1"
                    label="First Name"
                   
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        // input: classes.textField,
                      },
                    }}
                    value={edit.client_name1}
                    margin="normal"
                    type="name"
                    onChange={handleInputChange}
                  />
                </Grid>


                <Grid item sm={4}>
                  <TextField
                    id="standard-basic"
                    className={classes.textField}
                    label="Client Code"
                    name="client_code1"
                    margin="normal"
                    inputProps={
                      { maxLength: 8 }
                    }
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        // input: classes.textField,
                        maxLength: 8,
                      },
  
                    }}
                    onChange={handleInputChange}
                    value={edit.client_code1}
                  />
                </Grid>

         
              
  
        
  
                <Grid item sm={6}>
                  <TextField
                    id="standard-select-country"
                    select
                    label="Market Country"
                    name="market_country1"
                    className={classes.textField}
                    value={edit.market_country1}
                    onChange={handleInputChange}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please Select Your Country"
                    margin="normal"
                  >
                    {marketCountry1.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
  
                  </TextField>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Currency"
                    name="allowed_currency1"
                    className={classes.textField}
                    value={edit.allowed_currency1}
                    onChange={handleInputChange}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please Select Your Currency"
                    margin="normal"
                  >
                    {currencies.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}width: '100%',
                    paddingTop:'22px',
                    paddingBottom:'26px',
                    paddingLeft:'16px',
                    
                  </TextField>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Description"
                    name="description1"
                    rowsMax="4"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        // input: classes.textField,
                      },
                    }}
                    value={edit.description1}
                    // onChange={handleChange}
                    onChange={handleInputChange}
                    className={classes.textField}
                    // margin="normal"
                    multiline
                    type="name"
                    // fullWidth
                  />
                </Grid>

                <Grid item sm={4}>
                  <FormControlLabel
                    name="is_active1"
                    checked={edit.is_active1}
                    value="is_active1"
                    // onChange={handleInputChange}
                    onChange={activefn1('is_active1')}
                    control={<Checkbox color="primary" />}
                      margin="normal"
                    label="Is Active"
  
                  />
                </Grid>
             



                <Grid item sm={9}>
                {show? <TextField
                  disabled
                  // id="standard-basic"
                  // className={classes.textField}
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      // input: classes.textField,
                    },
                    
                  }}
                  value={authKey1}
                  // margin="normal"
                  label="AuthKey"
                  type="name"
                  name="AuthKey"
                  multiline
                    fullWidth
                    // onChange={handleInputChange}
                  /> :<TextField
                  disabled
                  multiline
                    // id="standard-basic"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        // input: classes.textField,
                      },
                    }}
                    // className={classes.textField}
                   
                  value={edit.authKey}
                    // margin="normal"
                    label="AuthKey"
                    type="name"
                    fullWidth
                    // onChange={handleInputChange}
                  />}
                    { copyShow?<Fade in={copyShow}>
                  <Typography color="primary" >
                   
                    <div>
                     Copied 
                     </div>


                  </Typography>
                </Fade>:''}
                  </Grid>


                  <Grid item sm={3}>
                    <div>
                    <Tooltip title="Generate AuthKey">
                  <FlipCameraIosIcon   onClick={Authkey}  className = {classes.AuthButton} />
                 </Tooltip>
                 <Tooltip title="Copy to Clipboard">
                <FileCopyIcon  onClick= {copyFn}  className = {classes.AuthButton}  />
                </Tooltip>
                    </div>
               </Grid>

                    


               
                    
                  
             
                    
                   
              


                    </Grid>
           
               
              
  
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.button}
                color="primary"
                type="submit"
                variant="contained"
                onClick={
  
                  updateUserAPI
                }
              >
                Save
            </Button>
            <Button color="primary" onClick={handleClose3} className={classes.button}>Cancel</Button>
            
            </DialogActions>
  
          </Dialog>
        </div>
        {/* ####################################### */}
  
        <Dialog open={openAlert} onClose={handleClose2} aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>
              Are you sure you want to  Delete User?
          </DialogContentText>
  
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteUser} color="primary">
              yes
        </Button>
            <Button onClick={handleClose2} color="primary">
              no
        </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={alertMsg} autoHideDuration={6000} onClose={handleCloseInit}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseInit} color="success">
    
        {alertAdd}
        </Alert>
      </Snackbar>
      <Snackbar open={alertUser} autoHideDuration={6000} onClose={handleCloseAdd}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseAdd} color="success">
    
       {alertDelete}
        </Alert>
      </Snackbar>
  
        <div>
  
          <Paper className={classes.root1}>
            <Table className={classes.table} aria-label="simple table">
  
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Client Name</Th>
                  <Th> Client Code</Th>
                  <Th>Market Country</Th>
                  <Th>Allowed Currency</Th>
                  <Th>Is Active</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
  
              <Tbody>
  
                {data.map((item, itInx) => {
  
                  return (
                    <Tr key={'item' + itInx}>
                
                      <Td className={classes.tableCell} >{itInx + 1}</Td>
                      
                      <Td className={classes.tableCell} >{item.client_name}{/* &nbsp;{item.last_name} */}</Td>
                      <Td className={classes.tableCell} >{item.client_code}</Td>
                      <Td className={classes.tableCell} >{item.market_country}</Td>
                      <Td className={classes.tableCell} >{item.allowed_currency}</Td>
                      {/* <Td align="left" >{item.is_active ? <AirplanemodeActiveSharpIcon  color="primary" />: < AirplanemodeInactiveSharpIcon  color ="secondary" />}</Td> */}
  
                      <Td className={classes.tableCell}  >{item.is_active ?'Active': 'In Active'}</Td>
                      <Td className={classes.tableCell}  value={item.client_id}   >
  
                        <span onClick={editUser}>
  
                          <EditRoundedIcon className={classes.Pointer} color= "primary"  />
  
                        </span>
                        &nbsp;
  
                        <DeleteRoundedIcon onClick={handleClickOpenAlert} className={classes.Pointer} color="secondary" />
  
                      </Td>
                    </Tr>
                  )
                }
                )
  
                }
              </Tbody>
            </Table>
  
          </Paper>
        </div>
  
      </React.Fragment>
    );
  }



























