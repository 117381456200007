export default {
    iconUrl: "https://api.cfares.uat.tltid.com/airlineIcon/",
    userRole:{
        SA:"Super Admin",
        MA:"Manager",
        US:"User",
        MU:"Meta User"
    },
    accessReqRes:[1],
    restrictedMenus:['LC','ST','AC','RP','MP','MC','BS','MT','UC','UT'],
    apiUrl: "https://api.cfares.uat.tltid.com",
    loginReq:{
        LC:"/app/lcc",
        MC:"/app/marketCompare",
        MP:"/app/metaPreview",
        ST:"/app/UserForm",
        BS:"/app/bulkSearch",
        AC:"/app/client",
        RP:"/app/sesEmail",
        MT:"/app/metaConfig",
        UC:"/app/roleAccess",
        UT:"/app/utilities"


    },
}