import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import superagent from "superagent";
import config from "../../config/config"


import { withRouter } from "react-router-dom";
import classnames from "classnames";
// import UserForm from "../../pages/User_form/userform"
// import ForgetPassword from "../../pages/forgotPassword/ForgotPassword"

// styles
import useStyles from "./styles";

// logo
import logo from "./NDC.jpg";


// context
// import { useUserDispatch, loginUser, ForgotPassword } from "../../context/UserContext";
import { useUserDispatch, LoginPage } from "../../context/UserContext";

function ResetPassword(props) {
  var classes = useStyles();
var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  // var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [conPasswordValue, setConPasswordValue] = useState("");
  var [validatePasswordErr, setValidatePasswordErr] = useState(false)
  var [conValidatePasswordErr, setConValidatePasswordErr] = useState(false);
  var [message, setMessage ] = React.useState(false);
  const [alert , setAlert ]  = React.useState(false);
  var [messages , setMessages] = React.useState('')
  var [mess , setMess] = React.useState('')
  // import SidebarLink from "./components/SidebarLink/SidebarLink";
  // const [ global , setGlobal]=useState('');
// console.log(props)
  const validation = () => {



    if(conPasswordValue !== passwordValue) {
      setIsLoading(false)
    }
     else{
       setIsLoading(true)
       
       superagent.post(config.apiUrl+"/api/Flight/resetPassword")
       .send({
         'token': props.match.params.token,
         'password':passwordValue
       })
       .then(res=>{
            if(res.body.status == true ){
              setIsLoading(false)
              setMessage(true)
             setMess(errorData[res.body.message])
            
            } 
            if(res.body.status == false){
              setAlert(true)
              setMessages(errorData[res.body.message])

            }

       })
       setIsLoading(false)
     } 
    




  }
 
  const handlePassword = (e) => {
    setValidatePasswordErr(false)
    setPasswordValue(e.target.value)
  }
  const conHandlePassword =(e) =>{
    setConValidatePasswordErr(false)
    setConPasswordValue(e.target.value)
  }
  const validatePassword = () => {
    var password = passwordValue;
    if (password.length < 8) {
      setValidatePasswordErr(true)
    }
  }
  const conValidatePassword =() =>{
    var conPasswords = conPasswordValue;
    if(conPasswords !== passwordValue) {
      setConValidatePasswordErr(true)

    }
  }

  


  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Clarity Insight</Typography>
      </div>


      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Reset password" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <div className={classes.formDividerContainer}>
              </div>
              <Fade in={error}>

                <Typography color="secondary" className={classes.errorMessage}>

                </Typography>
              </Fade>
                  


              {message ? (<Typography color="primary"> {mess} </Typography>): (null)}
              {alert ? (<Typography color="secondary">{messages}</Typography>):(null)}

              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                // onChange={e => setPasswordValue(e.target.value)}
                onChange={handlePassword}
                onBlur={validatePassword}
                helperText={validatePasswordErr ? <Typography color="Secondary"> Password value should be 8 characters </Typography> : ''}

                // onChange = {handlePasswordChange}
                margin="normal"
                placeholder="Password"
                type="password"

                fullWidth
              />
              <TextField
                id="conPassword"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={conPasswordValue}
                // onChange={e => setPasswordValue(e.target.value)}
                onChange={conHandlePassword}
                onBlur={conValidatePassword}
                helperText ={conValidatePasswordErr ? <Typography color="Secondary"> Password do not Match </Typography> : ''}

                // onChange = {handlePasswordChange}
                margin="normal"
                placeholder="Confirm Password"
                type="password"

                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                      disabled={
                        passwordValue.length < 8 || validatePasswordErr == true || conValidatePasswordErr == true
                      }
                      // onChange={validation}

                      onClick={
                        validation
                        // loginUser(
                        //   userDispatch,
                        //   emailValue,
                        //   passwordValue,
                        //   props.history,
                        //   setIsLoading,
                        //   setError,
                        //   )

                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                    >
                      Submit
                  </Button>
               


                  )}
                    
                     {message ? (  <Button
                       // hidden
                       color="primary"
                       size="large"
                       className={classes.forgetButton}
                       onClick={() => LoginPage(userDispatch, props.history)}
                     >
                      Click here  to Login
                     </Button>) : ''}
                  
             
              </div>
            </React.Fragment>
          )}

        </div>

      </div>
    </Grid>
  );
}

export default withRouter(ResetPassword);