import React, { useEffect } from 'react';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import { flexbox } from '@material-ui/system';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
import axios from 'axios';
import superagent from 'superagent';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CommonFunction from '../../lib/commonFunction';
import { email as emailRegex } from "../../pages/login/regexes";
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import Fade from '@material-ui/core/Fade';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
// import {ResponsiveTable} from 'material-ui-table';
// import ResponsiveTable from 'responsive-table';
// import ResponsiveTable from 'material-ui-next-responsive-table'
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import {  getMuiTheme } from 'material-ui/styles/getMuiTheme';
// import {  lightBaseTheme } from 'material-ui/styles/baseThemes/lightBaseTheme';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useUserState } from "../../context/UserContext";
// import data from  './UserTable';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import config from '../../config/config';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';



const Role = [
  {
    value: 'SA',
    label: 'SUPER ADMIN',
  },
  {
    value: 'US',
    label: 'USER',
  },
  {
    value: 'MA',
    label: 'MANAGER',
  },
  {
    value: 'MU',
    label: 'METAUSER',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  root1: {
    width: '100%',
    paddingTop:'22px',
    paddingBottom:'26px',
    paddingLeft:'16px',
    // overflowX: 'auto',
  },
 
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    size: 'small',
    variant: 'extended',
    position: 'absolute',
    top: theme.spacing(9),
    right: theme.spacing(2),
  },
  fab1: {
    size: 'medium',
    variant: 'extended',

  },
  button: {
    // right: theme.spacing(1),
    // margin: theme.spacing(2, 0),
    float:'right',
    '&:hover': {
      // backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
      overflow:'hidden'
    },
    cursor: 'Pointer',
  },
  button1: {
    left: theme.spacing(50),
  },
  table: {
    // display: 'block',
    // width: '100%',
    // overflowX: 'auto',
    // minWidth: 100,
  borderCollapse:'collapse'
  },
  Typography: {
    left: theme.spacing(10),
  },
  typography: {
    position:'fixed',
    // alignItems:'center'
  
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
    paddingTop:10,
    paddingBottom:10
  },
  header: {
    margin: theme.spacing(0, 2),
  },


  Pointer: {
    cursor: 'Pointer',
  },
  divider:{
    margin: theme.spacing(3, 0),
  },
  CancelIcon:{
   
    variant: 'extended',
    position: 'absolute',
    // top: theme.spacing(1),
    right: theme.spacing(2),
    '&:hover': {
      // backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    cursor: 'Pointer',
  },
  // tableBody:{
  //   margin:theme.spacing(5,1)
    
  // },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(5.5),
    
    
  },
  paginationRow:{
    Width:'100%',
    borderBottom:'none'
  }

}));



function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  

  return (
    <div className={classes.pagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}



export default function UserForm(props) {
 
  const classes = useStyles();
  var userDispatch = useUserDispatch();
  var userContextData = useUserState();
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
 
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  // const [username, setUsername] = React.useState('');
  // const [emailvalue, setEmailvalue] = React.useState('');
  // const [passwordvalue, setPasswordvalue] = React.useState('');
  const [role, setRole] = React.useState('');
  const [rows, setRows] = React.useState({
    id: null,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    role: role,
    items: [],
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  var [data, setData] = React.useState([]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

// const [menuCodeList, setMenuCodeList] = React.useState([]);
  // const [email, setEmail] = React.useState([]);
  // const [first_name, setFirst_name] = React.useState([]);
  // const [last_name, setLast_name] = React.useState([]);
  // const [pass, setPass] = React.useState([]);
  // const [rol, setRol] = React.useState('');
  // const [rows1, setRows1] = React.useState({
  //   id: null,
  //   firstname1: first_name,
  //   lastname1: last_name,
  //   email1: email,
  //   password1: pass,
  //   role1: rol,
  // });

  const [edit, setEdit] = React.useState({
    id1: '',
    email1: '',
    first_name1: '',
    last_name1: '',
    pass: '',
    rol: '',
    
  });
  // const [rolesEdit , setRolesEdit] = React.useState('')

  // const [text, setText] = React.useState([]);
  
  const [add, setAdd] = React.useState([]);
  const [id, setId] = React.useState(0);
  // const [editing, setEditing] = React.useState(false);
  const initialState = {
    id: null,
    firstName: '',
    lastname: '',
    email: '',
    password: '',
    role: null
  }

  // const [currentUser, setCurrentUser] = React.useState(initialState);
  const [id1, setId1] = React.useState({
    id1: ''
  })
  const [error, setError] = React.useState(null);
  const [ roleEmpty , setRoleEmpty] = React.useState(false)

  const [targetElement,setTargetElement] = React.useState();

    const [ menu ,setMenu] = React.useState([])
    const [  roles,setRoles] = React.useState([])
    const [ checkbox , setCheckbox] = React.useState(false)
    // const [ checkboxEdit , setCheckboxEdit] = React.useState(false)

    const [ menusValue,setMenusValue] = React.useState([])
    const [  carrier,setCarrier] = React.useState([])
  const [rolemenu,setRolemenu]=React.useState(false);
  const [rolemenu1,setRolemenu1]=React.useState(false);
  const [alertMsg ,setAlertMsg] =  React.useState(false)
  const [alertUser ,setAlertUser] =  React.useState(false)
  const [alertDelete, setAlertDelete] = React.useState('')
  const [alertAdd, setAlertAdd] = React.useState('')
  const [roleObject,setRoleObject] = React.useState({});
  var tableindex = "";



  const fetchData = async () => {
  
    const result = await axios(
      config.apiUrl+'/api/Flight/userDetails',
      { headers: { 'login-key': localStorage.getItem('token')} }
      );
      if(result.data.status ===  true){
        
        



      var filteredMenus=[];
      var roleRef = {};
      filteredMenus=result.data.roleMenu.menus.filter((val)=>{
        if(config.restrictedMenus.includes(val['menu_code'])){
          return val;
        }
      })

      result.data.roleMenu.roles.map((val)=>{
        roleRef[val.id] = val;
      });

      setRoleObject(roleRef);
      setMenu(filteredMenus)
       setRoles(result.data.roleMenu.roles)
       setData(result.data.data)
      
    }
    else{
      
       if(result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4'|| result.data.message === 'ERMWMSG2'|| result.data.message === 'ERMWMSG5' ){
  
      alert(errorData[result.data.message])
      signOut(userDispatch, props.history)
       }
       else if(result.data.status === false){
        
         alert(errorData[result.data.message])
         props.history.goBack();
       
        }
      }
  };


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleCloseInit = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertMsg(false);
  }
  const handleCloseAdd = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertUser(false);
  }


  useEffect(() => {
    fetchData();
  }, []);

 

  
  


  useEffect(()=>{
      if(roles.length)
      handleInputChangeRole();
  },[roles])

  useEffect(()=>{
    if(menu.length)
    handleInputChangeRole();
},[menu])

useEffect(()=>{
  handleInputChangeRole();
},[edit])





  const handleChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;


    var rolemenus = '';
    var menuValues = [];
    
    roles.map((key)=>{

      if(key.id == value ){
        rolemenus   =  key.menu_id;
      }
    })
    rolemenus = rolemenus.split(',');

      menu.map((val)=>{
        if(!rolemenus.includes(String(val.id))){
          var information = {
            menuId : val.id,
            isChecked : false,
            label : val.menu_name
  
  
          }
          menuValues.push(information)
        }
    })
    setCarrier([...menuValues])

    setRoleEmpty(true)

  
     
      // menu.map((key)=>{


      // if(key.id == rolemenus ){
        // var information = {
        //   menuId : rolemenus,
        //   isChecked : true,
        //   label : key.menu_code


        // }
      // }
    


    setRows({
      ...rows,
      [name]: value
    })

 
  
  }
  const handleCheckbox = name => event => {

    // var tempCarriers = carrier;
    // tempCarriers.map((val) => {
    //     if (val.carrierCode == event.target.value) {
    //         val.isChecked = !val.isChecked;
    //     }
    // })
    // setCarrier([...tempCarriers]);


    carrier.map((val)=>{
      if(val.menuId == event.target.value){
        val.isChecked = !val.isChecked
      }
    })
    setCarrier([...carrier])

  };

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRoleEmpty(false)
    setRolemenu(false)
    setRows({
      ...rows,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      role: ''
    });
    setError(false)


  };
  const handleClose1 = () => {
    // alert();
    setOpen1(false);
    setRolemenu1(false)
    

  };
  // const indexhandler = (ind)=>{
  //   for (var i=0;i<=ind;i++){
  //     i+1

  //   }
  // }
  
  const handleClickOpenAlert = (e) => {
    setTargetElement(e.target);

    setOpenAlert(true);


  };
  const handleClickOpenCheckbox= (e) =>{
    setRolemenu(!rolemenu);

    
  }
  const  handleClickOpenCheckboxEdit= (e) =>{
   
    setRolemenu1(!rolemenu1);
  }

 
  const checkboxClose = () =>{
    setCheckbox(false)
  }
  // const checkboxCloseEdit = () =>{
  //   setCheckboxEdit(false)
  // }

  const handleClose2 = () => {
    setOpenAlert(false);
  };
  const handleRole = event => {

    setRole(event.target.value);
  };

  const handleCheckboxEdit  = name => event => {

    // var tempCarriers = carrier;
    // tempCarriers.map((val) => {
    //     if (val.carrierCode == event.target.value) {
    //         val.isChecked = !val.isChecked;
    //     }
    // })
    // setCarrier([...tempCarriers]);
  

    menusValue.map((val)=>{
      if(val.menuId == event.target.value){
        val.isChecked = !val.isChecked
      }
    })
    setMenusValue([...menusValue])


  
};




  //handleAdd
  const handleAdd = (e) => {
    e.preventDefault();

    if (!emailRegex.test(rows.email)) {
      setError(true)
      // alert('enter proper mail id')

    } else if (rows.password.length < 8) {
      setError(true)
      // alert(' password length should be greater than 8 character')
    }
    else if (rows.role == '') {
      setError(true)
      // alert('field cannot be empty')
    }
    else{
      var carriers = '';
      var j = 0;//don't change
      carrier.map((val)=>{
        if(val.isChecked == true){
          if(j==0 &&  val.isChecked == true){
            carriers += val.menuId
            j++
          }else if( val.isChecked == true){
            carriers += ',';
            carriers += val.menuId;
        }
      }
 

      })
      setError(false)
    superagent.post(
      config.apiUrl+'/api/Flight/addUser')
      .set('login-key', localStorage.getItem('token'))
      .send({
        "firstName": rows.firstname,
        "lastName": rows.lastname,
        "email": rows.email,
        "password": rows.password,
        "roleId": rows.role,

        "additionalMenus":carriers
      })
      .then((response) => {
        if (response.body.status === true) {
          var text = JSON.parse(response.text)
          data.push(text.data[0]);
          setAlertUser(true)
          setAlertAdd(errorData[response.body.message])
          setData([...data]);
          
          // fetchData();          
        }

        else{
          
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
      
            alert(errorData[response.body.message])
          signOut(userDispatch, props.history)
           }
           else if(response.body.status == false){
            alert(errorData[response.body.message])
            handleClose();
            }
          }
      
      })
      handleClose();
    }

    setRows({
      ...rows,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      role: ''
    });

  };



  // const handleChange1 = (e) => {
  //   let input = e.target;
  //   let name = e.target.name;
  //   let value = input.value;

  //   setRows1({
  //     ...rows1,
  //     [name]: value
  //   })
  // }

  
  //deleteuser
  const deleteUser = (e) => {

    e.preventDefault();


  
    // setId(e.target.parentElement.parentElement.getAttribute('value'));  
    superagent.post(
      config.apiUrl+'/api/Flight/deleteUser')
      .set('login-key', localStorage.getItem('token'))
      .send({
        // "id": e.target.parentElement.parentElement.parentElement.parentElement.getAttribute('value')
        "id": CommonFunction.elementSelector(targetElement, 'td').getAttribute('value')
      })
      .then((response) => {
        if(response.body.status === true){
          setAlertMsg(true)
          setAlertDelete(errorData[response.body.message])
          fetchData();
        }
        else{
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
        
      
          alert('session timeout')
          signOut(userDispatch, props.history)
           }
           else if(response.body.status == false){
            alert(errorData[response.body.message])
            fetchData();
            }
          }

        // fetchData();
      })
      handleClose2();

  }
  //edit user

  const editUser = (e) => {
    e.preventDefault();
   
    // setId(e.target.parentElement.parentElement.getAttribute('value'));

    superagent.post(
      config.apiUrl+'/api/Flight/fetchUser')
      .send({
        "id": CommonFunction.elementSelector(e.target, 'td').getAttribute('value')
      })
      .set('login-key', localStorage.getItem('token'))
      .then((response) => {
       
        if(response.body.status === true){
          setOpen1(true)
          var edit1;
        edit1 = response.body.data;
       
        setId1(edit1[0].id)
        setEdit({

          email1: edit1[0].email,
          first_name1: edit1[0].first_name,
          last_name1: edit1[0].last_name,
          pass: edit1[0].password,
          rol: edit1[0].role_id,
          roles: edit1[0].additional_menus
        })
        // setRolesEdit(edit1[0].id)
        }else{
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
         
      
            alert(errorData[response.body.message])
            signOut(userDispatch, props.history)
             }
             else if(response.body.status === false){
              alert(errorData[response.body.message])
              fetchData();
              }
            }
        
       
      })
  }

 

  const handleInputChange = event => {
    const { name, value } = event.target
    

    setEdit({ ...edit, [name]: value })
  }

const handleInputChangeRole =(e) =>{
 
    // let input = e.target;
    // let name = e.target.name;
    // let value = input.value;
    var menuset;
        if (e != undefined) {
          
          menuset =e.target.value;
    setEdit({ ...edit, rol: menuset })

        }
        else {

          menuset = edit.rol;
        }

    // setEdit({ ...edit, rol: menuset })
    // setRolesEdit(menuset)
    var rolemenus1 = '';
    var menuValues1 = [];
    
    roles.map((key)=>{

      if(key.id == menuset){
        rolemenus1   =  key.menu_id;
      }
    })
    rolemenus1 = rolemenus1.split(',');
 

      menu.map((val)=>{
     
        if(!rolemenus1.includes(String(val.id))){
          var information = {
            menuId : val.id,
            isChecked : edit.roles!=null?(edit.roles.split(',').includes(String(val.id))?true:false):false,
            label : val.menu_name
          }
          menuValues1.push(information)
        }
    })
    setMenusValue([...menuValues1])


}



    


  

  //update

  const updateUserAPI = (e) => {
    // setId(e.target.parentElement.parentElement.getAttribute('value'));

    e.preventDefault();
    if (!emailRegex.test(edit.email1)) {
      setError(true)
      // alert('enter proper mail id')

    } else if (edit.pass.length < 8) {
      setError(true)
      // alert(' password length should be greater than 8 character')
    }
    else if (edit.length === '') {
      setError(true)
      // alert('field cannot be empty')
    } else {

     


      var editMenus = '';
      var j=0;
      menusValue.map((val)=>{
        if(val.isChecked == true){
          if(j==0 && val.isChecked == true){
            editMenus += val.menuId
            j++
          } else if( val.isChecked == true){
            editMenus += ','
            editMenus += val.menuId
          }
        }
      })



      setError(false)
    superagent.post(
      config.apiUrl+'/api/Flight/updateUser')
      .set('login-key', localStorage.getItem('token'))
      .send({
        "id": id1,
        "firstName": edit.first_name1,
        "lastName": edit.last_name1,
        "email": edit.email1,
        "password": edit.pass,
        "roleId": edit.rol,
        "additionalMenus":editMenus

        
      })
      .then((response) => {
       
        // fetchData();
        var response = response.body;
        if (response.status) {
          data = data.map((val) => {
            if (val.id === response.data[0].id)
              return response.data[0];
            else
              return val;
          });
          setData([...data]);
        } else {
          if(response.message === 'ERMWMSG3' || response.message === 'ERMWMSG4'||response.message === 'ERMWMSG2'|| response.message === 'ERMWMSG5' ){
          alert(errorData[response.message])
          signOut(userDispatch, props.history)
        }
        else if(response.status === false){
          alert(errorData[response.message])
          handleClose1();
          }

      }
        handleClose1();
      })
    }
  }




  return (
    <React.Fragment>
      <div className = {classes.header}>
      <Typography className={classes.Typography} variant="h3" component="h2" >
          Users
      </Typography>
      </div>

      <Fab className={classes.fab} onClick={handleClickOpen}color="primary" aria-label="add">
        <AddIcon onClick={handleClickOpen} />  
      </Fab>
      
       <div className={classes.divider} />
      <div>
        <Dialog open={open}   onClose={handleClose}  aria-labelledby="form-dialog-title">
  
          <DialogContent>
          <CancelIcon className ={classes.CancelIcon} onClick={handleClose}  />
            <Typography variant="h3" component="h4"  color="primary">
              <center> Add User </center> 
            </Typography>

            
            <Grid container spacing={1} className={classes.grid}>
             
              <Grid item xs={12}>

            {error?  <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {/* Something is wrong with your login or password :( */}
                    <div>
                      All fields are required
                     </div>


                  </Typography>
                </Fade>:''}


                <TextField
                  id="standard-basic"
                  className={classes.textField}
                  label="First  Name"
                  margin="normal"
                  name="firstname"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={rows.firstname}
                  margin="normal"
                  type="name"
                  onChange={handleChange}
                  fullWidth

                />
              </Grid>


              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  className={classes.textField}
                  label="Last Name"
                  autoComplete="off"
                  margin="normal"
                  name="lastname"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={rows.lastname}
                  margin="normal"
                  type="name"
                  onChange={handleChange}
                  fullWidth

                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={rows.email}
                  onChange={handleChange}
                  margin="normal"
                  name="email"
               label="Email"
                  type="email"
                  fullWidth
                  />
                  </Grid>
           
            {error? 
              <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                      email should be proper format
                  </Typography>
                </Fade>:''}
                 
                   
                    


              <Grid item xs={12}>
                <TextField
                  id="password"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={rows.password}
                  onChange={handleChange}
                  margin="normal"
                  name="password"
                   label="Password"
             
                  type="password"
                  fullWidth
                />
               
               
                {error? <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {/* Something is wrong with your login or password :( */}
                    
                      password should be greater than 8 character
                     


                  </Typography>
                </Fade> : ''}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Role"
                  className={classes.textField}
                  value={rows.role}
                  name="role"
                  onChange={handleChange}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  fullWidth
                >
               
                   
                  {
                    roles.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.role_name}
                    </MenuItem>
                  ))}

             

                </TextField>
              </Grid>
               
            

          <Grid item xs={12}>
                {/* <TextField
                  id="standard-select-currency"
                  select
                  label="Select Role"
                  className={classes.textField}
                  value={rows.role}
                  name="role"
                  onChange={handleChange}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  fullWidth
                >
                  {menuCodeList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option}
                    </MenuItem>
                  ))}

                </TextField> */}

        <Grid item xs={12}>
        {roleEmpty? <Button color="primary"  onClick={handleClickOpenCheckbox}>
         Add-on Roles
        </Button> :''}
        </Grid>
      {rolemenu ?
       <Grid container xs={12}>
      {
          
          carrier.map((val) => {
      return (
        <Grid item xs={4}>
          <FormControlLabel control={
              <Checkbox
                  checked={val.isChecked}
                  onChange={handleCheckbox(val.menuId)}
                  value={val.menuId}
                  inputProps={{
                      'aria-label': 'uncontrolled-checkbox',
                  }}
              />}
              label={val.label}
          />
          </Grid>
          );
  })
      }
      </Grid>
          :null}
         
                </Grid>




              <Grid item sm={12}    >
                <Button variant="contained" color="primary" margin="normal" onClick={handleAdd} className={classes.button} >
                  Save
      </Button>
      
     
      <Button color="primary" margin="normal" className={classes.button} onClick={handleClose}>Cancel</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>


      <Snackbar open={alertMsg} autoHideDuration={6000} onClose={handleCloseInit}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseInit} color="success">
    
        {alertDelete}
        </Alert>
      </Snackbar>
      <Snackbar open={alertUser} autoHideDuration={6000} onClose={handleCloseAdd}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseAdd} color="success">
    
      {alertAdd}
        </Alert>
      </Snackbar>
      {/* ############ */}
      
      
      {/* <Grid container spacing={2}>
      <Grid item sm={12}> */}
    <Paper className={classes.root1}>
    
    
        <Table className={classes.table}>
        
          <Thead>
          
            <Tr>
          
             
              
            
            
            
             
               <Th > </Th>
              <Th > Name</Th>
              <Th >Email</Th>
              <Th>Role</Th>
              <Th >Date Updated</Th>
              <Th >Actions</Th>
             
              
              
          
            </Tr>
        
          </Thead>
         

          <Tbody >
          

            {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((item, itInx) => {
         

           
           

            
                
              return (
                
                
                <Tr key={'item' + itInx}>
                   
                  <Td className={classes.tableCell}>{/* indexhandler(tableindex) */(page * rowsPerPage+(itInx+1))}</Td>
                  <Td className={classes.tableCell}>{item.first_name}{' '}{item.last_name}</Td>
                  <Td className={classes.tableCell}>{item.email}</Td>
                  <Td className={classes.tableCell}>{roleObject[item.role_id].role_name}{item.additional_menus!=''?<sup><AddIcon color='primary' style={{fontSize:'small'}}></AddIcon></sup>:''}</Td>
                  <Td className={classes.tableCell}>{item.updated_at}</Td>
                  <Td className={classes.tableCell} value={item.id}   >
                   
                    <span onClick={editUser}>

                      <EditRoundedIcon className={classes.Pointer} color="primary" />

                    </span>
                    &nbsp;

                      <DeleteRoundedIcon onClick={handleClickOpenAlert} className={classes.Pointer} color="secondary" />

                  </Td>
               
               
                </Tr>
            
              )
            }
            )
          

            }
         
          </Tbody>
          {emptyRows > 0 && (
            <Tr style={{ height: 53 * emptyRows }}>
              <Td colSpan={6} />
            </Tr>
          )}
          <TableFooter>
          <Tr>
            <TablePagination className={classes.paginationRow}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={8}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Tr>
        </TableFooter>
          
        </Table>
       
    {/* </MuiThemeProvider> */}
        </Paper>
        {/* </Grid>
        </Grid> */}
      
      
      

      <div>
        <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title">
          <DialogContent>

             <CancelIcon className ={classes.CancelIcon} onClick={handleClose1}  />
            <Typography variant="h3" component="h4"  color="primary">
              <center> Edit User </center> 
            </Typography>
           
            
              
            
            <Grid container spacing={1} className={classes.grid}>
              <Grid item xs={12}>
            {error?  <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {/* Something is wrong with your login or password :( */}
                    <div>
                      All Fields are Required
                     </div>


                  </Typography>
                </Fade>:''}
                <TextField
                  id="standard-basic"
                  className={classes.textField}
                  label="First Name"
                  margin="normal"
                  name="first_name1"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={edit.first_name1}
                  margin="normal"
                  type="name"
                  onChange={handleInputChange}
                  fullWidth

                />
              </Grid>


              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  className={classes.textField}
                  label="Last Name"
                  margin="normal"
                  name="last_name1"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={edit.last_name1}
                  margin="normal"
                  type="name"
                  onChange={handleInputChange}
                  fullWidth

                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={edit.email1}
                  onChange={handleInputChange}
                  margin="normal"
                  name="email1"
                  label="Email"
                  type="email"
                  fullWidth
                />
                 {error? <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {/* Something is wrong with your login or password :( */}
                    <div>
                      email should be proper format
                     </div>


                  </Typography>
                </Fade>:''}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={edit.pass}
                  onChange={handleInputChange}
                  margin="normal"
                  name="pass"
                  label="Password"
                  type="password"
                  fullWidth
                />
                  {error?<Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {/* Something is wrong with your login or password :( */}
                    <div>
                      password should be greater than 8 character
                     </div>


                  </Typography>
                </Fade>:''}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="User Role"
                  className={classes.textField}
                  value={edit.rol}
                  name="rol"
                  onChange={handleInputChangeRole}


                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  fullWidth
                >
                  {roles.map(option => (
                    <MenuItem key={option.id} value={option.id} >
                      {option.role_name}
                    </MenuItem>
                  ))}

                </TextField>
              </Grid>

              <Grid item xs={12}>
            

         {menusValue.length?(         
         <Button color="primary"  onClick={handleClickOpenCheckboxEdit}>
         Add-on-Roles
         </Button>):null}
       {rolemenu1?
        <Grid container spacing={12}>
      
      {
        
       
        menusValue.map((val) => {
              
      return (
        <Grid item xs={4}>
          <FormControlLabel control={
              <Checkbox
                  checked={val.isChecked}
                  onChange={handleCheckboxEdit(val.menuId)}
                  value={val.menuId}
                  inputProps={{
                      'aria-label': 'uncontrolled-checkbox',
                  }}
              />}
              label={val.label}
          />
          </Grid>
          );
  })
      }
      </Grid>
          :null}
          

                </Grid>



              <Grid item xs={12}   >
                <Button variant="contained"  className={classes.button} color="primary" onClick={updateUserAPI}  >
                  Save
      </Button>
      <Button color="primary"  className={classes.button} onClick={handleClose1}>Cancel</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      {/* ############# */}

      {/* ############ */}

      <Dialog open={openAlert} onClose={handleClose2} aria-labelledby="form-dialog-title">
      <DialogContent>
      <DialogContentText>
    Are you sure you want to  Delete User?
        </DialogContentText>
           
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUser} color="primary">
            yes
      </Button>
          <Button onClick={handleClose2} color="primary">
            no
      </Button>
        </DialogActions>
      </Dialog>

    

      {/* ################ */}
      
      
     
     
    </React.Fragment>

    
  );
}





















