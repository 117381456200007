import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import superagent from "superagent";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker } from "@material-ui/pickers";
import moment from 'react-moment'
import { format } from "date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import config from '../../config/config';
import { CircularProgress, Typography } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { flexbox, positions, maxWidth } from '@material-ui/system';
import { useUserState } from "../../context/UserContext";
import InputBase from '@material-ui/core/InputBase';
import Link from '@material-ui/core/Link';
import { CheckBox } from '@material-ui/icons';
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useTheme } from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import CommonFunction from '../../lib/commonFunction';
import socketIOClient from "socket.io-client";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const currencies = [
    {
        value: 'Economy',
        label: 'Economy',
    },
    {
        value: 'Business',
        label: 'Business',
    },
    {
        value: 'First',
        label: 'First',
    },

];



const Adult = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];

const Child = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];




const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
        paddingBottom: "10px",
        paddingTop: "10px",
        paddingLeft: "17px"
    },


    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 40,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // paddingRight:'40px'
        padding: theme.spacing(2),

    },
    container: {
        display: 'flex',
        // flexGrow: 1,

        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),

        width: '100%',



    },

    buttonSites: {
        marginLeft: "80%"
    },


    textField1: {
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(10),

        // width: 130
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    button: {
        // margin: theme.spacing(3),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    displayBlock:
    {
        display: 'block',
    },
    displayNone:
    {
        display: 'none',
    },
    fab: {
        margin: theme.spacing(0),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    null:
    {
        margin: null
    },
    fareButton: {
        // marginBottom: 10
        inlineSize: "-webkit-fill-available",


    },
    ReturnHead: {
        textAlign: 'center',
    },
    loginLoader: {
        margin: theme.spacing(3),
    },
    fadeError: {
        margin: theme.spacing(8),
        textAlign: "center",
        color: "red",
    },
    BotContainer: {
        display: flexbox
    },
    paper: {
        whiteSpace: "nowrap",
        textAlign: "center",
        // marginBottom:10

    },
    HeadingFlights: {
        margin: theme.spacing(2),
    },
    LinkButton: {
        marginLeft: theme.spacing(3),
    },
    wordFare: {
        // float: 'right',
        // paddingRight: 15,
        // position:"sticky"


    },
    colour: {
        color: 'blur',
    },
    selected: {
        inlineSize: "-webkit-fill-available",
        color: 'blue',
    },
    padding: {
        paddingLeft: 20
    },
    header: {
        margin: theme.spacing(0, 4),
        marginBottom: "2%",

    },
    buttonViewSites: {
        margin: theme.spacing(3),
    },
    checkbox: {
        margin: theme.spacing(2)
    },
    abortbutton: {
        position: 'inherit',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 150,
        // maxWidth: 250,
        width:'90%'
    },

}));
const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);




var abortValue = false;
var responseExist = false;
export default function MarketCompare(props) {
    var userDispatch = useUserDispatch();
    var userContextData = useUserState();
    var theme = useTheme();
    var errorData = JSON.parse(localStorage.getItem('errorMsg'))


    const [value, setValue] = React.useState('return');
    const [airportList, setAirportList] = React.useState([])


    const [journeyType, setJourneyType] = React.useState('return');

    const [showReturn, setShowReturn] = React.useState(true);
    const [returnState, setReturnState] = React.useState(true);
    const classes = useStyles();
    var currentDate = new Date();
    // const [FromDate, setSelectedDate] = React.useState(currentDate.getFullYear() + '/' + currentDate.getMonth() + '/' + currentDate.getDate());
    // const [FromDate, handleDateChange] = 
    var currentDate1 = new Date();
    var startDate = new Date();
    var days;
    days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    // const [ToDate, setSelectedDate1] = React.useState(currentDate1.getFullYear() + '/' + currentDate1.getMonth() + '/' + currentDate1.getDate());
    // const [FromDate, handleDateChange] = React.useState(currentDate.getFullYear() + ' ' + (currentDate.getMonth() + 1) + ' ' + (days[currentDate.getUTCDate()])  );
    const [FromDate, handleDateChange] = React.useState(currentDate);
    const [FromDateMulticity, handleDateChangeMulticity] = React.useState(currentDate);
    // const [ToDate, setSelectedDate1] = React.useState((currentDate.getMonth()+1)+'/'+currentDate.getDate()+'/'+currentDate.getFullYear());
    const [selectedDate2, setSelectedDate2] = React.useState(new Date());
    const [ToDate, handleDateChange1] = React.useState(currentDate);
    const [cabin, setCabin] = React.useState('Economy');
    const [market, setMarket] = React.useState('');
    const [adult, setAdult] = React.useState('1');
    const [child, setChild] = React.useState('0');
    const [infant, setInfant] = React.useState('0');
    const [addbutton, setAddbutton] = React.useState(false);
    const [addbutton1, setAddbutton1] = React.useState(false);
    const [showmulticity, setShowmulticity] = React.useState(false);
    const [add, setAdd] = React.useState([0]);
    const [empty, setEmpty] = React.useState(false);
    const [fields, setFields] = React.useState([{ value: null }]);
    const [fields1, setFields1] = React.useState([{ value: null }]);
    const [From, setFrom] = React.useState('');
    const [FromLabel, setFromLabel] = React.useState('');
    const [To, setTo] = React.useState('');
    const [ToLabel, setToLabel] = React.useState('');
    const [Departure, setDeparture] = React.useState('');
    const [Return, setReturn] = React.useState('');
    const [isSent, setIsSent] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [mainArray, setMainArray] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [retArray, setRetArray] = React.useState([]);
    const [loader, setIsLoader] = React.useState(false);
    const [abortButton, setAbortButton] = React.useState(false);
    const [fade, setFade] = React.useState(false);
    const [requestData, setRequestData] = React.useState('');
    const [responseData, setResponseData] = React.useState('');
    const [fareCurrency, setFareCurrency] = React.useState('');
    const [checkbox, setCheckbox] = React.useState({
        'SG': true,
        'U2': true,
        '6E': true,
        'FR': true,

    });
    const [carriers, setCarriers] = React.useState([]);
    const [checkedCarrier, setCheckedCarrier] = React.useState([]);

    const [selectall, setSelectall] = React.useState({
        selectAll: true,
    });

    const [checkboxState, setCheckboxState] = React.useState([]);


    const [responsebutton, setResponsebutton] = React.useState(false);
    const [errMessage, setErrMessage] = React.useState('');
    const [color, setColor] = React.useState(true)
    // const [ clicked , setClicked] =  React.useState(false)
    const [marketState, setMarketState] = React.useState([]);
    const [sitesIn, setSitesIn] = React.useState('')
    const [selectedbutton, setSelectedbutton] = React.useState(null);
    const [selectedreturn, setSelectedreturn] = React.useState([]);
    // const [showReturn, setShowreturn] = React.useState(true);
    const [showOneway, setShowoneway] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState([]);


    var [isPermanent, setPermanent] = React.useState(true);
    const [FromMulticity, setFromMulticity] = React.useState('');
    const [FromLabelMulticity, setFromLabelMulticity] = React.useState('');
    const [ToMulticity, setToMulticity] = React.useState('');
    const [ToLabelMulticity, setToLabelMulticity] = React.useState('');
    const [response, setResponse] = React.useState({});
    const [price, setPrice] = React.useState([]);
    const [dynamicrequest, setDynamicrequest] = React.useState({});
    const [test, setTest] = React.useState({});
    const [dynamiccheck, setDynamiccheck] = React.useState({
        checked: true,
    })
    const [autocheck, setAutocheck] = React.useState({
        checked: true,
    })
    const [req, setReq] = React.useState({});
    const [abort, setAbort] = React.useState(false)
    const [text, setText] = React.useState(false)
    const [abortFn, setAbortFn] = React.useState(false)
    const [displayRem, setDisplayRem] = React.useState(false)
    const [responseRec, setResponserec] = React.useState([])
    const [abortRes, setAbortRes] = React.useState(0)
    const [totallength, setTotallength] = React.useState(null)
    const [loadbutton, setLoadbutton] = React.useState(false)
    var [InfantValue, setInfantValue] = React.useState([{
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    }]);
    const [sitelength, setSitelength] = React.useState('');
    const [sourceobject, setSourceobject] = React.useState({})
    const [copyobject, setCopyobject] = React.useState({})
    const [filter, setFilter] = React.useState({
        airline: [],

        stops: [],
        stopover: [],
        sites: []
    })
    const [filterSegment, setFilterSegment] = React.useState([]);


    useEffect(() => {
        abortValue = abort;
        // console.log('abortValue====>', abortValue);
    }, [abort]);


    useEffect(() => {
        superagent.post(config.apiUrl + "/api/Flight/MarketCountryPreferred")

            .set('Content-Type', 'application/json')
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .then(response => {
                if (response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4' || response.body.message === 'ERMWMSG2' || response.body.message === 'ERMWMSG5') {
                    // console.log(response);
                    alert(errorData[response.body.message])
                    signOut(userDispatch, props.history)
                }
                else {

                    setMarketState([...response.body[0]])
                    setCheckboxState(response.body[1])

                }
            })

    }, []);


    useEffect(() => {

        if (marketState.length)
            handleMarket();

    }, [marketState]);


    useEffect(() => {

        if (checkboxState.length)
            handleMarket();

    }, [checkboxState]);




    useEffect(() => {

        var allChecked = true;
        carriers.map((val) => {


            if (!val.isChecked) {
                allChecked = false;
            }

        })

        if (allChecked) {
            setSelectall({ selectAll: true });
        }
        else {
            setSelectall({ selectAll: false });
        }
    }, [carriers]);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });


    useEffect(function () {
        // console.log(copyobject, response);
        if (copyobject.AirCompareRS != undefined) {
            if (Object.keys(response).length == 0) {
                setResponse(copyobject);
                setResponseData(JSON.stringify(copyobject))
            }
            else if (response.status != undefined) {
                if (response.status.noOfSitesCompleted < copyobject.status.noOfSitesCompleted) {
                    setLoadbutton(true);
                }
                else {
                    setLoadbutton(false);
                }
            }
        }
    }, [copyobject])

    useEffect(() => {
        handleFilter();
    }, [response]);

    useEffect(() => {

        var filteredAirline = [];
        var filteredStop = [];
        var filteredStopOver = [];
        var filteredSites = [];

        filteredAirline = filter.airline.filter((val) => val.checked).map((val) => val.value);
        filteredStop = filter.stops.filter((val) => val.checked).map((val) => val.value);
        filteredStopOver = filter.stopover.filter((val) => val.checked).map((val) => val.value);
        filteredSites = filter.sites.filter((val) => val.checked).map((val) => val.value);
        if (Object.keys(response).length > 0 && response.AirCompareRS.Flights.length > 0) {

           
            var filterItinerary = response.AirCompareRS.Flights.filter((dataArray) => {
                var airlineExist = false;
                var stopOverExist = false;
                var stopCountExist = false;
                var stopSites = false; 
           
              
                Object.keys(dataArray.Segment).map((itinerary, ind) => {

                    // console.log(itinerary)

                    for (let i = 0; i < dataArray.Segment[itinerary].length; i++) {
                        if (filteredAirline.includes(dataArray.Segment[itinerary][i].MarketingAirline)) {
                            airlineExist = true;
                        }
                        if (i > 0) {
                            // Stop over filter
                            if (filteredStopOver.includes(dataArray.Segment[itinerary][i].DepartureAirport)) {
                                stopOverExist = true;
                            }
                        }
                        if (airlineExist && stopOverExist) {
                            break;
                        }
                    }
                
                    // Stop count filter
                    if (filteredStop.includes(dataArray.Segment[itinerary].length - 1)) {
                        stopCountExist = true;
                    }

                    if (dataArray.Segment[itinerary].length == 1) {
                        stopOverExist = true;
                    }
                   
                   dataArray.FlightPrice.filter((Segments)=>{
       
                               if(filteredSites.includes(Segments.source)){
                             stopSites = true;
                        }
                    
                        
                    })
          
                });
             

                if (airlineExist && stopOverExist && stopCountExist && stopSites) {

                    return true;
                }
                else {
                    return false;
                }
            });
        }
        setFilterSegment(filterItinerary);

    }, [filter]);



    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }








    const handleCheckboxSelectall = name => event => {
        setSelectall({ ...selectall, [name]: event.target.checked });
        if (event.target.checked) {
            // setCheckbox({
            //     'SG': true, 'U2': true, '6E': true, 'FR': true,

            // })
            carriers.map((val) => {
                val.isChecked = true;
            })
            setCarriers(carriers);
        }
        else {
            carriers.map((val) => {
                val.isChecked = false;
            })
            setCarriers(carriers);
            // setCheckbox({
            //     'SG': false, 'U2': false, '6E': false, 'FR': false,
            // })
        }


    };



    // const handleCheckboxSelectall1 = ()=> {


    // };



    const handleCheckbox = name => event => {
        var tempCarriers = carriers;
        tempCarriers.map((val) => {
            if (val.carrierCode == event.target.value) {
                val.isChecked = !val.isChecked;
            }
        })
        setCarriers([...tempCarriers]);
        // console.log(carriers)


        // var tempCheckBox = { ...checkbox };
        // tempCheckBox[name] = event.target.checked;
        // setCheckbox(tempCheckBox);

        // setCheckbox({ ...checkbox, [name]: event.target.checked } );
        // if(checkbox.SG&&checkbox.EJ&&checkbox.IG&&checkbox.RA== event.target.checked){
        //     setSelectall({selectAll:true});
        // }
    };


    const handleClickOpen = () => {
        // handleMarket();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeSelect = event => {
        setCabin(event.target.value);
    };





    const handleMarket = (event) => {

        // console.log(marketState,event);
        var selectedCountry;
        if (event != undefined) {
            selectedCountry = event.target.value;
        }
        else {
            selectedCountry = marketState[0].countryCode;
        }

        //  sitesIn ='';
        // setCarriers
        setMarket(selectedCountry);
        var selectedCarriers = [];
        marketState.map((key) => {

            if (key.countryCode == selectedCountry) {
                selectedCarriers = key.preferred;
            }
        })
        var carrierValue = [];
        selectedCarriers.map((val) => {
            checkboxState.map((sites) => {
                if (sites.name == val) {
                    var carrier = {
                        carrierCode: val,
                        isChecked: true,
                        label: sites.siteName
                    }
                    carrierValue.push(carrier);
                }
            })
        })
        setCarriers(carrierValue);

        // e.target.value = e.target.value.toUpperCase();

        // if(marketState.countryCode)        




    };
    const handleChangeAdult = event => {
        setAdult(event.target.value);
        var maxInfant = [];
        var maxInfantCount = event.target.value;
        var i = 0;
        while (i <= maxInfantCount) {
            maxInfant.push({
                value: i,
                label: i
            });
            i++;
        }
        setInfantValue(maxInfant);
    };
    const handleChangeChild = event => {
        setChild(event.target.value);
    };
    const handleChangeInfant = event => {

        setInfant(event.target.value);

    };

    const handleDateChange2 = date => {
        setSelectedDate2(date);
    };
    const handleChangeRadio = event => {
        setValue(event.target.value);
    };

    const responseButton = () => {
        setResponsebutton(!responsebutton);
    }


    const responseButtonClose = () => {
        setResponsebutton(false);
    }



    const handleMulticity = () => {

        setShowmulticity(true);
    }


    const handleFilter = () => {

        var airlineSegment = [];
        var airlinearray = [];
        var maxCount = 0;
        var stopOvers = [];
        var StopOversFilter = [];
        var stopCounts = [];
        var sourcearray = [];
        var sourcefilter = [];

        //  response.AirCompareRS != undefined && response.AirCompareRS.Flights.length > 0 ?
        // console.log(response, '22')
        if (response.AirCompareRS != undefined) {
            response.AirCompareRS.Flights.map((airid, idind) => {

                for (var key in airid.Segment) {
                    airid.Segment[key].map((air, airind) => {
                        // console.log(airid.Segment[key])
                        if (!airlineSegment.includes(air.MarketingAirline)) {
                            airlineSegment.push(air.MarketingAirline)
                            var airvar = {
                                label: air.MarketingAirline,
                                value: air.MarketingAirline,
                                checked: true
                            }
                            airlinearray.push(airvar)
                        }
                        if (maxCount < airid.Segment[key].length) {
                            maxCount = airid.Segment[key].length
                        }
                        if (airid.Segment[key].length > 1) {
                            for (let i = 1; i < airid.Segment[key].length; i++) {
                                if (!stopOvers.includes(airid.Segment[key][i].DepartureAirport)) {
                                    stopOvers.push(airid.Segment[key][i].DepartureAirport)
                                    var stopAir = {
                                        label: airid.Segment[key][i].DepartureAirport,
                                        value: airid.Segment[key][i].DepartureAirport,
                                        checked: true
                                    }
                                    StopOversFilter.push(stopAir)
                                }
                            }
                        }



                    })
                }
            })
        }

        for (var i = 0; i < maxCount; i++) {
            var stopNumber = {
                label: '',
                value: '',
                checked: true
            };
            stopNumber.value = i;
            if (i == 0) {
                stopNumber.label = 'Non-Stop';
            }
            else if (i == 1) {
                stopNumber.label = '1 Stop';
            }
            else {
                stopNumber.label = i + ' Stops';
            }
            stopCounts.push(stopNumber);
        }
        if (response.AirCompareRS != undefined) {
            response.AirCompareRS.Sources.map((name, nameind) => {
                if (!sourcearray.includes(name.SourceID)) {
                    sourcearray.push(name.SourceID)
                    var sourcevar = {
                        label: name.SourceName + '(' + name.SourceID + ')',
                        value: name.SourceID,
                        checked: true
                    }
                    sourcefilter.push(sourcevar)
                }



            })
        }

        airlinearray.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        StopOversFilter.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        stopCounts.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        sourcefilter.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });





        // }):null

        setFilter({ ...filter, airline: airlinearray, stops: stopCounts, stopover: StopOversFilter, sites: sourcefilter })


    }


    const handleDeparture = e => {
        setDeparture(e.target.value);
    };

    const handleFrom = e => {

        e.target.value = e.target.value.toUpperCase();
        setFrom(e.target.value);
    };
    const handleTo = e => {
        e.target.value = e.target.value.toUpperCase();
        setTo(e.target.value);

    };
    function handleChange(i, event) {
        const values = [...fields];
        values[i].value = event.target.value;
        setFields(values);


    }
    const handleChangeStatic = name => event => {
        setDynamiccheck({ ...dynamiccheck, [name]: event.target.checked });
    };
    const handleChangeAuto = name => event => {
        setAutocheck({ ...autocheck, [name]: event.target.checked });
    };
    function handleChange1(i, event) {
        const values1 = [...fields1];
        values1[i].value = event.target.value;
        setFields1(values1);
    }

    function handleAdd() {

        const values = [...fields];

        if (values.length <= 3) {
            values.push({ value: null });

            setFields(values);
        } else {
            return null;
        }
        setAddbutton(true);
    }

    function handleRemove(i) {
        const values = [...fields];

        if (values.length <= 1) {
            { OnewayRadio() }

        }
        else {

            values.splice(i, 1);
            setFields(values);

        }

    }
    const loadbuttonfn = () => {
        setResponse(copyobject);
        setResponseData(JSON.stringify(copyobject))
        setLoadbutton(false);
    }

    const ReturnRadio = () => {
        setShowReturn(true);
        setAddbutton(false);
        setAddbutton1(false);
        setJourneyType('return');
        setShowmulticity(false);
        setEmpty(false);

        setShowoneway(false)
    }


    const OnewayRadio = () => {
        setShowReturn(false);
        setAddbutton(false);
        setAddbutton1(false);
        setShowmulticity(false);
        setValue('oneway');
        setEmpty(true);
        setShowoneway(true);
        // setShowreturn(false);

    }

    const MulticityRadio = () => {

        setShowReturn(false);
        setAddbutton(true);
        setAddbutton1(true);
        setValue('multicity');


    }
    var dynamicLoadTimeOut;
    var responseReceived = {};
    var abortRequest = false;
    var receive = {};

    function dynamicSetTimeout(resStatus) {
        dynamicLoadTimeOut = setTimeout(() => handleDynamicresponse(resStatus), 2000);
    }


    var handleDynamicresponse = (response) => {
        var DynamicLoad;
        superagent.post(config.apiUrl + "/api/Flight/DynamicLoad")
            .send(response)
            .set('Content-Type', 'application/json')
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .timeout({
                deadline: 4000
            })
            .then(dynamicRes => {



                dynamicRes = JSON.parse(dynamicRes.text);
                setResponserec(dynamicRes)
                // console.log('dynamic', dynamicRes)

                var resStatus = {};

                if (dynamicRes.AirCompareRS != undefined) {
                    setAbortRes(dynamicRes.AirCompareRS.Sources.length);
                }
                if (dynamicRes.AirCompareRS != undefined && dynamicRes.AirCompareRS.Flights.length > 0) {
                    responseExist = true;
                }


                resStatus.status = dynamicRes.status;
                // console.log('inside', abortValue);
                if (abortValue) {

                    resStatus.status.abort = 1;
                    setAbort(false)
                    clearTimeout(dynamicLoadTimeOut);

                }
                setRequestData(JSON.stringify(request))
                if (dynamicRes.status.render == 1) {
                    // console.log('render');
                    if (dynamicRes.AirCompareRS != undefined && Object.keys(dynamicRes.length != 0)) {
                        if (autocheck.checked) {
                            setResponse(dynamicRes);
                            setResponseData(JSON.stringify(dynamicRes))
                        }
                        else {
                            setCopyobject(dynamicRes);
                        }
                        setFade(false)
                        setIsLoading(true)

                    }
                }
                if (dynamicRes.status.completed == 0) {
                    dynamicSetTimeout(resStatus);
                }
                else {
                    setIsLoader(false);
                    setAbortButton(false);
                    if (dynamicRes.AirCompareRS == undefined && response.AirCompareRS == undefined) {
                        setFade(true);
                        setErrMessage('No Flights Found!');
                    }
                }

            })
            .catch((err, res) => {
                // console.log(err);
            })


    }

    const searchBefore = () => {
        setAbortButton(true);
        setIsLoader(true);
        setDisplayRem(true);
        setAbortRes(0);
        setFade(false);
        setTimeout(() => {
            clearTimeout(dynamicLoadTimeOut);
            setIsLoader(false);
            setAbortButton(false);
            if (!responseExist) {
                setFade(true);
                setErrMessage('No Flights Found!');
            }
        }, 130000);
        setResponse({});
        setCopyobject({});
    }
    const abortfunction = () => {
        // setDisplayRem(true)

        // setIsLoading(true)
        setIsLoader(false)
        setAbortFn(false)
        setAbortButton(false);
        // console.log('abort clicked');
        var abortFlag = true;
        setAbort(abortFlag);

    }


    var request;

    const submit = (e) => {
        e.preventDefault();
        setAbort(false)
        var isValidation = true;

        // console.log(infant)
        if (From.length != 3) {
            alert(' From and TO should be three characters')
            isValidation = false;
        }

        if (To.length != 3) {
            alert(' From and TO should be three characters')
            isValidation = false;
        }
        if (parseInt(adult) + parseInt(child) + parseInt(infant) > 9) {
            alert(' the passengers should not more than 9')
            isValidation = false;
        }
        // console.log(showReturn);
        if (showReturn && (new Date(FromDate) > new Date(ToDate))) {
            alert('Return Date should be greater than departure date')
            isValidation = false;
        }


        if (isValidation) {
            setErrMessage('')
            setAbortFn(true)
            setIsLoader(true);
            // setAbortButton(true);
            var carrier = [];
            var j = 0;//don't change
            // for (var i in checkbox) {
            //     // console.log(i);
            //     if (j == 0 && checkbox[i]) {
            //         carrier += i;
            //         j++;
            //     } else if (checkbox[i]) {
            //         carrier += ',';
            //         carrier += i;
            //     }
            //     // if ((j < Object.keys(checkbox).length-1)&&checkbox[i]==true) {

            // }
            var checkedCarrierTemp = []
            carriers.map((val) => {

                if (val.isChecked == true) {
                    carrier.push(val.carrierCode);
                    checkedCarrierTemp.push(val);
                }

            })
            setCheckedCarrier(checkedCarrierTemp);





            // function dateConverter(date) {
            //     return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            // }
            setTotallength(carrier.length)
            request = {
                "from": From,
                "to": To,
                "dep": FromDate.toISOString().slice(0, 10),
                "ret": "",
                "cabin": cabin,
                "adult": adult,
                "child": child,
                "infant": infant,
                "marketcountrypreferred": market,
                "sitePreferred": carrier
            }

            if (showReturn) {
                request.ret = ToDate.toISOString().slice(0, 10);
            }
            if (dynamiccheck.checked == true) {


                superagent.post(config.apiUrl + "/api/Flight/AirCompareRQ")
                    .send(request)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                    .set({ 'login-key': localStorage.getItem('token') })
                    .timeout({
                        deadline: 130000
                    })
                    .then(response => {
                        searchBefore();

                        response = JSON.parse(response.text);


                        handleDynamicresponse(response);

                        // const socket = socketIOClient(config.apiUrl);
                        // socket.on('marketCompareChange', (data) => {
                        //     console.log(data);
                        // });

                    })

            }



            // console.log(response)
            //    response = response.text

            // console.log(response)

            //     Object.keys.response.map((req)=>{
            //         var requ={
            //             "searchId":req.searchId
            //         }
            //         console.log(requ)
            //   })



            if (dynamiccheck.checked == false) {


                superagent.post(config.apiUrl + "/api/Flight/MarketCompareRQ")
                    // superagent.post(config.apiUrl + "/api/Flight/DynamicLoad")

                    .send(request)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                    .set({ 'login-key': localStorage.getItem('token') })
                    .timeout({
                        deadline: 180000
                    })
                    .then(response => {
                        // console.log(1)


                        // console.log(response)
                        setRequestData(JSON.stringify(request))
                        response = JSON.parse(response.text);
                        // console.log('static', response)

                        if (response.AirCompareRS != undefined && Object.keys(response.length != 0)) {
                            setResponse(response);
                            setResponseData(JSON.stringify(response))

                            setFade(false)
                            setIsLoading(true)
                            setIsLoader(false)

                        }
                        else if (Object.keys(response).length == 0) {

                            setIsLoader(false)
                            setFade(true);
                            setErrMessage('No Flights Found!');
                        }
                        else if (response.message === 'ERMWMSG3' || response.message === 'ERMWMSG4' || response.message === 'ERMWMSG2' || response.message === 'ERMWMSG5') {
                            alert(errorData[response.message])
                            signOut(userDispatch, props.history)
                        }

                    }, err => {
                        setIsLoader(false);
                        setIsLoading(false);
                        setFade(true);
                        setErrMessage('Request timeout!');
                    })


            }



        }


        setIsLoading(false);



    }

    const handleChangeFilter = event => {

        var filterName = event.target.name;
        var tempFilter = filter[filterName];

        if (event.target.value[event.target.value.length - 1] == 0) {
            tempFilter[0].checked ? (tempFilter.map((val) => val.checked = false)) : (tempFilter.map((val) => val.checked = true));
        }
        else {
            tempFilter[event.target.value[event.target.value.length - 1]].checked = !tempFilter[event.target.value[event.target.value.length - 1]].checked;
        }

        var isAllSelect = true;
        for (let i = 1; i < tempFilter.length; i++) {
            if (!tempFilter[i].checked) {
                isAllSelect = false;
                break;
            }
        }
        tempFilter[0].checked = isAllSelect;
        setFilter({ ...filter, [filterName]: tempFilter });



    }








    var tripsegment;
    var checkedSitesCount = 0;
    var checkedSitesIterator = 0;
    return (
        <React.Fragment>
            {/* <CssBaseline /> */}
            <div className={classes.header}>
                <Typography className={classes.Typography} variant="h3" component="h2"  >
                    Flight Compare
      </Typography>
            </div>
            <Container className={classes.container} style={{ marginBottom: '30px' }}>

                {/* <Card className={classes.card}>
    <CardContent> */}
                <FormControl component="fieldset">

                    <RadioGroup aria-label="position" name="position" value={value} onChange={handleChangeRadio} row>

                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                onClick={ReturnRadio}

                                value="return"
                                id="form1"
                                control={<Radio color="primary" />}
                                label="RETURN"
                                labelPlacement="start"
                            />


                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                value="oneway"
                                onClick={OnewayRadio}
                                id="form2"
                                control={<Radio color="primary" />}
                                label="ONEWAY"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <Tooltip title="Coming Soon">
                                <FormControlLabel
                                    value="disabled"
                                    // onClick={MulticityRadio}
                                    disabled
                                    control={<Radio color="primary" />}
                                    label="MULTICITY"
                                    onMouseOver="Coming Soon"
                                    labelPlacement="start"
                                />
                            </Tooltip>

                        </Grid>









                        <form onSubmit={submit} noValidate autoComplete="off">
                            <div className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid item sm={3} xs={6} lg={3} xl={3} >
                                        <Autocomplete
                                            id="auto-complete"
                                            options={airportList}
                                            // getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}
                                            getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')' : option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}

                                            inputValue={FromLabel}

                                            className={classes.textField}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    setFrom(newValue.id);
                                                    setFromLabel(newValue.value.split('|')[2] != '' ? newValue.value.split('|')[2] : newValue.value.split('|')[1].split(' ')[0]);
                                                }
                                                else {
                                                    setFrom('');
                                                }
                                            }}
                                            onBlur={() => {
                                                setAirportList([]);
                                            }}
                                            onInputChange={(event, value) => {
                                                setFromLabel(value);
                                                if (value.length >= 3) {
                                                    superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                        .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                        .set({ 'login-key': localStorage.getItem('token') })
                                                        .send({
                                                            "code": value
                                                        })
                                                        .then(res => {

                                                            setAirportList(res.body)
                                                        })
                                                }
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <TextField {...params} label="From" fullWidth />
                                                )
                                            }}
                                        />

                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3} >



                                        <Autocomplete
                                            id="auto-complete"
                                            options={airportList}
                                            getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')' : option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}

                                            // getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}

                                            inputValue={ToLabel}
                                            margin="normal"
                                            className={classes.textField}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    setTo(newValue.id);
                                                    setToLabel(newValue.value.split('|')[2] != '' ? newValue.value.split('|')[2] : newValue.value.split('|')[1].split(' ')[0]);
                                                }
                                                else {
                                                    setTo('');
                                                }
                                            }}
                                            onBlur={() => {
                                                setAirportList([]);
                                            }}
                                            onInputChange={(event, value) => {
                                                setToLabel(value);
                                                if (value.length >= 3) {
                                                    superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                        .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                        .set({ 'login-key': localStorage.getItem('token') })
                                                        .send({
                                                            "code": value
                                                        })
                                                        .then(res => {

                                                            setAirportList(res.body)
                                                        })
                                                }
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <TextField {...params} label="To" fullWidth />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    {/* {console.log(FromDate)} */}
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // disableToolbar
                            autoOk
                            variant="inline"
                            format="yyyy/MM/dd"
                            // formatDate={(date) => Moment(date).format('yyyy/MM/dd')}
                            margin="normal"
                            id="date-picker-inlineDeparture"
                            name="Departure"
                            label="Departure"
                            className={classes.textField}
                            value={FromDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk



                                                label="Departure"
                                                value={FromDate}
                                                onChange={handleDateChange}
                                                format="dd MMM yyyy"
                                                animateYearScrolling
                                                className={classes.textField}
                                                minDate={startDate}
                                                errorText="This is an error message."
                                                // shouldDisableDate={disablePrevDates(startDate)}
                                                utcOffset={0}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>


                                    {showReturn
                                        ?

                                        <Grid item sm={3} xs={6} lg={3} xl={3}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              format="yyyy/MM/dd"
                              margin="normal"
                              // name="Return"
                              id="date-picker-inlineReturn"
                              label="Return"
                              value={ToDate}
                              className={classes.textField}
                              onChange={handleDateChange1}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk


                                                    errorText="This is an error message."
                                                    label="Return"
                                                    value={ToDate}
                                                    onChange={handleDateChange1}
                                                    format="dd MMM yyyy"
                                                    animateYearScrolling
                                                    className={classes.textField}
                                                    minDate={FromDate}
                                                // shouldDisableDate={disablePrevDates1(FromDate)}
                                                // min={format(FromDate, "dd/MM/yyyy")}
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>

                                        : null
                                    }

                                    {empty ?


                                        <Grid item sm={3} xs={6} lg={3} xl={3}>
                                            <div className={classes.textField}>
                                                <span>&nbsp;&nbsp;  </span>


                                            </div>

                                        </Grid>




                                        : null
                                    }


                                    {addbutton === true && showReturn === false ?
                                        <div>

                                            {
                                                fields.map((field, idx) => {
                                                    return (
                                                        <div key={'${field}-${idx}'}>
                                                            <Grid container spacing={2}>
                                                                <Grid item sm={3} xs={6} lg={3} xl={3} >
                                                                    <Autocomplete
                                                                        id="auto-complete"
                                                                        options={airportList}
                                                                        getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}
                                                                        inputValue={FromLabelMulticity}

                                                                        className={classes.textField}
                                                                        onChange={(event, newValue) => {
                                                                            if (newValue != null) {
                                                                                setFromMulticity(newValue.id);
                                                                                setFromLabelMulticity(newValue.value.split('|')[2]);
                                                                            }
                                                                            else {
                                                                                setFromMulticity('');
                                                                            }
                                                                        }}
                                                                        onInputChange={(event, value) => {
                                                                            setFromLabelMulticity(value);
                                                                            if (value.length == 3) {
                                                                                superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                                                    .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                                                    .set({ 'login-key': localStorage.getItem('token') })
                                                                                    .send({
                                                                                        "code": value
                                                                                    })
                                                                                    .then(res => {

                                                                                        setAirportList(res.body)
                                                                                    })
                                                                            }
                                                                        }}
                                                                        renderInput={params => {
                                                                            return (
                                                                                <TextField {...params} label="From" fullWidth />
                                                                            )
                                                                        }}
                                                                    />

                                                                </Grid>
                                                                <Grid item sm={3} xs={6} lg={3} xl={3} >



                                                                    <Autocomplete
                                                                        id="auto-complete"
                                                                        options={airportList}
                                                                        getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}

                                                                        inputValue={ToLabelMulticity}
                                                                        margin="normal"
                                                                        className={classes.textField}
                                                                        onChange={(event, newValue) => {
                                                                            if (newValue != null) {
                                                                                setToMulticity(newValue.id);
                                                                                setToLabelMulticity(newValue.value.split('|')[2]);
                                                                            }
                                                                            else {
                                                                                setToMulticity('');
                                                                            }
                                                                        }}
                                                                        onInputChange={(event, value) => {
                                                                            setToLabelMulticity(value);
                                                                            if (value.length == 3) {
                                                                                superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                                                    .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                                                    .set({ 'login-key': localStorage.getItem('token') })
                                                                                    .send({
                                                                                        "code": value
                                                                                    })
                                                                                    .then(res => {

                                                                                        setAirportList(res.body)
                                                                                    })
                                                                            }
                                                                        }}
                                                                        renderInput={params => {
                                                                            return (
                                                                                <TextField {...params} label="To" fullWidth />
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>


                                                                <Grid item sm={3} xs={6} lg={3} xl={3}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <DatePicker
                                                                            autoOk



                                                                            label="Departure"
                                                                            value={FromDateMulticity}
                                                                            onChange={handleDateChangeMulticity}
                                                                            format="dd MMM yyyy"
                                                                            animateYearScrolling
                                                                            className={classes.textField}
                                                                            minDate={startDate}
                                                                            // shouldDisableDate={disablePrevDates(startDate)}
                                                                            utcOffset={0}

                                                                        />
                                                                    </MuiPickersUtilsProvider>

                                                                </Grid>


                                                                {fields.length ?
                                                                    <Grid item sm={1}>

                                                                        <DeleteIcon
                                                                            onClick={() => handleRemove()}
                                                                        />

                                                                    </Grid>
                                                                    : null}

                                                                {fields.length < 4 ?

                                                                    <Grid item sm={1}>
                                                                        <AddCircleIcon onClick={() => handleAdd()} />

                                                                    </Grid>
                                                                    : null}

                                                            </Grid>
                                                        </div>

                                                    )
                                                }


                                                )
                                            }



                                        </div>
                                        : null}






                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            label="Cabin"
                                            name="cabin"
                                            className={classes.textField}
                                            value={cabin}
                                            onChange={handleChangeSelect}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {currencies.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>




                                    <Grid item sm={3} xs={6} lg={3} xl={3}>

                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            label="Adult"
                                            className={classes.textField}
                                            // name="Adultfield"
                                            value={adult}
                                            onChange={handleChangeAdult}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {Adult.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}.
                        </TextField>

                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            name="Childfield"
                                            label="Child"
                                            className={classes.textField}
                                            value={child}
                                            onChange={handleChangeChild}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {Child.map(option => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            name="Infant"
                                            className={classes.textField}
                                            value={infant}
                                            label="Infant"
                                            onChange={handleChangeInfant}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {InfantValue.map(option => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={3} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select

                                            label="Market Country"
                                            // name="Marketcountry"
                                            className={classes.textField}
                                            value={market}
                                            onChange={handleMarket}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >


                                            {marketState.map((country, i) => (

                                                <MenuItem key={country.countryCode} value={country.countryCode} selected >
                                                    {country.countryCode}
                                                </MenuItem>


                                            ))}


                                        </TextField>
                                    </Grid>



                                    <Grid item sm={2} xs={2} lg={2} xl={2}>
                                        <Button color="primary" className={classes.buttonViewSites} onClick={handleClickOpen}>
                                            sites
      </Button>

                                        <Dialog open={open} aria-labelledby="form-dialog-title">

                                            <DialogContent>
                                                <Typography variant="h3" component="h5" color="primary">
                                                    <center> Sites  </center>
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item sm={12}>

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectall.selectAll}
                                                                    onChange={handleCheckboxSelectall('selectAll')}
                                                                    value="selectAll"
                                                                    // onClick={handleCheckboxSelectall1}
                                                                    inputProps={{
                                                                        'aria-label': 'uncontrolled-checkbox',
                                                                    }}
                                                                />}
                                                            label="Select All" />
                                                    </Grid>


                                                    {/* {console.log(checkboxState)}
                                                        {console.log('ch', checkbox)}
                                                        {checkBoxElement = <Checkbox
                                                            checked={checkbox.sitesIn}
                                                            onChange={handleCheckbox(sitesIn)}
                                                            value={sitesIn}
                                                            inputProps={{
                                                                'aria-label': 'uncontrolled-checkbox',
                                                            }}
                                                        />}
                                                        <Grid item sm={3}>
                                                            <FormControlLabel
                                                                control={
                                                                    checkBoxElement
                                                                }
                                                                label={checkboxState}
                                                            />
                                                        </Grid> */}

                                                    {
                                                        carriers.map((val) => {
                                                            // console.log(val)

                                                            return (
                                                                <Grid item sm={4}>
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            checked={val.isChecked}
                                                                            onChange={handleCheckbox(val.carrierCode)}
                                                                            value={val.carrierCode}
                                                                            inputProps={{
                                                                                'aria-label': 'uncontrolled-checkbox',
                                                                            }}
                                                                        />}
                                                                        label={val.label}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                    {/* {checkBoxElement +=   <FormControlLabel control={
                                                            <Checkbox
                                                                checked={carriers.isChecked}
                                                                onChange={handleCheckbox(carriers.carrierCode)}
                                                                value={carriers.carrierCode}
                                                                inputProps={{
                                                                    'aria-label': 'uncontrolled-checkbox',
                                                                }}
                                                            />}
                                                            label={carriers.label}
                                                        />}
                                                        {checkBoxElement} */}



                                                </Grid>
                                                <Button color="primary" onClick={handleClose} className={classes.buttonSites} variant="contained"
                                                    disabled={carriers.filter((val) => {
                                                        if (val.isChecked == true) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }

                                                    }).length < 1}
                                                >
                                                    OK
                                                     </Button>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                    <Grid item sm={3} xs={3} lg={3} xl={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={dynamiccheck.checked} className={classes.checkbox} onChange={handleChangeStatic('checked')} value="checked" disabled={loader} />
                                            }
                                            label="Dynamic Loading"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    {dynamiccheck.checked ?
                                        <Grid item sm={2} xs={3} lg={2} xl={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={autocheck.checked} className={classes.checkbox} onChange={handleChangeAuto('checked')} value="checked" disabled={loader} />
                                                }
                                                label="Auto Refresh"
                                                labelPlacement="start"
                                            />
                                        </Grid> : null}


                                    <Grid item sm={2} xs={3} lg={2} xl={2}>
                                        {loader ? (
                                            <CircularProgress size={26} className={classes.loginLoader} />
                                            // <Typography> <CircularProgress size={26} className={classes.loginLoader} />{'Completed  of '} {abortRes}{'/'}{totallength}</Typography>

                                        ) : (
                                                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                    search
                                        </Button>)
                                        }
                                    </Grid>

                                    <Grid item sm={6} xs={6} lg={3} xl={3} style={{ display: 'contents' }}>
                                        {dynamiccheck.checked == true ?
                                            abortFn ?
                                                <Grid item xl={5}>
                                                    <p>Displaying fares from:</p>
                                                    {checkedCarrier.map((display, i) => {
                                                        return (
                                                            <span> {display.isChecked ? display.label : null}{i < checkedCarrier.length - 1 ? ',' : '.'}</span>
                                                        )
                                                    })}
                                                </Grid>
                                                : null
                                            : null}
                                    </Grid>
                                    <Grid item sm={6} xs={6} lg={3} xl={3} style={{ textAlign: '-webkit-right', marginInlineStart: 'auto' }}>
                                        {dynamiccheck.checked == true ?
                                            (<Grid container direction='row'>
                                                <Grid item sm={6}>
                                                    {displayRem ? (<Typography>{'Completed of '} {abortRes}{'/'}{totallength}</Typography>) : (null)}
                                                </Grid>
                                                <Grid item sm={6}>
                                                    {abortButton ? (
                                                        <Button variant="contained" onClick={abortfunction} color="primary" className={classes.abortbutton}>
                                                            Abort
                                                                </Button>) : null}
                                                </Grid>
                                            </Grid>
                                            )


                                            : null}
                                    </Grid>
                                </Grid>
                            </div>
                        </form>




                    </RadioGroup>
                </FormControl>
                {/* </CardContent>
    </Card> */}
            </Container>
            {/* <Grid item xl={5}>

                {
                    response.AirCompareRS != undefined ? (
                        <div>
                            {
                                response.AirCompareRS.Sources.map((rec, inq) => {
                                    var text = rec.SourceName;
                                    console.log(text)
                                })
                            }
                        </div>
                    ) : (null)
                }
 */}


            {/* // {console.log(responseReceived)} */}

            {/* 
            </Grid> */}


            {
                fade ? (
                    <Typography className="container" color="Secondary" variant="h3" className={classes.fadeError}>
                        {errMessage}
                    </Typography>)
                    : ''
            }


            {
                isLoading && response.AirCompareRS != undefined ? (

                    <Grid >

                        {dynamiccheck.checked == true ?
                            <div className={classes.root}>

                                <p>Response received from:</p>
                                {


                                    responseReceived = (response.AirCompareRS != undefined) ? response.AirCompareRS.Sources.map((rec, index) => {
                                        return (

                                            //  console.log('rec',rec),

                                            <span>{rec.SourceName}{index < response.AirCompareRS.Sources.length - 1 ? ',' : '.'}</span>
                                        )
                                    }) : ''
                                }

                                {autocheck.checked == false && loadbutton ?
                                    <span style={{ float: 'right' }}>
                                        <Button variant="contained" onClick={loadbuttonfn} color="primary" className={classes.abortbutton}>
                                            Load
                                        </Button>
                                    </span> : null}

                            </div>
                            : null}




                        <div>
                            &nbsp;
                         </div>

                        {(config.accessReqRes.includes(userContextData.data[0]['role_id']) ? (
                            <div>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={responseButton}
                                    className={classes.LinkButton}
                                >
                                    View Request/Response
    </Link>


                                {responsebutton ?
                                    <div className="container" >
                                        <Grid Container >
                                            <Grid item xl={5}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Request"
                                                    multiline
                                                    rows="4"
                                                    value={requestData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xl={2} ></Grid>
                                            <Grid item xl={5} >
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Response"
                                                    multiline
                                                    rows="4"
                                                    value={responseData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xl={2} >
                                                {
                                                    response.AirCompareRS != undefined ? (
                                                        response.AirCompareRS.Sources.map((link, ino) => {
                                                            return (
                                                                <TextField
                                                                    id="outlined-multiline-static"
                                                                    label={link.SourceName}
                                                                    multiline
                                                                    rows="1"
                                                                    style={{ marginRight: '7px' }}
                                                                    value={link.Url}
                                                                    // className={classes.textField}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                // fullWidth
                                                                />
                                                            )
                                                        })
                                                    ) : (null)
                                                }

                                            </Grid>

                                        </Grid>

                                    </div> : null}
                            </div>
                        ) : null)}

                        {/* <Grid Container >
                   <Grid item xl={5}>
                   <p>Displaying fares from:</p>
                      {carriers.map((display)=>{
                          return(
                        display.label
                          )
                      })}
                      </Grid>
                      <Grid item xl={5}>
                        <Button variant="contained" onClick={abortfunction} color="primary" className={classes.abortbutton}>
                            Abort
                            
                         </Button>
                         </Grid>
                         </Grid> */}



                        <div>
                            &nbsp;
                         </div>

                         <Grid Container spacing={1}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panecl1a-content"
                                    id="Filter"
                                >
                                    <Typography className={classes.heading}>Filters</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Airlines</InputLabel>
                                                <Select
                                                    id="FilterAirline"
                                                    name='airline'
                                                    multiple
                                                    value={filter.airline}
                                                    onChange={handleChangeFilter}
                                                    variant="outlined"
                                                    
                                                    input={<BootstrapInput />}

                                                    renderValue={() => {
                                                        return filter.airline.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Airline';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.airline.map((dataAirline, i) => {
                                                        return (
                                                            <MenuItem key={dataAirline.value} value={i}>
                                                                <Checkbox checked={dataAirline.checked} value={dataAirline.value} />
                                                                <ListItemText primary={dataAirline.label} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Stops</InputLabel>
                                                <Select
                                                    id="FilterStops"
                                                    name='stops'
                                                    multiple
                                                    value={filter.stops}
                                                    onChange={handleChangeFilter}
                                                    variant="outlined"
                                                    input={<BootstrapInput />}
                                                    //   renderValue={selected => selected.join(', ')}
                                                    renderValue={() => {
                                                        return filter.stops.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Stops';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.stops.map((dataStops, i) => {
                                                        return (

                                                            <MenuItem key={dataStops.value} value={i}>
                                                                <Checkbox checked={dataStops.checked} value={dataStops.value} />
                                                                <ListItemText primary={dataStops.label} />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select StopOvers</InputLabel>
                                                <Select
                                                    id="FilterStopOvers"
                                                    name='stopover'
                                                    multiple
                                                    value={filter.stopover}
                                                    onChange={handleChangeFilter}
                                                    variant="outlined"
                                                    input={<BootstrapInput />}
                                                    //   renderValue={selected => selected.join(', ')}
                                                    renderValue={() => {
                                                        return filter.stopover.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Stop Overs';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.stopover.map((dataStopsOver, i) => {
                                                        return (

                                                            <MenuItem key={dataStopsOver.value} value={i}>
                                                                <Checkbox checked={dataStopsOver.checked} value={dataStopsOver.value} />
                                                                <ListItemText primary={dataStopsOver.label} />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Sites</InputLabel>
                                                <Select
                                                    id="FilterStopOvers"
                                                    name='sites'
                                                    multiple
                                                    value={filter.sites}
                                                    onChange={handleChangeFilter}
                                                    variant="outlined"
                                                    input={<BootstrapInput />}
                                                    //   renderValue={selected => selected.join(', ')}
                                                    renderValue={() => {
                                                        return filter.sites.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Sites';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.sites.map((dataStopsOver, i) => {
                                                        return (

                                                            <MenuItem key={dataStopsOver.value} value={i}>
                                                                <Checkbox checked={dataStopsOver.checked} value={dataStopsOver.value} />
                                                                <ListItemText primary={dataStopsOver.label} />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                                     










                        <div>
                            &nbsp;
                         </div>

                        {/* {console.log(filterSegment)} */}
                        {
                            filterSegment!=undefined && filterSegment.length > 0 ? (
                            <div style={{ width: '100%' }}>{
                                filterSegment.map((itn, i) => {
                                    // filterSegment.map((itn, i) => {
                                    if (itn.Segment['ForwardSegments'].length) {



                                        var LowestSource = [];
                                        var LowestSourceId = [];
                                        var tempArray = [];
                                        var temp = '';
                                        itn.LowestSource.map((low, inx) => {
                                            LowestSource.push(low.source);

                                        });

                                        // response.AirCompareRS.Sources.map((url,ind)=>{

                                        //       temp  =(url.Url)
                                        //       console.log(temp);

                                        // })



                                        itn.FlightPrice.sort(function (a, b) { return a.AverageFare - b.AverageFare })
                                        var currency = response.AirCompareRS.Sources;
                                        var source = {};
                                        var array = '';




                                        currency.map((key) => {
                                            source[key.SourceID] = key;
                                        })

                                        // for(var i=0; i< itn.FlightPrice.length;i++){
                                        //     for(var j=0; j<itn.FlightPrice.length-i-1;j++){
                                        //         if(itn.FlightPrice[j].AverageFare > itn.FlightPrice[j+1].AverageFare){
                                        //             var temp = itn.FlightPrice[j];
                                        //             itn.FlightPrice[j] = itn.FlightPrice[j+1];
                                        //             itn.FlightPrice[j+1]= temp;
                                        //         }
                                        //     }

                                        // }

                                        // for (var i=0; i<itn.LowestSource.length;i++){
                                        //     LowestSource.push(LowestSource[i].source)
                                        // }




                                        return (
                                            //  itn.Segment['ForwardSegments'].length>0
                                            <div style={{ paddingBottom: '10px' }}>



                                                <Card spacing={2} className={classes.card}>
                                                    <Grid container spacing={1}>
                                                        <Grid item className={classes.padding} xs={1} style={{ textAlignLast: "center" }}>
                                                            {i + 1}
                                                        </Grid>
                                                        <Grid item className={classes.padding} xs={11}>
                                                            {/* <Grid container spacing={1} direction='row' > */}
                                                            {
                                                                <Grid container spacing={1} direction='row' >
                                                                    <Grid item className={classes.padding} xs={9}>
                                                                        {
                                                                            Object.keys(itn.Segment).map((flight, indnx) => {
                                                                                // {console.log(flight)}

                                                                                var airlineArray = [];
                                                                                var seg = '';
                                                                                var air = '';
                                                                                var stopFlig = '';
                                                                                var iternarystr = '';
                                                                                var numberStop = '';

                                                                                return (
                                                                                    <Grid item className={classes.padding} xs={12}>
                                                                                        {

                                                                                            itn.Segment[flight].length > 0 && Object.keys(itn.Segment[flight].length != 0) ? (




                                                                                                <Grid container spacing={1} direction='row' >









                                                                                                    <Grid item className={classes.padding} xs={2}>
                                                                                                        {itn.Segment[flight].map((key, inx) => {
                                                                                                            // if (!airlineArray.includes(key.MarketingAirline)) {
                                                                                                            if (!airlineArray.includes(key.MarketingAirline)) {


                                                                                                                airlineArray.push(key.MarketingAirline);
                                                                                                                return (
                                                                                                                    <img src={config.iconUrl + key.MarketingAirline + ".png"} onError={(e) => { e.target.onerror = null; e.target.src = config.iconUrl + 'plane.png'; e.target.style.height = '25px'; e.target.style.width = '25px'; }} alt={key.MarketingAirline} height="30px" width="30px" />
                                                                                                                )
                                                                                                                // airlineArray.push(key.MarketingAirline);

                                                                                                                // seg += airlineArray.length > 1 ? '/' + key.MarketingAirline : key.MarketingAirline;
                                                                                                            }
                                                                                                        })}
                                                                                                        <Typography variant="body2">{seg}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item className={classes.padding} xs={4}>
                                                                                                        {/* {itn.Segment[flight].map((key,inx)=>{
                                                                                                 air+= key.DepartureAirport;
                                                                                              })} */}
                                                                                                        {/* {console.log(itn.Segment[flight])} */}
                                                                                                        <Typography variant="body2">{itn.Segment[flight][0].DepartureDateTime.replace('|', ' ')}</Typography>

                                                                                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{itn.Segment[flight][0].DepartureAirport}</Typography>
                                                                                                        {/* <Typography>{air}</Typography> */}
                                                                                                    </Grid>

                                                                                                    {
                                                                                                        itn.Segment[flight].map((stopValue, stinx) => {
                                                                                                            if (stinx > 0) {
                                                                                                                stopFlig += itn.Segment[flight][stinx].DepartureAirport;
                                                                                                                if (stinx < itn.Segment[flight].length - 1) {
                                                                                                                    stopFlig += ', ';
                                                                                                                }


                                                                                                            }

                                                                                                            // if (itn.Segment[flight].length == 1) {
                                                                                                            //     numberStop += 'Non Stop'
                                                                                                            // } else {
                                                                                                            //     if (stinx < itn.Segment[flight].length - 1) {
                                                                                                            //         numberStop += Number(itn.Segment[flight].length - 1) + 'stop';
                                                                                                            //     }
                                                                                                            // }

                                                                                                        })


                                                                                                    }
                                                                                                    <Grid style={{ overflowWrap: 'break-word' }} item className={classes.padding} xs={2}>
                                                                                                        <Typography variant="body2">{itn.Segment[flight].length == 1 ? 'Non stop' : (itn.Segment[flight].length == 2 ? '1 stop' : (itn.Segment[flight].length - 1) + ' stops')}</Typography>
                                                                                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{stopFlig}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item className={classes.padding} xs={4}>
                                                                                                        <Typography variant="body2">{itn.Segment[flight][itn.Segment[flight].length - 1].ArrivalDateTime.replace('|', ' ')}</Typography>
                                                                                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}
                                                                                                        >{itn.Segment[flight][itn.Segment[flight].length - 1].ArrivalAirport}</Typography>

                                                                                                    </Grid>
                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        {/* {console.log( itn.Segment[flight])} */}
                                                                                                        {
                                                                                                            itn.Segment[flight].map((iternary, inx) => {
                                                                                                                iternarystr += itn.Segment[flight][inx].MarketingAirline + itn.Segment[flight][inx].MarketingAirlineFlightNumber + '-' + itn.Segment[flight][inx].DepartureAirport + itn.Segment[flight][inx].ArrivalAirport
                                                                                                                if (inx < itn.Segment[flight].length - 1) {
                                                                                                                    iternarystr += '/'
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                        <Typography variant="body2" style={{ fontStyle: "italic" }}>{iternarystr}</Typography>
                                                                                                    </Grid>


                                                                                                </Grid>







                                                                                            ) : (null)}
                                                                                    </Grid>


                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>  {/* grid -9 */}

                                                                    < Grid item className={classes.padding} xs={3}>
                                                                        {itn.FlightPrice.map((val, index) => {

                                                                            // console.log('val',val)
                                                                            // console.log("sourrce",source)
                                                                            // console.log('sou',source[val.source])


                                                                            return (
                                                                                <Grid style={{ marginBottom: '10px' }}>
                                                                                    {(response.AirCompareRS != undefined) ? response.AirCompareRS.Sources.map((source, sourceind) => {
                                                                                        array += source.SourceID
                                                                                    }) : null}



                                                                                    <Tooltip title={

                                                                                        val.source != undefined && val.source == 'SS' ? val.Agent.map((value) => {
                                                                                            return (
                                                                                                <Typography>
                                                                                                    <span style={{ float: 'left' }}>
                                                                                                        {value.Price != '' ? value.source : null}</span>
                                                                                                    {value.Price != '' && value.Price != undefined ? ':' : null}
                                                                                                    <span style={{ float: 'right' }}>
                                                                                                        {value.Price != '' && value.Price != undefined ? (value.Price).toFixed(2) : null}</span>
                                                                                                </Typography>
                                                                                            )
                                                                                        }) : ''}
                                                                                        enterDelay={300} leaveDelay={100}
                                                                                        placement="left-start"
                                                                                        interactive
                                                                                    // disableFocusListener
                                                                                    >










                                                                                        {/* <Button color="primary" value={source[val.source].Url } onClick={handleLink} variant={LowestSource.includes(val.source) ? "contained" : "outlined"}>{val.source}{' '}{val.AverageFare}{' '}{source[val.source].FareCurrency}</Button> */}

                                                                                        <a href={source[val.source].Url} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}
                                                                                        ><Button style={{ textDecoration: 'none', width: '60%' }}
                                                                                            color="primary" variant={LowestSource.includes(val.source) && itn.LowestSource[0].AverageFare == val.AverageFare ? "contained" : "outlined"}>
                                                                                                {val.source}{' '}{(val.AverageFare).toFixed(2)}{' '}{source[val.source].FareCurrency}
                                                                                            </Button></a></Tooltip>


                                                                                </Grid>
                                                                            )



                                                                        })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {/* </Grid> */}

                                                        </Grid>
                                                    </Grid>

                                                </Card>
                                            </div>
                                        )
                                    }
                                })
                            }
                            </div>
                            ) : (null)
                        }

                    </Grid>) : (null)
            }
        </React.Fragment >
    )
}