import React from "react";
import Typography from '@material-ui/core/Typography';
import Lcc from "../../pages/lcc/lcc";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useUserDispatch, Dashboard } from "../../context/UserContext";

export default function NotFound(props) {

    var userDispatch = useUserDispatch();
    const homePageFn = () => {
        Dashboard(userDispatch, props.history)
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <center>
                    <Typography className="container" color="Secondary" variant="h2" >
                        No Page Found
                    </Typography>
                </center>
            </Grid>

            <Grid item xs={12}>
                <center><Typography className="container" variant="h5" >
                    Redirect to <Button variant="outlined" onClick={homePageFn}> HomePage</Button>
                </Typography></center>
            </Grid>
        </Grid>
    )

}











