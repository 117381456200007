import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";



import Client from "../../pages/client_form/client"
import Lcc from "../../pages/lcc/lcc"
import UserForm from "../../pages/User_form/userform"
import sesEmail from "../../pages/Reports/SesEmail";
import NotFound from "../../pages/Not_Found/Not_Found";
import MetaPreview from "../../pages/Meta_Preview/Meta_Preview";
import MarketCompare from "../../pages/Market_Compare/Market_Compare";
import BulkSearch from "../../pages/Bulk-Search/Bulk-Search";
import MetaConfig from "../../pages/metaconfig/metaConfig";
import RoleAccess from "../../pages/roleAccess/roleAccess"
import Utilities from "../../pages/utilities/Utilities"

import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            {/* <Route path="/app/dashboard" component={Dashboard} /> */}
            {/* <Route path="/app/flights" component={Flights} /> */}
            <Route path="/app/client" component={Client} />
            <Route path="/app/lcc" component={Lcc} />
            <Route path="/app/UserForm" component={UserForm} />
            <Route path="/app/metaPreview" component={MetaPreview} />
            <Route path="/app/marketCompare" component={MarketCompare} />
            <Route path="/app/bulkSearch" component={BulkSearch} />
            <Route path="/app/metaConfig" component={MetaConfig} />
            <Route path="/app/roleAccess" component={RoleAccess} />
            <Route path = "/app/utilities" component = {Utilities} />


            <Route path="/app/sesEmail" component={sesEmail} />
            <Route path="*" exact={true} component={NotFound} />


                 


          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
