import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { Typography } from '@material-ui/core';
import axios from 'axios';
import config from '../../config/config';
import { useUserState } from "../../context/UserContext";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CommonFunction from '../../lib/commonFunction';
import Button from '@material-ui/core/Button';
import superagent from 'superagent'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  card: {
    padding: '20px',
  },
  header: {
    marginBottom: '15px'
  },
  fab: {
    size: 'small',
    variant: 'extended',
    position: 'absolute',
    top: theme.spacing(9),
    right: theme.spacing(2),
  },
}));


export default function RoleAccess(props) {
  const classes = useStyles();
  var userDispatch = useUserDispatch();
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  var userContextData = useUserState();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([])
  const [menu, setMenu] = React.useState([])
  const [roles, setRoles] = React.useState([])
  const [checkbox, setCheckbox] = React.useState([])
  const [role, setRole] = React.useState('');
  const [roleName, setRoleName] = React.useState('')
  const [admin, setAdmin] = React.useState('')
  const [roleDes, setRoleDes] = React.useState({})
  const [state, setState] = React.useState(false)
  const [checkAdd, setCheckAdd] = React.useState([])
  const [error, setError] = React.useState(false)
  const [disable, setDisable] = React.useState(false)
  const [alertMsg ,setAlertMsg] =  React.useState(false)
  const [alertUser ,setAlertUser] =  React.useState(false)
  const [alertDelete, setAlertDelete] = React.useState('')
  const [alertAdd, setAlertAdd] = React.useState('')
  const [rolesEdit , setRolesEdit] = React.useState(false)
  const [ errorCheckBox , setErrorCheckBox] = React.useState(false)




  const fetchData = async () => {
    const result = await axios(
      config.apiUrl + '/api/Flight/roleMenu',
      { headers: { 'login-key': localStorage.getItem('token') } }
    );
    if (result.data.status === true) {
      setData(result.data.data)
      var filteredMenus = [];
      filteredMenus = result.data.roleMenu.menus.filter((val) => {
        if (config.restrictedMenus.includes(val['menu_code'])) {
          return val;
        }
      })
      setMenu(filteredMenus)
      setRoles(result.data.roleMenu.roles)
    }
    else {
      if (result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4' || result.data.message === 'ERMWMSG2' || result.data.message === 'ERMWMSG5') {
        alert(errorData[result.data.message])
        signOut(userDispatch, props.history)
      }
      else if (result.data.status === false) {
        alert(result.data.message)
        props.history.goBack();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleRoleName = (event) => {
    let { name, value } = event.target
    setRoleDes({ ...roleDes, role_name: event.target.value })
  }

  const handleRole = (event) => {
    var rolesAdmin;
    if (event != undefined) {
      rolesAdmin = event.target.value
    } else {
      rolesAdmin = roles[0].id
    }
    setAdmin(rolesAdmin)
    var rolemenus = '';
    var menuValues = [];
    roles.map((key) => {
      if (key.id == rolesAdmin) {
        rolemenus = key.menu_id;
        setRoleDes(key)
      }
    })
    rolemenus = rolemenus.split(',');
    menu.map((val) => {
      if (rolemenus.includes(String(val.id))) {
        var information = {
          menuId: val.id,
          isChecked: true,
          label: val.menu_name
        }
        menuValues.push(information)
      }
      else {
        var information = {
          menuId: val.id,
          isChecked: false,
          label: val.menu_name
        }
        menuValues.push(information)
      }
    })
    setCheckbox([...menuValues])
  }
  useEffect(() => {
    if (roles.length)
      handleRole();
  }, [roles]);

  const handleCheckbox = name => event => {
    setErrorCheckBox(false)
    checkbox.map((val) => {
      if (val.menuId == event.target.value) {
        val.isChecked = !val.isChecked
      }
    })
    setCheckbox([...checkbox])
  };
  const handleCheckboxAdd = name => event => {
    checkAdd.map((val) => {
      if (val.menuId == event.target.value) {
        val.isChecked = !val.isChecked
      }
    })
    setCheckAdd([...checkAdd])
  };

  const handleSubmit = (e) => {
    setRolesEdit(false)
    var checkboxRequest = '';
    var j = 0;//don't change
    checkbox.map((val) => {
      if (val.isChecked == true) {
        if (j == 0 && val.isChecked == true) {
          checkboxRequest += val.menuId
          j++
        } else if (val.isChecked == true) {
          checkboxRequest += ',';
          checkboxRequest += val.menuId;
        }
      }
    })
    
    if(checkboxRequest.length  > 0 ){
      setErrorCheckBox(false)
    superagent.post(config.apiUrl + '/api/Flight/updateRole')
      .send({

        "id": admin,
        "roleName": roleDes.role_name,
        "menuId": checkboxRequest,
        "editBlock": 0

      })
      .set('login-key', localStorage.getItem('token'))
      .then((response) => {
        if (response.body.status == true) {
          setAlertUser(true)
          // setRolesEdit(false)
          setAlertAdd(errorData[response.body.message])
          fetchData();
        } else {
          alert(errorData[response.body.message])
        }
      })
    } else{
      setErrorCheckBox(true)
    }
  }
  const handleClickOpen = () => {
    setState(true);
    setErrorCheckBox(false)
    setRolesEdit(false)
    var checkstate = [];
    var rolemenus = roles[0].menu_id;
    rolemenus = rolemenus.split(',');

    menu.map((val) => {
      if (rolemenus.includes(String(val.id))) {

        var information = {
          menuId: val.id,
          isChecked: false,
          label: val.menu_name
        }
        checkstate.push(information)
      }
    })
    setCheckAdd([...checkstate])
  };
  const handleAddFn = (e) => {
    e.preventDefault();

    if (roleName.length == '') {
      setError(true)
    }
    
    else {

      setError(false)
      var checkboxRequest1 = '';
      var j = 0;//don't change
      checkAdd.map((val) => {
        if (val.isChecked == true) {
          if (j == 0 && val.isChecked == true) {
            checkboxRequest1 += val.menuId
            j++
          } else if (val.isChecked == true) {
            checkboxRequest1 += ',';
            checkboxRequest1 += val.menuId;
          }
        }
      })
      superagent.post(config.apiUrl + '/api/Flight/addRole')
        .send({
          "roleName": roleName,
          "menuId": checkboxRequest1,
        })
        .set('login-key', localStorage.getItem('token'))
        .then((response) => {
          // console.log(response)
          if (response.body.status == true) {

            setState(false)
            fetchData();
            setAlertMsg(true)
            setAlertDelete(errorData[response.body.message])
          } else {
            alert(errorData[response.body.message])
          }
        })
    }
  }
  const handleRoleValue = (e) => {
    setError(false)
    setRoleName(e.target.value)
  }
  const handleCancelFn = (e) => {
    setState(false)
    setRoleName('')
    setError(false)
  }
  const handleCloseInit = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertMsg(false);
  }
  const handleCloseAdd = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }

    setAlertUser(false);
  }
const  handleRolesEdit =(e) =>{
  setRolesEdit(!rolesEdit)
}
  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.Typography} variant="h3" component="h2" >
          Access Control
        </Typography>
      </div>
      <Fab className={classes.fab} onClick={handleClickOpen} color="primary" aria-label="add">
        <AddIcon onClick={handleClickOpen} />
      </Fab>
      <Card spacing={2} className={classes.card}>
        <Grid container spacing={1} className={classes.grid}>
          {state ? ('') : (<Grid item xs={12}>
           {errorCheckBox ? ( <Typography variant="h6" color="secondary"> Menus cannot be empty</Typography>):('')}
              


            <TextField
              id="standard-select"
              select
              label="Select Role"
              className={classes.textField}
              value={admin}
              name="role"
              onChange={handleRole}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              fullWidth
            >
              {
                roles.map(option => (
                  <MenuItem key={option.id} value={option.id} data={option}>
                    {option.role_name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>)}

          <Grid item xs={12}>
            {state ? (<TextField
              name="role Description"
              id="standard-select1"
              // autoComplete="off"
              value={roleName}
              label=" Enter Role Name"
              onChange={handleRoleValue}
              margin="normal"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              helperText={error ? (<Typography color="secondary"> Please enter Role Name  </Typography>) : ('')}
              type="name"
              fullWidth
            />) : (  
              
              <Button variant="outlined" color="primary" margin="normal" onClick={handleRolesEdit} /* className={classes.button} */ disabled={roleDes.edit_block != undefined && roleDes.edit_block == 1} >
              Edit Role Name
    </Button>
               
              )}
               {rolesEdit ? (<TextField
                  name="role Description"
                  id="standard-select1"
                  // autoComplete="off"
                  value={roleDes.role_name}

                  onChange={handleRoleName}
                  margin="normal"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  type="name"
                  fullWidth
                />):('')}
          </Grid>

          <Grid item xs={12}>
            {state ? (<Grid container xs={12}>
              {
                checkAdd.map((val) => {
                  return (
                    <Grid item xs={4}>
                      <FormControlLabel control={
                        <Checkbox
                          checked={val.isChecked}
                          onChange={handleCheckboxAdd(val.menuId)}
                          value={val.menuId}
                          inputProps={{
                            'aria-label': 'uncontrolled-checkbox',
                          }}
                        />}
                        label={val.label}
                      />
                    </Grid>
                  );
                })
              }
            </Grid>) : (<Grid container xs={12}>
              {
                checkbox.map((val) => {
                  return (
                    <Grid item xs={4}>
                      <FormControlLabel control={
                        <Checkbox
                          checked={val.isChecked}
                          disabled={roleDes.edit_block != undefined && roleDes.edit_block == 1 ? true : false}

                          onChange={handleCheckbox(val.menuId)}
                          value={val.menuId}
                          inputProps={{
                            'aria-label': 'uncontrolled-checkbox',
                          }}
                        />}
                        label={val.label}
                     
                      />
                    </Grid>
                  );
                })
              }
            </Grid>)}
          </Grid>
              
          <Grid item sm={12}>
            {state ? (<Button variant="contained" style={{ marginRight: '3px' }} color="primary" margin="normal" onClick={handleAddFn} className={classes.button} 
              disabled={
                checkAdd.filter((val)=>{
                  if(val.isChecked == true){
                    return true; 
                  } else{
                    return false
                  }
                }).length < 1
              }
              >
              Add
              
            
            
      </Button>) : (
                (<Button variant="contained" color="primary" margin="normal" onClick={handleSubmit} className={classes.button} 
                
                disabled={roleDes.edit_block != undefined && roleDes.edit_block == 1 
                
                
                } >
                  Save
        </Button>)
              )}
            {state ? (<Button variant="contained" color="primary" margin="normal" onClick={handleCancelFn} className={classes.button} >
              Cancel
      </Button>) : ('')}
          </Grid>
        </Grid>
        <Snackbar open={alertMsg} autoHideDuration={6000} onClose={handleCloseInit}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseInit} color="success">
    
        {alertDelete}
        </Alert>
      </Snackbar>
      <Snackbar open={alertUser} autoHideDuration={6000} onClose={handleCloseAdd}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseAdd} color="success">
    
      {alertAdd}
        </Alert>
      </Snackbar>
      </Card>
    </React.Fragment>
  )
}





