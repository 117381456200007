import React, { useState } from 'react';
import socketIOClient from "socket.io-client";
import config from '../config/config'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


export default function SocketIO(props) {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    var errorData = JSON.parse(localStorage.getItem('errorMsg'))
    var testData = JSON.parse(localStorage.getItem('data'));
    const [openMsg, setOpenMsg] = React.useState(false);
    const [socketMsg, setSocketMsg] = React.useState('')
    var socket = socketIOClient(config.apiUrl);
    socket.on('bulkSearchComplete', (data) => {
        console.log(data);
        if (testData != null && data.data != null && data.status == true && testData[0].id === data.data[0].id) {
            setOpenMsg(true)
            setSocketMsg(errorData[data.message])
        }
    })
    const handleCloseInit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMsg(false);
    }

    return (<div>
        <Snackbar open={openMsg} autoHideDuration={6000} onClose={handleCloseInit} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleCloseInit} color="success">

                {socketMsg}
            </Alert>
        </Snackbar>
    </div>);
}



