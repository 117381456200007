import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  FlightTakeoff as FlightTakeoffIcon,
  EventNote as EventNoteIcon,
  AlternateEmail as AlternateEmailIcon,
  Settings as SettingsIcon,
  Link as LinkIcon,
  People as PeopleIcon,
  Language as LanguageIcon,
  Flight as FlightIcon,
  Search as SearchIcon,
  Compare as CompareIcon,
  ZoomIn as ZoomInIcon,
  AddToPhotos as AddToPhotosIcon,
  PermDataSetting as PermDataSettingIcon,
  Lock as LockIcon,
  Widgets as WidgetsIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";


// styles
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";



//global



// var menu = JSON.parse(localStorage.getItem('menu')) /* || [] */;
// const menu = window.menu || [];

// var menuCodeList = [];


// if (menu/* .length */) {
//   menuCodeList = menu.menu.map(function (val) {
//     return val.menu_code;
//   });
// }




var isFirst = true;
var isMouseOver = false;
function Sidebar({ location }) {
  var userContextData = useUserState();


  const [state, setState] = React.useState({});
  if (isFirst) {
    setState({ ...userContextData });
    isFirst = false;
  }

  const structure = [
    // { id: 0, menuCode: '', label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
    {
      id: 0,
      menuCode: 'MC',
      label: "Flight Compare",
      link: "/app/marketCompare",
      icon: <CompareIcon />,
      // children: [
      //   {  id:1,  menuCode: 'MC',label: "Flights", link: "/app/marketCompare",icon: <FlightIcon />  },

      // ],
    },
    { id: 2, menuCode: 'UT', label: "Utilites", link: "/app/utilities", icon: <WidgetsIcon /> },
    {
      id: 3,
      menuCode: 'MP',
      label: "Flight Meta",
      link: "/app/metaPreview",
      icon: <FlightIcon />,
    },
    {
      id: 4,
      menuCode: 'LC', label: "LCC Flights", link: "/app/lcc", icon: <FlightTakeoffIcon />
    },
    { id: 5, menuCode: 'BS', label: "Bulk Search", link: "/app/bulkSearch", icon: <AddToPhotosIcon /> },

    {
      id: 6, menuCode: '',
      label: "Settings",
      link: "/app/UserForm",
      icon: <SettingsIcon />,

      children: [
        { id: 8, menuCode: 'ST', label: "Users", link: "/app/UserForm", icon: <PeopleIcon /> },
        { id: 9, menuCode: 'AC', label: "API ", link: "/app/client", icon: <LinkIcon /> },
        { id: 10, menuCode: 'MT', label: "Meta Config ", link: "/app/metaConfig", icon: <PermDataSettingIcon /> },
        { id: 11, menuCode: 'UC', label: "Access Control ", link: "/app/roleAccess", icon: <LockIcon /> },
      ],



    },
    {
      id: 7,
      menuCode: 'RP', label: "Reports", link: "/app/sesEmail", icon: <EventNoteIcon />,
      children: [
        { id: 11, menuCode: 'RP', label: "SES Email", link: "/app/sesEmail", icon: <AlternateEmailIcon /> },


      ],
    },

  ];


  var menuCodeList = [];
  menuCodeList = userContextData.roleMenu.menus.map(function (val) {
    return val.menu_code;
  });



  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });


  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onMouseEnter={() => {
        if (!isSidebarOpened) {
          toggleSidebar(layoutDispatch)
          isMouseOver = true;
        }
        else {
          isMouseOver = false;
        }
      }}
      onMouseLeave={() => {
        if (isMouseOver) {
          toggleSidebar(layoutDispatch)
        }
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>

      <List className={classes.sidebarList}>

        {structure.map(link => {
          if (link.children != undefined) {

            link.children = link.children.filter(val => {
              if ((menuCodeList !== undefined && val.menuCode !== '' && menuCodeList.indexOf(val.menuCode) !== -1)) {
                return val;
              }
            })

            if (link.children.length) {
              link.link = link.children[0].link;
            }
            else {
              return;
            }
          }

          if ((menuCodeList !== undefined && link.menuCode !== '' && menuCodeList.indexOf(link.menuCode) !== -1) || link.menuCode === '') {

            return (
              <SidebarLink

                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            )
          }
        })}
      </List>
    </Drawer>
  );
  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}


export default withRouter(Sidebar);

