import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from '@material-ui/core/Container';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import superagent from "superagent";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import config from '../../config/config';
import { CircularProgress, Typography } from '@material-ui/core';
// import airportList from '../../config/airports'
import { flexbox, positions, maxWidth } from '@material-ui/system';
import { useUserState } from "../../context/UserContext";

import Link from '@material-ui/core/Link';
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useTheme } from "@material-ui/styles";
import uuid1 from 'uuid/v1';
import AppBar from '@material-ui/core/AppBar';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';






const currencies = [
    {
        value: 'Economy',
        label: 'Economy',
    },
    {
        value: 'Business',
        label: 'Business',
    },
    {
        value: 'First',
        label: 'First',
    },

];

const Adult = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];

const Child = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};







const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 40,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // paddingRight:'40px'
        padding: theme.spacing(2),

    },
    container: {
        display: 'flex',
        // flexGrow: 1,

        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),

        width: '100%',



    },

    buttonSites: {
        marginLeft: "80%"
    },


    textField1: {
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(10),

        // width: 130
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(3),
    },
    input: {
        display: 'none',
    },
    displayBlock:
    {
        display: 'block',
    },
    displayNone:
    {
        display: 'none',
    },
    fab: {
        margin: theme.spacing(0),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    null:
    {
        margin: null
    },
    fareButton: {
        // marginBottom: 10
        inlineSize: "-webkit-fill-available",


    },
    ReturnHead: {
        textAlign: 'center',
    },
    loginLoader: {
        margin: theme.spacing(3),
    },
    fadeError: {
        margin: theme.spacing(8),
        textAlign: "center",
        color: "red",
    },
    BotContainer: {
        display: flexbox
    },
    paper: {
        whiteSpace: "nowrap",
        textAlign: "center",
        // marginBottom:10

    },
    HeadingFlights: {
        margin: theme.spacing(2),
    },
    LinkButton: {
        marginLeft: theme.spacing(3),
    },
    wordFare: {
        // float: 'right',
        // paddingRight: 15,
        // position:"sticky"


    },
    colour: {
        color: 'blur',
    },
    selected: {
        inlineSize: "-webkit-fill-available",
        color: 'blue',
    },
    header: {
        margin: theme.spacing(0, 4),
        marginBottom: "2%",

    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#eae8e8'
    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 150,
        width: '90%'
        // maxWidth: 250,
    },


}));
const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);






export default React.memo(function Lcc(props) {
    var userDispatch = useUserDispatch();
    var userContextData = useUserState();
    var theme = useTheme();
    var errorData = JSON.parse(localStorage.getItem('errorMsg'))



    const [value, setValue] = React.useState('return');

    const [journeyType, setJourneyType] = React.useState('return');

    const [showing1, setShowing1] = React.useState(true);
    const classes = useStyles();
    var currentDate = new Date();
    // const [selectedDate, setSelectedDate] = React.useState(currentDate.getFullYear() + '/' + currentDate.getMonth() + '/' + currentDate.getDate());
    // const [selectedDate, handleDateChange] = 
    var currentDate1 = new Date();
    var startDate = new Date();
    var days;
    days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    // const [selectedDate1, setSelectedDate1] = React.useState(currentDate1.getFullYear() + '/' + currentDate1.getMonth() + '/' + currentDate1.getDate());
    // const [selectedDate, handleDateChange] = React.useState(currentDate.getFullYear() + ' ' + (currentDate.getMonth() + 1) + ' ' + (days[currentDate.getUTCDate()])  );
    const [selectedDate, handleDateChange] = React.useState(currentDate);
    // const [selectedDate1, setSelectedDate1] = React.useState((currentDate.getMonth()+1)+'/'+currentDate.getDate()+'/'+currentDate.getFullYear());
    const [selectedDate2, setSelectedDate2] = React.useState(new Date());
    const [selectedDate1, handleDateChange1] = React.useState(currentDate);
    const [cabin, setCabin] = React.useState('Economy');
    const [market, setMarket] = React.useState('');
    const [adult, setAdult] = React.useState('1');
    const [child, setChild] = React.useState('0');
    const [infant, setInfant] = React.useState('0');
    const [addbutton, setAddbutton] = React.useState(false);
    const [addbutton1, setAddbutton1] = React.useState(false);
    const [showmulticity, setShowmulticity] = React.useState(false);
    const [add, setAdd] = React.useState([0]);
    const [empty, setEmpty] = React.useState(false);
    const [fields, setFields] = React.useState([{ value: null }]);
    const [fields1, setFields1] = React.useState([{ value: null }]);
    const [From, setFrom] = React.useState('');
    const [FromLabel, setFromLabel] = React.useState('');
    const [To, setTo] = React.useState('');
    const [ToLabel, setToLabel] = React.useState('');
    const [Departure, setDeparture] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [mainArray, setMainArray] = React.useState([]);
    const [filterMainArray, setFilterMainArray] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [retArray, setRetArray] = React.useState([]);
    const [loader, setIsLoader] = React.useState(false);
    const [fade, setFade] = React.useState(false);
    const [fade1, setFade1] = React.useState(false);
    const [requestData, setRequestData] = React.useState('');
    const [responseData, setResponseData] = React.useState('');
    const [fareCurrency, setFareCurrency] = React.useState('');
    const [airlineSource, setAirlineSource] = React.useState([]);
    const [checkbox, setCheckbox] = React.useState({
        'SG': true,
        'U2': true,
        '6E': true,
        'FR': true,

    });
    const [carriers, setCarriers] = React.useState([]);

    const [selectall, setSelectall] = React.useState({
        selectAll: true,
    });

    const [checkboxState, setCheckboxState] = React.useState([]);
    const [validateMsg, setValidateMsg] = React.useState(false)
    const [toValidate, setToValidate] = React.useState(false)

    const [responsebutton, setResponsebutton] = React.useState(false);
    const [errMessage, setErrMessage] = React.useState('');
    const [errMessageFare, setErrMessageFare] = React.useState('');

    const [color, setColor] = React.useState(true)
    // const [ clicked , setClicked] =  React.useState(false)
    const [marketState, setMarketState] = React.useState([]);
    const [sitesIn, setSitesIn] = React.useState('')
    const [selectedbutton, setSelectedbutton] = React.useState(null);
    const [selectedreturn, setSelectedreturn] = React.useState(null);
    const [showReturn, setShowreturn] = React.useState(true);
    const [showOneway, setShowoneway] = React.useState(false);
    const [airportList, setAirportList] = React.useState([])
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [infantVal, setInfantVal] = React.useState(false)

    var [isPermanent, setPermanent] = React.useState(true);
    var [InfantValue, setInfantValue] = React.useState([{
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    }]);
    const [footer, setFooter] = React.useState(false)
    const [itinery, setItinery] = React.useState({})
    const [fares, setFares] = React.useState('')
    // const [returnbutton, setReturnbutton] = React.useState({
    //     city: '',
    //     string: '',
    //     fare: '',
    // })
    // const [onewaybutton, setOnewaybutton] = React.useState({
    //     city: '',
    //     string: '',
    //     fare: '',
    // })
    const [bookingButton, setBookingButton] = React.useState([
        {
            city: '',
            string: '',
            fare: '',
            fareInd: '',
            ItineraryID: '',
            Date: ''
        }
    ])
    const [faretype, setFaretype] = React.useState([]);
    const [responseId, setResponseId] = React.useState('');
    const [bookingloader, setBookingloader] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [pages, setPages] = React.useState({})
    const [flightFared, setFlightFared] = React.useState([])
    const [easyJet, setEasyJet] = React.useState('')
    const [display, setDisplay] = React.useState(true);
    const [alertMsg, setAlertMsg] = React.useState(false);
    const [alertTextMessage, setAlertTextMessage] = React.useState('');
    const [filter, setFilter] = React.useState({
        airlines: [],
        stops: [],
        stopOvers: [],
        sites: []
    });

    useEffect(() => {
        superagent.post(config.apiUrl + "/api/Flight/LCCMarketCarrier")

            .set('Content-Type', 'application/json')
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .then(response => {
                if (response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4' || response.body.message === 'ERMWMSG2' || response.body.message === 'ERMWMSG5') {
                    alert(errorData[response.body.message])


                    signOut(userDispatch, props.history)
                }
                else {

                    setMarketState([...response.body[0]])
                    setCheckboxState(response.body[1])

                }
            })

    }, []);


    useEffect(() => {

        if (marketState.length)
            handleMarket();

    }, [marketState]);


    useEffect(() => {

        if (checkboxState.length)
            handleMarket();

    }, [checkboxState]);




    useEffect(() => {

        var allChecked = true;
        carriers.map((val) => {


            if (!val.isChecked) {
                allChecked = false;
            }

        })

        if (allChecked) {
            setSelectall({ selectAll: true });
        }
        else {
            setSelectall({ selectAll: false });
        }
    }, [carriers]);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    useEffect(function () {
        var footbarFlag = false;
        bookingButton.map((val) => {

            if (val.ItineraryID != '') {
                footbarFlag = true;
            }
        });

        setFooter(footbarFlag);
    }, [bookingButton]);

    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleCloseInit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertMsg(false);
    }





    const handleCheckboxSelectall = name => event => {
        setSelectall({ ...selectall, [name]: event.target.checked });
        if (event.target.checked) {
            // setCheckbox({
            //     'SG': true, 'U2': true, '6E': true, 'FR': true,

            // })
            carriers.map((val) => {
                val.isChecked = true;
            })
            setCarriers(carriers);
        }
        else {
            carriers.map((val) => {
                val.isChecked = false;
            })
            setCarriers(carriers);
            // setCheckbox({
            //     'SG': false, 'U2': false, '6E': false, 'FR': false,
            // })
        }


    };

    const handleBooking = (event) => {
        let id1 = event.target.id;

    }

    // const handleCheckboxSelectall1 = ()=> {


    // };



    const handleCheckbox = name => event => {
        var tempCarriers = carriers;
        tempCarriers.map((val) => {
            if (val.carrierCode == event.target.value) {
                val.isChecked = !val.isChecked;
            }
        })
        setCarriers([...tempCarriers]);



        // var tempCheckBox = { ...checkbox };
        // tempCheckBox[name] = event.target.checked;
        // setCheckbox(tempCheckBox);

        // setCheckbox({ ...checkbox, [name]: event.target.checked } );
        // if(checkbox.SG&&checkbox.EJ&&checkbox.IG&&checkbox.RA== event.target.checked){
        //     setSelectall({selectAll:true});
        // }
    };


    const bookingData = selectedbutton => event => {

        setSelectedbutton(selectedbutton)


    }
    useEffect(() => {
        // if (Object.keys(itinery).length > 0)
        //     bookingDataReturn();
    }, [footer])

    useEffect(() => {
        handleFilter();
    }, [mainArray]);

    useEffect(() => {
        var filteredAirline = [];
        var filteredStop = [];
        var filteredStopOver = [];
        var filteredSite = [];

        filteredAirline = filter.airlines.filter((val) => val.checked).map((val) => val.value);
        filteredStop = filter.stops.filter((val) => val.checked).map((val) => val.value);
        filteredStopOver = filter.stopOvers.filter((val) => val.checked).map((val) => val.value);
        filteredSite = filter.sites.filter((val) => val.checked).map((val) => val.value);

        var filterItinerary = [];
        for (let i = 0; i < mainArray.length; i++) {
            var filterFlight = [];
            for (let j = 0; j < mainArray[i].length; j++) {
                var airlineExist = false;
                var stopOverExist = false;
                var stopCountExist = false;
                var siteExist = false;

                for (let k = 0; k < mainArray[i][j].Segment.length; k++) {
                    // Airline filter
                    if (filteredAirline.includes(mainArray[i][j].Segment[k].MarketingCarrier.AirlineID)) {
                        airlineExist = true;
                    }
                    // Stop over filter
                    if (k > 0) {
                        if (filteredStopOver.includes(mainArray[i][j].Segment[k].Departure.AirportCode)) {
                            stopOverExist = true;
                        }
                    }
                    if (airlineExist && stopOverExist) {
                        break;
                    }
                }
                if (mainArray[i][j].Segment.length == 1) {
                    stopOverExist = true;
                }
                // stop count filter
                if (filteredStop.includes(mainArray[i][j].Segment.length - 1)) {
                    stopCountExist = true;
                }
                // Source site filter
                if (filteredSite.includes(mainArray[i][j].Source)) {
                    siteExist = true;
                }
                if (airlineExist && stopOverExist && stopCountExist && siteExist) {
                    filterFlight.push(mainArray[i][j]);
                }
            }
            filterItinerary.push(filterFlight);
        }
        setFilterMainArray(filterItinerary);

    }, [filter])


    const bookingDataReturn = (button, fareInd, itn, booking) => event => {
        // setFooter(true);
        mainArray.map((key, itn) => {
            key.map((iternary, ine) => {
                appfn[iternary.ItineraryID] = iternary
            })
        })
        setItinery({ ...appfn[itn] });
        setFares(booking);
        var itinId = '';
        var itinery = appfn[itn];
        if (flightsState[1]) {
            flightsState[1].map((loop, ind) => {
                itinId += loop.ItineraryID

            })


            if (itinId.includes(itn) && Object.keys(itinery).length > 0) {

                let values = [];
                let faretypes = [];
                if (selectedValues[1] === button) {
                    let values = [selectedValues[0], null];
                    setSelectedValues(values);

                    setBookingButton([bookingButton[0], {
                        city: '',
                        string: '',
                        fare: '',
                        fareInd: '',
                        ItineraryID: '',
                        Date: ''
                    }]);
                }
                else if (selectedValues[1] !== button) {
                    values = [selectedValues[0], button];
                    faretypes = [faretype[0], fareInd]
                    if (bookingButton[0].ItineraryID.split('^')[3] == 'U2') {
                        flightFared.map((val, key) => {

                            var test1 = JSON.stringify(val.ItineraryID)
                            var test2 = JSON.stringify([bookingButton[0].ItineraryID.split('^', 2).join('^'), itn])

                            if (test1 == test2) {


                                setEasyJet(val.FlightPrice[0].AverageFare)
                            }
                        })
                    }

                }
                // setReturnbutton({

                //     city: itinery.Segment[0].Departure.AirportCode + '-' + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode + '@',
                //     string: itinery.Segment[0].MarketingCarrier.AirlineID + itinery.Segment[0].MarketingCarrier.FlightNumber +
                //         '-' + itinery.Segment[0].Departure.AirportCode + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                //     fare: itinery.FareDetail["0"].BrandedFares[fareInd]
                // })

                // setBookingButton([...bookingButton].map(object => {
                //     return {
                //         ...object,
                //         city: itinery.Segment[0].Departure.AirportCode + '-' + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode + '@',
                //         string: itinery.Segment[0].MarketingCarrier.AirlineID + itinery.Segment[0].MarketingCarrier.FlightNumber +
                //             '-' + itinery.Segment[0].Departure.AirportCode + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                //         fare: itinery.FareDetail["0"].BrandedFares[fareInd]
                //     }
                // }));

                var bookingArray = [...bookingButton];


                bookingArray[1] = {
                    city: itinery.Segment[0].Departure.AirportCode + '-' + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                    string: itinery.Segment[0].MarketingCarrier.AirlineID + itinery.Segment[0].MarketingCarrier.FlightNumber +
                        '-' + itinery.Segment[0].Departure.AirportCode + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                    fare: itinery.FareDetail["0"].BrandedFares[fareInd],
                    fareInd: fareInd,
                    ItineraryID: button,
                    Date: itinery.Segment[0].Departure.DateTime.split("|")[0]

                };
                // bookingArray[0].fareInd==bookingArray[1].fareInd?
                // (
                // setBookingButton([...bookingArray])
                // ):(alert('select same category'))

                if (bookingArray.length == 1 || (bookingArray[0] != undefined && bookingArray[0].fareInd == bookingArray[1].fareInd && bookingArray[0].ItineraryID != '')) {

                    // setAlertMsg(true);
                    // setAlertTextMessage('Choose onward ft!');
                    setBookingButton([...bookingArray])
                    setSelectedValues(values);
                    setFaretype(faretypes);
                }
                else {

                    // alert('select same category')
                    setAlertMsg(true);
                    setAlertTextMessage('Select same category for return flight!');
                }

                if (selectedValues[1] === button) {
                    setBookingButton([bookingButton[0], {
                        city: '',
                        string: '',
                        fare: '',
                        fareInd: '',
                        ItineraryID: '',
                        Date: ''
                    }]);
                }
                if (bookingArray[0].ItineraryID == '') {
                    setAlertMsg(true);
                    setAlertTextMessage('Choose onward flight!');
                }





            }
        }
        itinId = '';

        flightsState[0].map((loop, ind) => {
            itinId += loop.ItineraryID
        })
        if (itinId.includes(itn) && Object.keys(itinery).length > 0) {
            let values = [];
            let faretypes = [];

            if (selectedValues[0] === button) {
                values = [null, selectedValues[1]];
                setSelectedValues(values);
                setBookingButton([{
                    city: '',
                    string: '',
                    fare: '',
                    fareInd: '',
                    ItineraryID: '',
                    Date: ''
                }, bookingButton[1]]);
            }
            else if (selectedValues[0] !== button) {
                values = [button, selectedValues[1]];
                faretypes = [fareInd, faretype[1]]

            }
            var bookingArray = [...bookingButton];


            bookingArray[0] = {
                city: itinery.Segment[0].Departure.AirportCode + '-' + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                string: itinery.Segment[0].MarketingCarrier.AirlineID + itinery.Segment[0].MarketingCarrier.FlightNumber +
                    '-' + itinery.Segment[0].Departure.AirportCode + itinery.Segment[itinery.Segment.length - 1].Arrival.AirportCode,
                fare: itinery.Source == 'U2' ? (itinery.FareCalculation == 'Itinerary' ? itinery.FareDetail["0"].AverageFare : '') : itinery.FareDetail["0"].BrandedFares[fareInd],
                fareInd: fareInd,
                ItineraryID: button,
                Date: itinery.Segment[0].Departure.DateTime.split("|")[0]
            }

            // setBookingButton([...bookingArray]);

            if (bookingArray.length == 1 || (bookingArray[1] != undefined && bookingArray[1].fareInd == bookingArray[0].fareInd)) {

                setBookingButton([...bookingArray])
                setSelectedValues(values);
                setFaretype(faretypes)
            }
            else {

                // alert('select same category')
            }

            if (bookingButton[0].ItineraryID === button) {
                setBookingButton([{
                    city: '',
                    string: '',
                    fare: '',
                    fareInd: '',
                    ItineraryID: '',
                    Date: ''
                }]);
            }

            if (bookingArray[0].ItineraryID != '' && itinery.FareCalculation == 'Flight') {
                setAlertMsg(true);
                setAlertTextMessage('Select iternary to view Fares!');
            }


        }




    }







    const handleClickOpen = () => {
        // handleMarket();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeSelect = event => {
        setCabin(event.target.value);
    };





    const handleMarket = (event) => {

        var selectedCountry;
        if (event != undefined) {
            selectedCountry = event.target.value;
        }
        else {
            selectedCountry = marketState[0].countryCode;
        }

        //  sitesIn ='';
        // setCarriers
        setMarket(selectedCountry);
        var selectedCarriers = [];
        marketState.map((key) => {

            if (key.countryCode == selectedCountry) {
                selectedCarriers = key.preferred;
            }
        })
        var carrierValue = [];
        selectedCarriers.map((val) => {
            checkboxState.map((sites) => {
                if (sites.name == val) {
                    var carrier = {
                        carrierCode: val,
                        isChecked: true,
                        label: sites.siteName
                    }
                    carrierValue.push(carrier);
                }
            })
        })
        setCarriers(carrierValue);

        // e.target.value = e.target.value.toUpperCase();

        // if(marketState.countryCode)        




    };
    const handleChangeAdult = event => {
        setAdult(event.target.value);
        var maxInfant = [];
        var maxInfantCount = event.target.value;
        var i = 0;
        while (i <= maxInfantCount) {
            maxInfant.push({
                value: i,
                label: i
            });
            i++;
        }
        setInfantValue(maxInfant);
    };
    const handleChangeChild = event => {
        setChild(event.target.value);
    };
    const handleChangeInfant = event => {

        setInfant(event.target.value);

    };
    // const handleDateChange = (event, date) => {

    //   setSelectedDate(date);

    // };

    // const handleDateChange1 = (event, date) => {
    //   setSelectedDate1(date);
    // };
    const handleDateChange2 = date => {
        setSelectedDate2(date);
    };
    const handleChangeRadio = event => {
        setValue(event.target.value);
    };

    const responseButton = () => {
        setResponsebutton(!responsebutton);
    }


    const responseButtonClose = () => {
        setResponsebutton(false);
    }



    const handleMulticity = () => {

        setShowmulticity(true);
    }


    const handleDeparture = e => {
        setDeparture(e.target.value);
    };

    const handleFrom = e => {

        e.target.value = e.target.value.toUpperCase();
        setFrom(e.target.value);






    };
    const handleTo = e => {
        e.target.value = e.target.value.toUpperCase();
        setTo(e.target.value);

    };


    const Showing1 = () => {
        setShowing1(true);
        setAddbutton(false);
        setAddbutton1(false);
        setJourneyType('return');
        setShowmulticity(false);
        setEmpty(false);


        setShowoneway(false)
    }


    const Showing2 = () => {
        setShowing1(false);
        setAddbutton(false);
        setAddbutton1(false);
        setShowmulticity(false);
        setValue('oneway');
        setEmpty(true);
        setShowoneway(true);
        setShowreturn(false);


    }

    const Showing3 = () => {

        setShowing1(false);
        setAddbutton(true);
        setAddbutton1(true);
        setValue('multicity');


    }


    const disablePrevDates = (startDate) => {
        const startSeconds = Date.parse(startDate);
        return (date) => {
            return Date.parse(date) < startSeconds;
        }
    }

    const disablePrevDates1 = (selectedDate) => {
        const startSeconds = Date.parse(selectedDate);
        return (date) => {
            return Date.parse(date) <= startSeconds;
        }
    }


    const handleFromValidation = () => {
        setAirportList([]);
        if (From == '') {
            setValidateMsg(true)
        }
    }
    const handleToValidation = () => {
        setAirportList([]);
        if (To == '') {
            setToValidate(true)
        }
    }




    const submit = e => {
        e.preventDefault();

        var isValidation = true;


        if (From.length != 3) {
            alert(' From and TO should be three characters')
            isValidation = false;
        }

        if (To.length != 3) {
            alert(' From and TO should be three characters')
            isValidation = false;
        }
        if (parseInt(adult) + parseInt(child) + parseInt(infant) > 9) {
            alert(' the passengers should not more than 9')
            isValidation = false;
        }

        if (showing1 && (new Date(selectedDate) > new Date(selectedDate1))) {
            alert('Return Date should be greater than departure date')
            isValidation = false;
        }


        if (isValidation) {
            setErrMessage('')
            setIsLoader(true)

            setSelectedValues([])
            setStatus(false)
            setFooter(false)
            var carrier = '';
            var j = 0;//don't change
            // for (var i in checkbox) {

            //     if (j == 0 && checkbox[i]) {
            //         carrier += i;
            //         j++;
            //     } else if (checkbox[i]) {
            //         carrier += ',';
            //         carrier += i;
            //     }
            //     // if ((j < Object.keys(checkbox).length-1)&&checkbox[i]==true) {

            // }

            carriers.map((val) => {

                if (val.isChecked == true) {
                    if (j == 0 && val.isChecked == true) {

                        carrier += val.carrierCode
                        j++

                    } else if (val.isChecked == true) {
                        carrier += ',';
                        carrier += val.carrierCode;
                    }
                }



            })




            // function dateConverter(date) {
            //     return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            // }
            var request = {
                "AirShoppingRQ": {
                    "MessageID": uuid1(),
                    "MetaData": {
                        "cabin": "INR",
                        "RequestType": "LCCPublic",
                        "ClientKey": "",
                        "TimeStamp": "",
                        "Carriers": carrier
                    },
                    "OriginDestinations": [
                        {
                            "Departure": {
                                "AirportCode": From,
                                "Date": selectedDate.toISOString().slice(0, 10)
                            },
                            "Arrival": {
                                "AirportCode": To
                            }
                        }
                    ],
                    "Preferences": {
                        "TripType": "Oneway",
                        "Cabin": cabin
                    },
                    "Travelers": {
                        "ADT": adult,
                        "CHD": child,
                        "INF": infant
                    }
                }

            };

            // for return 
            if (showing1) {
                request.AirShoppingRQ.OriginDestinations.push({
                    "Departure": {
                        "AirportCode": To,
                        "Date": selectedDate1.toISOString().slice(0, 10)
                    },
                    "Arrival": {
                        "AirportCode": From
                    }
                });
            }





            superagent.post(config.apiUrl + "/api/Flight/LCCPublic")
                .send(request)
                .set('Content-Type', 'application/json')
                .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                .set({ 'login-key': localStorage.getItem('token') })
                .timeout({
                    deadline: 500000
                })
                .then(response => {

                    setBookingButton([
                        {
                            city: '',
                            string: '',
                            fare: '',
                            fareInd: '',
                            ItineraryID: ''
                        }
                    ]);



                    response = JSON.parse(response.text);

                    if (response.AirShoppingRS != undefined) {
                        setResponseId(response.AirShoppingRS.ResponseID)
                    }

                    if (response.AirShoppingRS != undefined && Object.keys(response).length != 0) {

                        setFareCurrency(response.AirShoppingRS.Sources[0].FareCurrency)
                        setAirlineSource(response.AirShoppingRS.Sources);



                        setRequestData(JSON.stringify(request))
                        setResponseData(JSON.stringify(response))
                        // var flightFare = response.AirShoppingRS;
                        var flightFared;
                        flightFared = response.AirShoppingRS.FareList;
                        setFlightFared(response.AirShoppingRS.FareList)

                        var flight = response.AirShoppingRS.Flights;
                        var flightList = [];

                        flight.map(function (trip) {
                            var tripData = [];
                            trip.map(function (segments) {
                                // val.map((segments) => {
                                var itinId = segments.ItineraryID;

                                var flighFareDetail = flightFared.find(f => f.ItineraryID[0] == itinId || f.ItineraryID[1] == itinId);
                                if (segments.FareDetail == undefined) {
                                    segments.FareDetail = flighFareDetail.FlightPrice
                                }
                                tripData.push(segments)
                                // })
                            });
                            flightList.push(tripData);
                        });


                        var forwardSegment = response.AirShoppingRS.Flights[0];

                        var returnSegment = response.AirShoppingRS.Flights[1];

                        var segmentData = [];
                        var priceData = [];
                        var priceData1 = "";


                        setMainArray(flightList)
                        // setRetArray({
                        //     retArray: segmentDataReturn,

                        //     // isLoading: true,

                        // })
                        setIsLoader(false)
                        setFade(false)

                        setIsLoading(
                            true
                        )

                    } else if (Object.keys(response).length == 0) {

                        setIsLoader(false)
                        setFade(true);
                        setErrMessage('No Flights Found!');



                    }
                    else if (response.message === 'ERMWMSG3' || response.message === 'ERMWMSG4' || response.message === 'ERMWMSG2' || response.message === 'ERMWMSG5') {
                        alert(errorData[response.message])
                        signOut(userDispatch, props.history)
                    }

                }, err => {

                    // if (err.timeout) {
                    setIsLoader(false);
                    setIsLoading(false);
                    setFade(true);
                    setErrMessage('Request timeout!');
                    // }



                })
        }

        setIsLoading(false);
        //    setIsLoader(false)

    }

    const handleFilter = () => {
        var airlineArraySegment = [];
        var airlineFilter = [];
        var stopOverAirport = [];
        var stopOverFilter = [];
        var sourceSite = [];
        var sourceSiteFilter = [];
        var maxCount = 0;

        for (let i = 0; i < mainArray.length; i++) {
            for (let j = 0; j < mainArray[i].length; j++) {
                for (let k = 0; k < mainArray[i][j].Segment.length; k++) {
                    // Airline filter
                    if (!airlineArraySegment.includes(mainArray[i][j].Segment[k].MarketingCarrier.AirlineID)) {
                        airlineArraySegment.push(mainArray[i][j].Segment[k].MarketingCarrier.AirlineID);
                        var airlineValues = {
                            label: '',
                            value: mainArray[i][j].Segment[k].MarketingCarrier.AirlineID,
                            checked: true
                        }
                        airlineFilter.push(airlineValues);
                    }
                    // Stop over filter
                    if (k > 0) {
                        if (!stopOverAirport.includes(mainArray[i][j].Segment[k].Departure.AirportCode)) {
                            stopOverAirport.push(mainArray[i][j].Segment[k].Departure.AirportCode)
                            var stopValues = {
                                label: '',
                                value: mainArray[i][j].Segment[k].Departure.AirportCode,
                                checked: true
                            }
                            stopOverFilter.push(stopValues);
                        }
                    }
                }
                // stop count filter
                if (maxCount < mainArray[i][j].Segment.length) {
                    maxCount = mainArray[i][j].Segment.length;
                }
                // Source site filter
                if (!sourceSite.includes(mainArray[i][j].Source)) {
                    sourceSite.push(mainArray[i][j].Source);
                    var siteValues = {

                        label: airlineSource.filter((val) => val.SourceID == mainArray[i][j].Source)[0]['SourceName'] + '(' + airlineSource.filter((val) => val.SourceID == mainArray[i][j].Source)[0]['SourceID'] + ')',

                        value: mainArray[i][j].Source,
                        checked: true
                    };
                    sourceSiteFilter.push(siteValues);
                }
            }
        }

        var stopValues = [];
        for (var i = 0; i < maxCount; i++) {
            var stopNumber = {
                label: '',
                value: '',
                checked: true
            };
            stopNumber.value = i;
            if (i == 0) {
                stopNumber.label = 'Non-Stop';
            }
            else if (i == 1) {
                stopNumber.label = '1 Stop';
            }
            else {
                stopNumber.label = i + ' Stops';
            }
            stopValues.push(stopNumber);
        }


        airlineFilter.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        stopOverFilter.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        stopValues.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });
        sourceSiteFilter.unshift({
            label: 'Select All',
            value: 'selectall',
            checked: true
        });

        setFilter({ ...filter, airlines: airlineFilter, stopOvers: stopOverFilter, stops: stopValues, sites: sourceSiteFilter });

    }

    const handleChangeFilter = event => {

        var filterName = event.target.name;
        var tempFilter = filter[filterName];

        if (event.target.value[event.target.value.length - 1] == 0) {
            tempFilter[0].checked ? (tempFilter.map((val) => val.checked = false)) : (tempFilter.map((val) => val.checked = true));
        }
        else {
            tempFilter[event.target.value[event.target.value.length - 1]].checked = !tempFilter[event.target.value[event.target.value.length - 1]].checked;
        }

        var isAllSelect = true;
        for (let i = 1; i < tempFilter.length; i++) {
            if (!tempFilter[i].checked) {
                isAllSelect = false;
                break;
            }
        }
        tempFilter[0].checked = isAllSelect;
        setFilter({ ...filter, [filterName]: tempFilter });


    }


    var bookItinerary = [];
    bookingButton.map((val) => {
        if (bookingButton[0].ItineraryID.split('^')[3] != 'U2') {
            bookItinerary.push({
                "SelectedItineraryIndex": null,
                "ItineraryString": val.ItineraryID.split("^")[0],
                "FareType": val.ItineraryID.split("^")[2],

                "Fare": val.fare

            });
        } else {
            bookItinerary.push({
                "SelectedItineraryIndex": null,
                "ItineraryString": val.ItineraryID.split("^")[0],
                "FareType": '',
            });



        }
    });

    var OriginDestinations = [];
    bookingButton.map((od) => {
        OriginDestinations.push({
            "Departure": {
                "AirportCode": od.city.split("-")[0],
                "Date": od.Date
            },
            "Arrival": {
                "AirportCode": od.city.split("-")[1]
            }
        })
    })


    const bookingContinue = () => {
        setBookingloader(true);
        setStatus(false)
        setErrMessageFare('')
        var bookingRequest = {
            "AirShoppingRQ": {
                "MessageID": responseId,
                "MetaData": {
                    "Currency": airlineSource.find((val) => {
                        return val.SourceID == bookingButton[0].ItineraryID.split('^')[3];
                    })['FareCurrency'],
                    "RequestType": "LCCPublic",
                    "ClientKey": "",
                    "TimeStamp": "",
                    "Carriers": bookingButton[0].ItineraryID.split('^')[3]
                },
                "OriginDestinations": OriginDestinations,

                "Preferences": {
                    "TripType": "",
                    "Cabin": ""
                },
                "Travelers": {
                    "ADT": 1,
                    "CHD": 0,
                    "INF": 0
                }
            },
            "BookingDetails": {
                "BookItinerary": bookItinerary,
                "BookingType": "b2c",
                "Contact": {
                    "PassengerDetails": {
                        "NameTitle": "MR",
                        "FirstName": "Arunkumar",
                        "LastName": "M",
                        "Phone": {
                            "AreaCode": "",
                            "CountryCode": "IN",
                            "Number": "666123123"
                        },
                        "EmailContact": "arun@gmail.com",
                        "CountryCode": "IN",
                        "Address": {
                            "Street": "street",
                            "City": "chennai",
                            "PostalCode": "600000",
                            "CountryCode": "IN"
                        }
                    }
                },
                "Passenger": {
                    "Adult": [
                        {
                            "Document": {
                                "BirthCountry": "",
                                "DocumentID": "",
                                "DocumentType": "",
                                "ExpirationDate": "",
                                "IssueDate": "",
                                "ResidenceCountry": ""
                            },
                            "Passenger": {
                                "Birthdate": "1996-11-22",
                                "EmailContact": "abc@gmail.com",
                                "Gender": "Male",
                                "FirstName": "Arun",
                                "LastName": "kumar",
                                "NameTitle": "MR",
                                "PassengerType": "ADT",
                                "Phone": {
                                    "AreaCode": "",
                                    "CountryCode": "IN",
                                    "Number": "666123123"
                                },
                                "Address": {
                                    "Street": "",
                                    "City": "",
                                    "PostalCode": "",
                                    "CountryCode": "IN"
                                }
                            }
                        }
                    ],
                    "Child": [

                    ],
                    "Infant": [

                    ]
                }
            }
        };
        if (bookingButton[0].ItineraryID.split('^')[3] == 'U2') {
            bookingRequest.BookingDetails.Fare = easyJet;
        }

        superagent.post(config.apiUrl + "/api/Flight/LCCPublic")
            .send(bookingRequest)
            .set('Content-Type', 'application/json')
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .timeout({
                deadline: 70000
            })
            .then(response => {
                response = JSON.parse(response.text);

                if (response.pages != undefined && response.status == "success") {
                    setPages(response)
                    setStatus(true)
                    setFade1(false)
                    setBookingloader(false)
                }


                if (response.status == false) {

                    setBookingloader(false)
                    setFade1(true)
                    setErrMessageFare(errorData[response.message])



                }
            }, err => {
                setErrMessageFare('Request timeout!')
                setFade1(true)
                setBookingloader(false)
            })


    }



    function handleChange(i, event) {
        const values = [...fields];
        values[i].value = event.target.value;
        setFields(values);


    }
    function handleChange1(i, event) {
        const values1 = [...fields1];
        values1[i].value = event.target.value;
        setFields1(values1);


    }
    function handleAdd() {

        const values = [...fields];

        if (values.length <= 3) {
            values.push({ value: null });

            setFields(values);
        } else {
            return null;
        }
        setAddbutton(true);
    }

    function handleRemove(i) {
        const values = [...fields];

        if (values.length <= 1) {
            { Showing2() }

        }
        else {

            values.splice(i, 1);
            setFields(values);

        }

    }




    //   const thankYouMessage = <p>Thank you for your input!</p>
    // const form = <form>...</form>

    // return (isSent ? thankYouMessage : form);



    var flightsState = filterMainArray;
    var appfn = {};
    var returnSeg = retArray.retArray;
    let AirportSegment = [];
    let segm = {};

    return (
        <React.Fragment>
            <div className={classes.header}>
                <Typography className={classes.Typography} variant="h3" component="h2"  >
                    LCC Flights
      </Typography>
            </div>
            {/* <CssBaseline /> */}
            <Container className={classes.container} >

                {/* <Card className={classes.card}>
    <CardContent> */}
                <FormControl component="fieldset">

                    <RadioGroup aria-label="position" name="position" value={value} onChange={handleChangeRadio} row>

                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                onClick={Showing1}

                                value="return"
                                id="form1"
                                control={<Radio color="primary" />}
                                label="RETURN"
                                labelPlacement="start"
                            />


                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                value="oneway"
                                onClick={Showing2}
                                id="form2"
                                control={<Radio color="primary" />}
                                label="ONEWAY"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <Tooltip title="Coming Soon">
                                <FormControlLabel
                                    value="disabled"
                                    // onClick={Showing3}
                                    disabled
                                    control={<Radio color="primary" />}
                                    label="MULTICITY"
                                    onMouseOver="Coming Soon"
                                    labelPlacement="start"
                                />
                            </Tooltip>

                        </Grid>









                        <form onSubmit={submit} noValidate autoComplete="off">
                            <div className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid item sm={3} xs={6} lg={3} xl={3} >
                                        <Autocomplete
                                            id="auto-complete"
                                            options={airportList}
                                            getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')' : option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}
                                            inputValue={FromLabel}

                                            className={classes.textField}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    setFrom(newValue.id);
                                                    setFromLabel(newValue.value.split('|')[2] != '' ? newValue.value.split('|')[2] : newValue.value.split('|')[1].split(' ')[0]);
                                                }
                                                else {
                                                    setFrom('');
                                                }
                                            }}
                                            onBlur={(e) =>
                                                // handleFromValidation()
                                                setAirportList([])
                                            }
                                            // helperText= {validateMsg ? (<Typography color="secondary">From Should be empty</Typography>):(null)}
                                            onInputChange={(event, value) => {
                                                setFromLabel(value);
                                                if (value.length >= 3) {
                                                    superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                        .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                        .set({ 'login-key': localStorage.getItem('token') })
                                                        .send({
                                                            "code": value
                                                        })
                                                        .then(res => {

                                                            setAirportList(res.body)
                                                        })
                                                }
                                            }}

                                            renderInput={params => {
                                                return (
                                                    <TextField {...params} label="From" fullWidth />
                                                )
                                            }}
                                        />

                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3} >



                                        <Autocomplete
                                            //  helperText= {toValidate ?(<Typography color="secondary">To should not be empty</Typography>):(null)}
                                            id="auto-complete"
                                            options={airportList}
                                            getOptionLabel={isPermanent ? option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}
                                            // getOptionLabel={option => option.value.split('|')[2]+' '+'('+option.value.split('|')[0]+')'}

                                            inputValue={ToLabel}
                                            margin="normal"
                                            className={classes.textField}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    setTo(newValue.id);
                                                    setToLabel(newValue.value.split('|')[2] != '' ? newValue.value.split('|')[2] : newValue.value.split('|')[1].split(' ')[0]);
                                                }
                                                else {
                                                    setTo('');
                                                }
                                            }}
                                            onBlur={() => {
                                                setAirportList([]);
                                                // handleToValidation()
                                            }}
                                            onInputChange={(event, value) => {
                                                setToLabel(value);
                                                if (value.length >= 3) {
                                                    superagent.post(config.apiUrl + '/api/Flight/getAirport')
                                                        .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                        .set({ 'login-key': localStorage.getItem('token') })
                                                        .send({
                                                            "code": value
                                                        })
                                                        .then(res => {

                                                            setAirportList(res.body)
                                                        })
                                                }
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <TextField {...params} label="To" fullWidth />
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // disableToolbar
                            autoOk
                            variant="inline"
                            format="yyyy/MM/dd"
                            // formatDate={(date) => Moment(date).format('yyyy/MM/dd')}
                            margin="normal"
                            id="date-picker-inlineDeparture"
                            name="Departure"
                            label="Departure"
                            className={classes.textField}
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      */}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                autoOk



                                                label="Departure"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                format="dd MMM yyyy"
                                                animateYearScrolling
                                                className={classes.textField}
                                                minDate={startDate}
                                                // shouldDisableDate={disablePrevDates(startDate)}
                                                utcOffset={0}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>


                                    {showing1
                                        ?

                                        <Grid item sm={3} xs={6} lg={3} xl={3}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              format="yyyy/MM/dd"
                              margin="normal"
                              // name="Return"
                              id="date-picker-inlineReturn"
                              label="Return"
                              value={selectedDate1}
                              className={classes.textField}
                              onChange={handleDateChange1}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk



                                                    label="Return"
                                                    value={selectedDate1}
                                                    onChange={handleDateChange1}
                                                    format="dd MMM yyyy"
                                                    animateYearScrolling
                                                    className={classes.textField}
                                                    minDate={selectedDate}
                                                // shouldDisableDate={disablePrevDates1(selectedDate)}
                                                // min={format(selectedDate, "dd/MM/yyyy")}
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>

                                        : null
                                    }

                                    {empty ?


                                        <Grid item sm={3} xs={6} lg={3} xl={3}>
                                            <div className={classes.textField}>
                                                <span>&nbsp;&nbsp;  </span>


                                            </div>

                                        </Grid>




                                        : null
                                    }


                                    {addbutton ?
                                        <div>

                                            {
                                                fields.map((field, idx) => {
                                                    return (
                                                        <div key={'${field}-${idx}'}>
                                                            <Grid container spacing={3}>


                                                                <Grid item xs={3}>
                                                                    <TextField
                                                                        id="standard-search1"
                                                                        label="From"
                                                                        name="From1"
                                                                        type="search"
                                                                        className={classes.textField1}
                                                                        margin="normal"
                                                                        onChange={e => handleChange(idx, e)}
                                                                        value={field.value}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>

                                                                    <TextField
                                                                        id="standard-search2"
                                                                        label="To"
                                                                        name="To1"
                                                                        type="search"
                                                                        value={field.value}
                                                                        className={classes.textField1}
                                                                        margin="normal"
                                                                        onChange={e => handleChange(idx, e)}
                                                                        required
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            variant="inline"
                                                                            format="MM/dd/yyyy"
                                                                            name="Departure1"
                                                                            margin="normal"
                                                                            id="date-picker-inline1"
                                                                            label="Departure"
                                                                            value={selectedDate2 || field.value}
                                                                            className={classes.textField1}
                                                                            onChange={handleDateChange2}
                                                                            KeyboardButtonProps={{
                                                                                'aria-label': 'change date',
                                                                            }}
                                                                            required
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>


                                                                {fields.length ?
                                                                    <Grid item xs={1}>

                                                                        <DeleteIcon
                                                                            onClick={() => handleRemove()}
                                                                        />

                                                                    </Grid>
                                                                    : null}

                                                                {fields.length < 4 ?

                                                                    <Grid item xs={1}>
                                                                        <AddCircleIcon onClick={() => handleAdd()} />

                                                                    </Grid>
                                                                    : null}

                                                            </Grid>
                                                        </div>

                                                    )
                                                }


                                                )
                                            }



                                        </div>
                                        : null}






                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            label="Cabin"
                                            name="cabin"
                                            className={classes.textField}
                                            value={cabin}
                                            onChange={handleChangeSelect}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {currencies.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>




                                    <Grid item sm={3} xs={6} lg={3} xl={3}>

                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            label="Adult"
                                            className={classes.textField}
                                            // name="Adultfield"
                                            value={adult}
                                            onChange={handleChangeAdult}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {Adult.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}.
                        </TextField>

                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            name="Childfield"
                                            label="Child"
                                            className={classes.textField}
                                            value={child}
                                            onChange={handleChangeChild}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >
                                            {Child.map(option => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            name="Infant"
                                            className={classes.textField}
                                            value={infant}

                                            label="Infant"
                                            onChange={handleChangeInfant}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >

                                            {InfantValue.map(option => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select

                                            label="Market Country"
                                            // name="Marketcountry"
                                            className={classes.textField}
                                            value={market}
                                            onChange={handleMarket}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        // margin="normal"
                                        >


                                            {marketState.map((country, i) => (

                                                <MenuItem key={country.countryCode} value={country.countryCode} selected >
                                                    {country.countryCode}
                                                </MenuItem>


                                            ))}


                                        </TextField>
                                    </Grid>
                                    {/* <Grid item  sm={3} >
                                            <TextField
                                                id="standard-searchMarket"
                                                label="Market Country"
                                                name="MarketCountry"
                                                type="search"
                                                value={market}
                                                onChange={handleMarket}
                                                className={classes.textField}
                                                margin="normal"
                                                inputProps={
                                                    { maxLength: 2 }
                                                }



                                            />
                                        </Grid> */}


                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <Button color="primary" className={classes.button} onClick={handleClickOpen}>
                                            sites
      </Button>

                                        <Dialog open={open} aria-labelledby="form-dialog-title">

                                            <DialogContent>
                                                <Typography variant="h3" component="h5" color="primary">
                                                    <center> Sites  </center>
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item sm={12}>

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectall.selectAll}
                                                                    onChange={handleCheckboxSelectall('selectAll')}
                                                                    value="selectAll"
                                                                    // onClick={handleCheckboxSelectall1}
                                                                    inputProps={{
                                                                        'aria-label': 'uncontrolled-checkbox',
                                                                    }}
                                                                />}
                                                            label="Select All" />
                                                    </Grid>


                                                    {/* 
          
                                                        {checkBoxElement = <Checkbox
                                                            checked={checkbox.sitesIn}
                                                            onChange={handleCheckbox(sitesIn)}
                                                            value={sitesIn}
                                                            inputProps={{
                                                                'aria-label': 'uncontrolled-checkbox',
                                                            }}
                                                        />}
                                                        <Grid item sm={3}>
                                                            <FormControlLabel
                                                                control={
                                                                    checkBoxElement
                                                                }
                                                                label={checkboxState}
                                                            />
                                                        </Grid> */}

                                                    {
                                                        carriers.map((val) => {

                                                            return (
                                                                <Grid item sm={4}>
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            checked={val.isChecked}
                                                                            onChange={handleCheckbox(val.carrierCode)}
                                                                            value={val.carrierCode}
                                                                            inputProps={{
                                                                                'aria-label': 'uncontrolled-checkbox',
                                                                            }}
                                                                        />}
                                                                        label={val.label}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                    {/* {checkBoxElement +=   <FormControlLabel control={
                                                            <Checkbox
                                                                checked={carriers.isChecked}
                                                                onChange={handleCheckbox(carriers.carrierCode)}
                                                                value={carriers.carrierCode}
                                                                inputProps={{
                                                                    'aria-label': 'uncontrolled-checkbox',
                                                                }}
                                                            />}
                                                            label={carriers.label}
                                                        />}
                                                        {checkBoxElement} */}



                                                </Grid>
                                                <Button color="primary" onClick={handleClose} className={classes.buttonSites} variant="contained"
                                                    disabled={carriers.filter((val) => {
                                                        if (val.isChecked == true) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }

                                                    }).length < 1}
                                                >
                                                    OK
                                                     </Button>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        {loader ? (
                                            <CircularProgress size={26} className={classes.loginLoader} />
                                        ) : (
                                                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                    search
      </Button>)
                                        }
                                        {/* <Button id ="unique" variant="contained" onClick={handleBooking}>test</Button>  */}
                                    </Grid>

                                </Grid>
                            </div>
                        </form>


                    </RadioGroup>
                </FormControl>
                {/* </CardContent>
    </Card> */}
            </Container>
            {/* {if(true){

      }
      else{

      }} */}


            {fade ? (
                <Typography className="container" color="Secondary" variant="h3" className={classes.fadeError}>
                    {errMessage}
                </Typography>)
                : ''}

            <div>
                &nbsp;
</div>




            {isLoading ?
                (

                    <Grid>

                        <div>
                            &nbsp;
                         </div>

                        {(config.accessReqRes.includes(userContextData.data[0]['role_id']) ? (
                            <div>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={responseButton}
                                    className={classes.LinkButton}
                                >
                                    View Request/Response
    </Link>


                                {responsebutton ?
                                    <div className="container" >
                                        <Grid Container >
                                            <Grid item xl={5}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Request"
                                                    multiline
                                                    rows="4"
                                                    value={requestData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xl={2} ></Grid>
                                            <Grid item xl={5} >
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Response"
                                                    multiline
                                                    rows="4"
                                                    value={responseData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                    </div> : null}
                            </div>
                        ) : null)}


                        <div>
                            &nbsp;
                         </div>
                        <Grid Container spacing={1}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panecl1a-content"
                                    id="Filter"
                                >
                                    <Typography className={classes.heading}>Filters</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Airlines</InputLabel>
                                                <Select
                                                    id="FilterAirline"
                                                    name='airlines'
                                                    multiple
                                                    value={filter.airlines}
                                                    onChange={handleChangeFilter}
                                                    input={<BootstrapInput />}
                                                    variant="outlined"
                                                    renderValue={() => {
                                                        return filter.airlines.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Airline';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.airlines.map((dataAirline, i) => {
                                                        return (
                                                            <MenuItem key={dataAirline.value} value={i}>
                                                                <Checkbox checked={dataAirline.checked} value={dataAirline.value} />
                                                                <ListItemText primary={dataAirline.label == '' ? dataAirline.value : dataAirline.label} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Stops</InputLabel>
                                                <Select
                                                    id="FilterStops"
                                                    name='stops'
                                                    multiple
                                                    value={filter.stops}
                                                    onChange={handleChangeFilter}
                                                    input={<BootstrapInput />}
                                                    variant="outlined"
                                                    //   renderValue={selected => selected.join(', ')}
                                                    renderValue={() => {
                                                        return filter.stops.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Stops';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.stops.map((dataStops, i) => {
                                                        return (

                                                            <MenuItem key={dataStops.value} value={i}>
                                                                <Checkbox checked={dataStops.checked} value={dataStops.value} />
                                                                <ListItemText primary={dataStops.label == '' ? dataStops.value : dataStops.label} />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select StopOvers</InputLabel>
                                                <Select
                                                    id="FilterStopOvers"
                                                    name='stopOvers'
                                                    multiple
                                                    value={filter.stopOvers}
                                                    onChange={handleChangeFilter}
                                                    input={<BootstrapInput />}
                                                    variant="outlined"
                                                    //   renderValue={selected => selected.join(', ')}
                                                    renderValue={() => {
                                                        return filter.stopOvers.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select stop Flights';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.stopOvers.map((dataStopsOver, i) => {
                                                        return (

                                                            <MenuItem key={dataStopsOver.value} value={i}>
                                                                <Checkbox checked={dataStopsOver.checked} value={dataStopsOver.value} />
                                                                <ListItemText primary={dataStopsOver.label == '' ? dataStopsOver.value : dataStopsOver.label} />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Select Sites</InputLabel>
                                                <Select
                                                    id="FilterSite"
                                                    name='sites'
                                                    multiple
                                                    value={filter.sites}
                                                    onChange={handleChangeFilter}
                                                    input={<BootstrapInput />}
                                                    variant="outlined"
                                                    renderValue={() => {
                                                        return filter.sites.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Sites';
                                                    }}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                >
                                                    {filter.sites.map((dataSite, i) => {
                                                        return (
                                                            <MenuItem key={dataSite.value} value={i}>
                                                                <Checkbox checked={dataSite.checked} value={dataSite.value} />
                                                                <ListItemText primary={dataSite.label == '' ? dataSite.value : dataSite.label} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <div>
                            &nbsp;
                         </div>

                        <div>

                            {flightsState.map(function (test, testInd) {

                                var tripMessage = '';

                                if (flightsState.length == 2) {
                                    if (testInd == 0) {
                                        tripMessage = 'Onward Flights'
                                    }
                                    else {
                                        tripMessage = 'Return Flights'
                                    }
                                }
                                // if (testInd ==0 && showing1 == true){

                                //     var onward ='OnWard Flights'
                                // }
                                // var  returnHeading = '';
                                //  if (testInd > 0) {
                                //     returnHeading  += 'Return Flights'
                                // }





                                return (<div >
                                    <center><Typography variant="h3" color="primary" className={classes.HeadingFlights}>{tripMessage}</Typography></center>
                                    {/* <center> <Typography variant="h3" color="primary" className={classes.HeadingFlights}>{returnHeading}</Typography></center> */}


                                    {


                                        test.map((tsegment, tsegInx) => {
                                            //         return(
                                            // <AppBar1 seg={tsegment} />
                                            //         )
                                            AirportSegment = flightsState;

                                            var airLinrStr = '';
                                            var airlineSeg = "";
                                            var stopFlig = "";
                                            var numberStop = "";
                                            var tempArray = [];
                                            // var currencyValue = "";



                                            return (<div  >

                                                <Card spacing={2} >

                                                    <Grid container spacing={0} key={'segmentkey' + tsegInx} style={{ padding: 15 }}>


                                                        {/* <div className="row"> */}

                                                        <Grid item /* setMarket={1} */ xs={1} variant="body2">
                                                            {tsegInx + 1}
                                                        </Grid>

                                                        <Grid item xs={11} >
                                                            <Grid
                                                                container
                                                                direction="row"

                                                            >

                                                                <Grid item sm={8}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"

                                                                    >

                                                                        {
                                                                            tsegment.Segment.map((tseg, tseginx) => {
                                                                                segm = tseg
                                                                                if (tseginx > 0) {
                                                                                    stopFlig += tsegment.Segment[tseginx].Departure.AirportCode;
                                                                                    if (tseginx < tsegment.Segment.length - 1) {
                                                                                        stopFlig += ', '
                                                                                    }
                                                                                }

                                                                            })
                                                                        }








                                                                        <Grid item sm={2} xs={6} >
                                                                            {
                                                                                tsegment.Segment.map((arr, segInd) => {
                                                                                    if (!tempArray.includes(tsegment.Segment[segInd].MarketingCarrier.AirlineID)) {

                                                                                        tempArray.push(tsegment.Segment[segInd].MarketingCarrier.AirlineID);
                                                                                        return (
                                                                                            <img src={config.iconUrl + tsegment.Segment[segInd].MarketingCarrier.AirlineID + ".png"} onError={(e) => { e.target.onerror = null; e.target.src = config.iconUrl + 'plane.png'; e.target.style.height = '25px'; e.target.style.width = '25px'; }} />

                                                                                        )
                                                                                    }

                                                                                })
                                                                            }
                                                                            <Typography variant="body2" > {airLinrStr}</Typography>
                                                                        </Grid>


                                                                        <Grid item sm={4} xs={6}>
                                                                            <Typography variant="body2" style={{ marginBottom: 3 }}>  {tsegment.Segment[0].Departure.DateTime.replace('|', ' ')} </Typography>

                                                                            <b><Typography variant="body2" style={{ marginBottom: 3, fontWeight: "bold" }}>{tsegment.Segment[0].Departure.AirportCode}</Typography></b>
                                                                        </Grid>


                                                                        <Grid item sm={2} xs={6}>
                                                                            {/* {
                                                                                tsegment.Segment.map((num, segNum) => {
                                                                                    if (tsegment.Segment.length == 1) {
                                                                                        numberStop += "Non Stop"
                                                                                    } else {
                                                                                        if (segNum < tsegment.Segment.length - 1) {
                                                                                            // numberStop += "Non Stop"
                                                                                            numberStop += Number(tsegment.Segment.length - 1) + 'stop';

                                                                                        }


                                                                                    }
                                                                                })

                                                                            } */}
                                                                            <Typography variant="body2" style={{ marginBottom: 3 }}>{tsegment.Segment.length == 1 ? 'Non Stop' : (tsegment.Segment.length == 2 ? '1 Stop' : (tsegment.Segment.length - 1) + ' Stops')}</Typography>

                                                                            <b>  <Typography variant="body2" style={{ marginBottom: 3, fontWeight: "bold" }}>{stopFlig}</Typography></b>



                                                                        </Grid>

                                                                        <Grid item sm={4} xs={6}>
                                                                            {/* ArrivalDate and time  */}
                                                                            <Typography style={{ marginBottom: 3 }} variant="body2" > {tsegment.Segment[tsegment.Segment.length - 1].Arrival.DateTime.replace('|', ' ')}</Typography>
                                                                            <b><Typography variant="body2" style={{ marginBottom: 3, fontWeight: "bold" }}  >{tsegment.Segment[tsegment.Segment.length - 1].Arrival.AirportCode}</Typography></b>
                                                                        </Grid>


                                                                        <Grid item sm={12} xs={12}>
                                                                            {
                                                                                tsegment.Segment.map((arr, segInd) => {
                                                                                    airlineSeg += tsegment.Segment[segInd].MarketingCarrier.AirlineID + tsegment.Segment[segInd].MarketingCarrier.FlightNumber + '-' + tsegment.Segment[segInd].Departure.AirportCode + tsegment.Segment[segInd].Arrival.AirportCode;
                                                                                    if (segInd < tsegment.Segment.length - 1)
                                                                                        airlineSeg += '/';
                                                                                })
                                                                            }
                                                                            <Typography style={{ marginBottom: 3, fontStyle: "italic" }} variant="body2">{airlineSeg}</Typography>
                                                                        </Grid>
{/* 
                                                                        {
                                                                            airlineSource.map((val) => {
                                                                                if (val.SourceID == tsegment.Source) {
                                                                                    currencyValue += val.FareCurrency;
                                                                                }
                                                                            })
                                                                        } */}



                                                                        {/* col-9 */}
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item sm={4} style={{ inlineSize: "inherit" }}>

                                                                    {

                                                                        tsegment.FareDetail[0].AverageFare == '' ?
                                                                            (

                                                                                Object.keys(tsegment.FareDetail[0].BrandedFares).map(fareInx => {
                                                                                    {
                                                                                        var testing = tsegment.FareDetail[0].BrandedFares[fareInx];




                                                                                        if (tsegment.FareDetail[0].BrandedFares[fareInx] != '') {

                                                                                            return (

                                                                                                <Grid
                                                                                                    container
                                                                                                    direction="row"
                                                                                                    style={{ placeContent: "flex-end" }}
                                                                                                >
                                                                                                    <Grid item sm={6} xs={8} style={{ textAlignLast: "end", alignSelf: 'center', padding: '9px' }}  >
                                                                                                        <paper className={classes.wordFare}> {fareInx} </paper>

                                                                                                    </Grid>
                                                                                                    <Grid item sm={6} xs={7} style={{ padding: '5px' }}>
                                                                                                        {/* {
                                                                                                            showReturn?
                                                                                                          <Button className={classes.fareButton} variant = {test[0].FareDetail[0].BrandedFares[fareInx]===selectedreturn?"contained":"outlined"}color="primary" key={test[0].FareDetail[0].BrandedFares[fareInx]}
                                                                                                          
                                                                                                          value={airlineSeg} id={tsegment.ItineraryID} onClick={bookingDataReturn(test[0].FareDetail[0].BrandedFares[fareInx])}>{'\u00A0'}<b>
                                                                                                              {fareCurrency + ' ' + tsegment.FareDetail[0].BrandedFares[fareInx]}</b></Button>
                                                                                                              :null
                                                                                                      }                                         */}


                                                                                                        <Button className={classes.fareButton} variant={/* selectedValues.includes(tsegment.ItineraryID + '^' + fareInx.toLowerCase()) */
                                                                                                            (bookingButton.findIndex((val) => val.ItineraryID == tsegment.ItineraryID + '^' + fareInx.toLowerCase() + '^' + tsegment.Source) > -1)
                                                                                                                ? "contained" : "outlined"}

                                                                                                            color="primary" key={tsegment.ItineraryID + '^' + fareInx.toLowerCase() + '^' + tsegment.Source}
                                                                                                            string={fareInx} booking={tsegment.FareDetail[0].BrandedFares[fareInx]}
                                                                                                            value={airlineSeg} id={tsegment.ItineraryID} onClick={bookingDataReturn(tsegment.ItineraryID + '^' + fareInx.toLowerCase() + '^' + tsegment.Source, fareInx, tsegment.ItineraryID, tsegment.FareDetail[0].BrandedFares[fareInx])}>{'\u00A0'}<b>
                                                                                                                {fareCurrency + ' ' + tsegment.FareDetail[0].BrandedFares[fareInx]}</b></Button>





                                                                                                        {/* {showOneway ?


                                                                                                            <Button className={classes.fareButton} variant={tsegment.ItineraryID + '^' + fareInx.toLowerCase() === selectedbutton ? "contained" : "outlined"} color="primary" key={tsegment.ItineraryID + '^' + fareInx.toLowerCase()}
                                                                                                                string={fareInx} booking={tsegment.FareDetail[0].BrandedFares[fareInx]}
                                                                                                                value={airlineSeg} id={tsegment.ItineraryID} onClick={bookingData(tsegment.ItineraryID + '^' + fareInx.toLowerCase())}>{'\u00A0'}<b>
                                                                                                                    {fareCurrency + ' ' + tsegment.FareDetail[0].BrandedFares[fareInx]}</b></Button>

                                                                                                            : null} */}


                                                                                                    </Grid>


                                                                                                </Grid>

                                                                                            )
                                                                                        }



                                                                                    }

                                                                                })
                                                                            ) : (
                                                                                // return (
                                                                                //  <div>
                                                                                //      <p>{tsegment.Segment.FareDetail[0].Source + ': ' + fareInx}</p><b><button type="submit" variant="contained" color="primary">{tsegment.Segment.FareDetail[0].BrandedFares[fareInx]}</button></b>
                                                                                //  </div>
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    style={{ placeContent: "flex-end" }}
                                                                                >
                                                                                    <Grid item sm={6} xs={12} style={{ textAlignLast: "center", alignSelf: 'center', padding: '5px' }}>
                                                                                        <paper className={classes.wordFare}> {''} </paper>
                                                                                    </Grid>
                                                                                    <Grid item sm={6} xs={12} style={{ padding: '5px' }}>

                                                                                        {tsegment.FareCalculation == 'Flight' ? (
                                                                                            //     <Tooltip 
                                                                                            //      PopperProps={{
                                                                                            //          disablePortal: true,
                                                                                            //        }}
                                                                                            //     open = {alertMsg}  
                                                                                            //      disableFocusListener
                                                                                            //     disableHoverListener
                                                                                            //     disableTouchListener
                                                                                            //     title =
                                                                                            // 'Select Itinerary to View Fares ! '

                                                                                            //     >

                                                                                            <Button type="submit" variant={(bookingButton.findIndex((val) => val.ItineraryID == tsegment.ItineraryID + '^' + '' + '^' + tsegment.Source) > -1) ? 'contained' : 'outlined'} color="primary" key={'fare' + 'AverageFare'}
                                                                                                onClick={bookingDataReturn(tsegment.ItineraryID + '^' + '' + '^' + tsegment.Source, '', tsegment.ItineraryID, '')}>{'\u00A0'}<b>
                                                                                                    {(bookingButton.findIndex((val) => val.ItineraryID == tsegment.ItineraryID + '^' + '' + '^' + tsegment.Source) > -1) ? 'Selected' : 'Select'}</b></Button>
                                                                                            //    </Tooltip>


                                                                                        ) : (
                                                                                                <Button type="submit" /* className={classes.fareButton} */ variant={(bookingButton.findIndex((val) => val.ItineraryID == tsegment.ItineraryID + '^' + '' + '^' + tsegment.Source) > -1) ? 'contained' : 'outlined'} color="primary" key={'fare' + 'AverageFare'}
                                                                                                    onClick={bookingDataReturn(tsegment.ItineraryID + '^' + '' + '^' + tsegment.Source, '', tsegment.ItineraryID, tsegment.FareDetail[0].AverageFare)}>{'\u00A0'}<b>
                                                                                                        {fareCurrency + ' ' + tsegment.FareDetail[0].AverageFare}</b></Button>
                                                                                            )}
                                                                                    </Grid>

                                                                                </Grid>

                                                                                // )
                                                                            )
                                                                    }

                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                        {/* col-11 */}








                                                    </Grid>
                                                    <div>
                                                        &nbsp;
</div>

                                                </Card>

                                                {/* <h3><div className={classes.ReturnHead}>{showing1 ? 'Return Flights' : ''}</div></h3> */}
                                                <div>
                                                    &nbsp;
                                        </div>


                                            </div>
                                            );

                                        })
                                    }</div>

                                );
                                //     }
                                //    </div>
                            })
                            }
                        </div>
                        {footer ?
                            <AppBar container position="fixed" color="inherit" className={classes.appBar}>
                                <Container maxWidth="sm" style={{ maxWidth: "87%" }}>

                                    <Grid item xs={12} style={{ margin: "1%" }}>
                                        <Grid container
                                            direction="row"
                                        >
                                            {display ?

                                                <React.Fragment>
                                                    <Grid item xs={3}>
                                                        <Typography style={{ fontWeight: "bolder", fontSize: "x-large" }}>{bookingButton[0].city}{' '} {bookingButton[0].fare} </Typography>

                                                        <Typography style={{ fontSize: "x-small" }}>
                                                            {bookingButton[0].ItineraryID.split('^')[0]}
                                                        </Typography>

                                                    </Grid>

                                                    {showing1 && bookingButton[1] != undefined && bookingButton[1] != '' ?
                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bolder", fontSize: "x-large" }}> {bookingButton[1].city} {' '} {bookingButton[1].fare} </Typography>
                                                            <Typography style={{ fontSize: "x-small" }}> {bookingButton[1].ItineraryID.split('^')[0]}</Typography>
                                                        </Grid>
                                                        : null}
                                                    {showReturn && bookingButton[1] != undefined && bookingButton[1] != '' ?
                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bolder" }}> TOTAL FARE </Typography>

                                                            <Typography style={{ fontSize: "x-large" }}>

                                                                {fareCurrency}&nbsp;{bookingButton[0].ItineraryID.split('^')[3] != 'U2' ? ((Number(bookingButton[0].fare) + Number(bookingButton[1].fare)).toFixed(2)) : easyJet}
                                                            </Typography>

                                                        </Grid>
                                                        :
                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bolder" }}> TOTAL FARE </Typography>

                                                            <Typography style={{ fontSize: "x-large" }}>

                                                                {fareCurrency} &nbsp;{bookingButton[0].fare}
                                                            </Typography>

                                                        </Grid>

                                                    }
                                                </React.Fragment>
                                                : null}

                                            {
                                                selectedValues[showing1 ? 1 : 0] != undefined ?

                                                    <Grid item xs={3} style={{ textAlignLast: "center" }}>
                                                        {bookingloader ?
                                                            <CircularProgress size={26} className={classes.loginLoader} /> : (
                                                                <Button onClick={bookingContinue} variant="contained" color="primary">Continue</Button>)
                                                        }
                                                    </Grid> : null
                                            }
                                        </Grid>

                                        <Grid item xs={12} style={{ margin: "1%" }}>
                                            {status ? (<Grid>
                                                <Typography variant='h4'><b>{'Booking Process'}</b></Typography>
                                                {
                                                    Object.keys(pages.pages).map((val) => {
                                                        return (
                                                            <Typography style={{ fontWeight: "bolder" }}>
                                                                <a target='_blank' href={pages.pages[val]}>{val}</a>
                                                            </Typography>
                                                        );
                                                    })}
                                            </Grid>)
                                                : null}
                                            {fade1 ? (
                                                <Typography className="container" color="Secondary" variant="h3" className={classes.fadeError}>
                                                    {errMessageFare}
                                                </Typography>)
                                                : ''}
                                        </Grid>
                                    </Grid>



                                </Container>
                            </AppBar>


                            : ''}
                        {/* </Container> */}


                    </Grid>


                )
                : ''}
            <Snackbar open={alertMsg} autoHideDuration={6000} onClose={handleCloseInit} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity="error" onClose={handleCloseInit} >
                    {alertTextMessage}
                </Alert>
            </Snackbar>



        </React.Fragment >)

})


