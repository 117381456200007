import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import superagent from 'superagent';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CommonFunction from '../../lib/commonFunction';
import { email as emailRegex } from "../../pages/login/regexes";
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import Fade from '@material-ui/core/Fade';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useUserState } from "../../context/UserContext";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import config from '../../config/config';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';



const Role = [
    {
        value: 'SA',
        label: 'SUPER ADMIN',
    },
    {
        value: 'US',
        label: 'USER',
    },
    {
        value: 'MA',
        label: 'MANAGER',
    },
    {
        value: 'MU',
        label: 'METAUSER',
    },
];

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    root1: {
        width: '100%',
        paddingTop: '22px',
        paddingBottom: '26px',
        paddingLeft: '16px',
    },

    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        size: 'small',
        variant: 'extended',
        position: 'absolute',
        top: theme.spacing(9),
        right: theme.spacing(2),
    },
    fab1: {
        size: 'medium',
        variant: 'extended',

    },
    button: {
        float: 'right',
        '&:hover': {
            borderColor: '#0062cc',
            boxShadow: 'none',
            overflow: 'hidden'
        },
        cursor: 'Pointer',
    },
    button1: {
        left: theme.spacing(50),
    },
    table: {
        borderCollapse: 'collapse'
    },
    Typography: {
        left: theme.spacing(10),
    },
    typography: {
        position: 'fixed',

    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5,
        paddingTop: 10,
        paddingBottom: 10
    },
    header: {
        margin: theme.spacing(0, 2),
    },


    Pointer: {
        cursor: 'Pointer',
    },
    divider: {
        margin: theme.spacing(3, 0),
    },
    CancelIcon: {

        variant: 'extended',
        position: 'absolute',
        right: theme.spacing(2),
        '&:hover': {
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        cursor: 'Pointer',
    },
    tableBody: {
        margin: theme.spacing(5, 1)

    }

}));


export default function MetaConfig (props) {

    const classes = useStyles();
    var userDispatch = useUserDispatch();
    var errorData = JSON.parse(localStorage.getItem('errorMsg'))
    const [addForm, setAddForm] = React.useState(false);
    const [editForm, setEditForm] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [addData, setAddData] = React.useState({
        metaId: '',
        apiKey: '',
        apiUrl: '',
        currency: '',
        b2cUrl: '',
        rsource: '',
        utmString: '',
        metaName: '',
        userName: ''
    });
    const [editData, setEditData] = React.useState({
        metaId: '',
        apiKey: '',
        apiUrl: '',
        currency: '',
        b2cUrl: '',
        rsource: '',
        utmString: '',
        metaName: '',
        userName: ''
    });
    var [metaData, setMetaData] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [targetElement, setTargetElement] = React.useState();
    const [alertMsg ,setAlertMsg] =  React.useState(false)
    const [alertUser ,setAlertUser] =  React.useState(false)
    const [alertDelete, setAlertDelete] = React.useState('')
  const [alertAdd, setAlertAdd] = React.useState('')




    const fetchData = async () => {
        const result = await axios(
            config.apiUrl+'/api/Meta/getMeta',
            { headers: { 'login-key': localStorage.getItem('token') } }
        );
        if (result.data.status) {
            setMetaData(result.data.data);

        }
        else {
       if(result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4'|| result.data.message === 'ERMWMSG2'|| result.data.message === 'ERMWMSG5' ){


                alert(errorData[result.data.message])
                signOut(userDispatch, props.history)
            }
            else if (result.data.status === false) {
                alert(errorData[result.data.message])
                props.history.goBack();
            }
        }
    }





    useEffect(() => {
        fetchData();
    }, []);


    const handleClose = () => {
        setAddForm(false);
        setAddData({
            ...addData,
            id: '',
            apiKey: '',
            apiUrl: '',
            currency: '',
            b2cUrl: '',
            rsource: '',
            utmString: '',
            metaName: '',
            userName: ''
        })
    }

    const handleClickOpen = () => {
        setAddForm(true);
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
      const handleCloseInit = (event, reason) =>{
        if (reason === 'clickaway') {
          return;
        }
    
        setAlertMsg(false);
      }
      const handleCloseAdd = (event, reason) =>{
        if (reason === 'clickaway') {
          return;
        }
    
        setAlertUser(false);
      }
    

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (name == 'currency') {
            value = value.toUpperCase();
        }

        setAddData({
            ...addData,
            [name]: value
        });
    }

    const handleEditChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (name == 'currency') {
            value = value.toUpperCase();
        }

        setEditData({
            ...editData,
            [name]: value
        });
    }

    const handleAdd = (event) => {
        event.preventDefault();
        if (addData.apiKey == '' || addData.apiUrl == '' || addData.b2cUrl == '' || addData.currency == '' || addData.rsource == '' || addData.metaName == '' || addData.userName == '') {
            setError(true);
        }
        else {
            var reqData = {
                apiKey: addData.apiKey,
                apiUrl: addData.apiUrl,
                currency: addData.currency,
                b2cUrl: addData.b2cUrl,
                rsource: addData.rsource,
                utmString: addData.utmString,
                metaName: addData.metaName,
                userName: addData.userName
            };
            superagent.post(config.apiUrl+'/api/Meta/addMeta')
                .set('login-key', localStorage.getItem('token'))
                .send(reqData)
                .then((response) => {
                    if (response.body.status === true) {
                        var res = JSON.parse(response.text)
                        metaData.push(res.data[0]);
                        setAlertMsg(true)
                        setAlertAdd(errorData[response.body.message])
                        setMetaData([...metaData]);
                    }
                    else {
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
                       
                            alert(errorData[response.body.message])
                            signOut(userDispatch, props.history)
                        }
                        else if (response.body.status == false) {
                            alert(errorData[response.body.message])
                            handleClose();
                        }
                    }
                })
            handleClose();
        }

    }

    const handleClickOpenAlert = (e) => {
        setTargetElement(e.target);
        setOpenAlert(true);
    };

    const alertHandleClose = () => {
        setOpenAlert(false);
    }

    const deleteUser = (e) => {
        e.preventDefault();
        superagent.post(config.apiUrl+'/api/Meta/deleteMeta')
            .set('login-key', localStorage.getItem('token'))
            .send({ "metaId": CommonFunction.elementSelector(targetElement, 'td').getAttribute('value') })
            .then((response) => {
                if (response.body.status) {
                    setAlertUser(true)
                    setAlertDelete(errorData[response.body.message])
                    fetchData();
                }
                else {
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
                   
            alert(errorData[response.body.message])
                        signOut(userDispatch, props.history)
                    }
                    else if (response.body.status == false) {
                        alert(errorData[response.body.message])
                        handleClose();
                    }
                }
            });

        alertHandleClose();
    }

    const handleEditClose = () => {
        setEditForm(false);
    }

    const handleEdit = (event) => {
        event.preventDefault();
        if (editData.apiKey == '' || editData.apiUrl == '' || editData.b2cUrl == '' || editData.currency == '' || editData.rsource == '' || editData.metaName == '' || editData.userName == '') {
            setError(true);
        }
        else {
            var reqData = {
                metaId: editData.metaId,
                apiKey: editData.apiKey,
                apiUrl: editData.apiUrl,
                currency: editData.currency,
                b2cUrl: editData.b2cUrl,
                rsource: editData.rsource,
                utmString: editData.utmString,
                metaName: editData.metaName,
                userName: editData.userName
            };
            superagent.post(config.apiUrl+'/api/Meta/updateMeta')
                .set('login-key', localStorage.getItem('token'))
                .send(reqData)
                .then((response) => {
                    if (response.body.status == true) {
                        var updatMetaData = metaData.map((val) => {
                            if (val.meta_id == response.body.data[0].meta_id)

                                return response.body.data[0];
                            else
                                return val;
                        })
                        setMetaData([...updatMetaData]);
                    }
                    else {
                        if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
                            alert(errorData[response.body.message])
                            signOut(userDispatch, props.history)
                        }
                        else if (response.body.status == false) {
                            alert(errorData[response.body.message])
                            handleEditClose();
                        }
                    }
                });
            handleEditClose();
        }
    }

    const editUser = (e) => {
        e.preventDefault();
        superagent.post(config.apiUrl+'/api/Meta/getMeta')
            .set('login-key', localStorage.getItem('token'))
            .send({ "metaId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value') })
            .then((response) => {
                if (response.body.status) {
                    setEditForm(true);
                    var data = response.body.data;
                    setEditData({
                        metaId: data[0].meta_id,
                        apiKey: data[0].api_key,
                        apiUrl: data[0].api_url,
                        currency: data[0].currency,
                        b2cUrl: data[0].b2c_url,
                        rsource: data[0].rsource,
                        utmString: data[0].utm_string,
                        metaName: data[0].meta_name,
                        userName: data[0].user_name
                    });
                }
                else {
                    if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
                        alert(errorData[response.body.message])
                        signOut(userDispatch, props.history)
                    }
                }
            })
    }


    return (
        <React.Fragment>
            <div style={{ paddingBottom: 'inherit' }}>
                <div className={classes.header}>
                    <Typography className={classes.Typography} variant="h3" component="h2" >
                        Meta Config
                </Typography>
                </div>
                <Fab className={classes.fab} onClick={handleClickOpen} color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </div>
            <Snackbar open={alertMsg} autoHideDuration={6000} onClose={handleCloseInit}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseInit} color="success">
    
        {alertAdd}
        </Alert>
      </Snackbar>
      <Snackbar open={alertUser} autoHideDuration={6000} onClose={handleCloseAdd}  anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert onClose={handleCloseAdd} color="success">
    
        {alertDelete}
        </Alert>
      </Snackbar>

            <Paper className={classes.root1}>
                <Table className={classes.table}>
                    <Thead>
                        <Tr>
                            <Th ></Th>
                            <Th >API Key</Th>
                            <Th >API Url</Th>
                            <Th >Currency</Th>
                            <Th >B2C Url</Th>
                            <Th >RSource</Th>
                            <Th >UTM String</Th>
                            <Th >Meta Name</Th>
                            <Th >User Name</Th>
                            <Th >Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody >
                        {metaData.map((item, itInx) => {
                            return (
                                <Tr key={'item' + itInx}>
                                    <Td className={classes.tableCell}>{itInx + 1}</Td>
                                    <Td className={classes.tableCell}>{item.api_key}</Td>
                                    <Td className={classes.tableCell}>{item.api_url}</Td>
                                    <Td className={classes.tableCell}>{item.currency}</Td>
                                    <Td className={classes.tableCell}>{item.b2c_url}</Td>
                                    <Td className={classes.tableCell}>{item.rsource}</Td>
                                    <Td className={classes.tableCell}>{item.utm_string}</Td>
                                    <Td className={classes.tableCell}>{item.meta_name}</Td>
                                    <Td className={classes.tableCell}>{item.user_name}</Td>
                                    <Td className={classes.tableCell} value={item.meta_id}   >
                                        <span onClick={editUser}>
                                            <EditRoundedIcon /* onClick={editUser} */ className={classes.Pointer} color="primary" />
                                        </span>
                                        &nbsp;
                                       <DeleteRoundedIcon onClick={handleClickOpenAlert} className={classes.Pointer} color="secondary" />
                                    </Td>
                                </Tr>
                            )
                        }
                        )
                        }
                    </Tbody>
                </Table>
            </Paper>

            {/* add form */}
            <div>
                <Dialog open={addForm} onClose={handleClose} aria-labelledby="form-dialog-title">

                    <DialogContent>
                        <CancelIcon className={classes.CancelIcon} onClick={handleClose} />
                        <Typography variant="h3" component="h4" color="primary">
                            <center> Add Meta </center>
                        </Typography>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12}>
                                {error ? <Fade in={error}>
                                    <Typography color="secondary" className={classes.errorMessage}>
                                        <div>
                                            All fields are required
                                        </div>
                                    </Typography>
                                </Fade> : ''}

                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="API Key"
                                    margin="normal"
                                    name="apiKey"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField
                                        },
                                    }}
                                    value={addData.apiKey}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="API Url"
                                    margin="normal"
                                    name="apiUrl"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.apiUrl}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="Currency"
                                    margin="normal"
                                    name="currency"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },

                                    }}
                                    inputProps={
                                        { maxLength: 3 }
                                    }
                                    value={addData.currency}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="B2C Url"
                                    margin="normal"
                                    name="b2cUrl"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.b2cUrl}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="RSource"
                                    margin="normal"
                                    name="rsource"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.rsource}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="UTM String"
                                    margin="normal"
                                    name="utmString"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.utmString}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="Meta Name"
                                    margin="normal"
                                    name="metaName"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.metaName}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="User Name"
                                    margin="normal"
                                    name="userName"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={addData.userName}
                                    margin="normal"
                                    type="name"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Button variant="contained" color="primary" margin="normal" onClick={handleAdd} className={classes.button} >
                                    Save
                                </Button>
                                <Button color="primary" margin="normal" className={classes.button} onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog open={editForm} onClose={handleEditClose} aria-labelledby="form-dialog-title">

                    <DialogContent>
                        <CancelIcon className={classes.CancelIcon} onClick={handleEditClose} />
                        <Typography variant="h3" component="h4" color="primary">
                            <center> Edit Meta </center>
                        </Typography>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12}>
                                {error ? <Fade in={error}>
                                    <Typography color="secondary" className={classes.errorMessage}>
                                        <div>
                                            All fields are required
                                        </div>
                                    </Typography>
                                </Fade> : ''}

                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="API Key"
                                    margin="normal"
                                    name="apiKey"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField
                                        },
                                    }}
                                    value={editData.apiKey}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="API Url"
                                    margin="normal"
                                    name="apiUrl"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.apiUrl}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="Currency"
                                    margin="normal"
                                    name="currency"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },

                                    }}
                                    inputProps={
                                        { maxLength: 3 }
                                    }
                                    value={editData.currency}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="B2C Url"
                                    margin="normal"
                                    name="b2cUrl"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.b2cUrl}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="RSource"
                                    margin="normal"
                                    name="rsource"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.rsource}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="UTM String"
                                    margin="normal"
                                    name="utmString"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.utmString}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="Meta Name"
                                    margin="normal"
                                    name="metaName"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.metaName}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.textField}
                                    label="User Name"
                                    margin="normal"
                                    name="userName"
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={editData.userName}
                                    margin="normal"
                                    type="name"
                                    onChange={handleEditChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Button variant="contained" color="primary" margin="normal" onClick={handleEdit} className={classes.button} >
                                    Save
                                </Button>
                                <Button color="primary" margin="normal" className={classes.button} onClick={handleEditClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>


            {/* dialog alert */}
            <Dialog open={openAlert} onClose={alertHandleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to  Delete User?
                </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteUser} color="primary">
                        yes
                    </Button>
                    <Button onClick={alertHandleClose} color="primary">
                        no
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
} 