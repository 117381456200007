import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { wrap } from "module";
import axios, { post } from "axios";
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CommonFunction from '../../lib/commonFunction';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import superagent from 'superagent';
import Fade from '@material-ui/core/Fade';
import { VerticalAlignTop } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import config from '../../config/config';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import GetAppIcon from '@material-ui/icons/GetApp';
import commonFunction from "../../lib/commonFunction";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import StopIcon from '@material-ui/icons/Stop';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import socketIOClient from "socket.io-client";
import MuiAlert from '@material-ui/lab/Alert';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { green } from '@material-ui/core/colors';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Tooltip from '@material-ui/core/Tooltip';







const useStyles = makeStyles(theme => ({
  Typography: {
    left: theme.spacing(8),
    marginBottom: "2%",
    display: 'inline-block'
  },
  position:
  {
    display: 'inline-block',
    padding: '0px 20px'
  },
  padding:
  {
    padding: "50px"
  },
  button: {
    paddingRight: "10%"
  },
  tableCell: {

    padding: "10px !important",
  },
  Pointer: {
    cursor: 'Pointer',
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paginationRow:{
    minWidth:'100%'
  }

}))
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };



  return (
    <div className={classes.pagination}>
     <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}



export default function BulkSearch(props) {
  const classes = useStyles();
  var userDispatch = useUserDispatch();
  var testData = JSON.parse(localStorage.getItem('data'));
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({})
  const [csvData, setCsvData] = React.useState([])
  const [csvReqData, setCsvReqData] = React.useState([])
  var [files, setFiles] = React.useState(null)
  const [test, setTest] = React.useState(false);
  const [progress, setProgress] = React.useState(false)
  const [openMsg, setOpenMsg] = React.useState(false);
  const [alertOpen, setAlertOPen] = React.useState(false);
  const [socketMsg, setSocketMsg] = React.useState('')
  const [alertDelete, setAlertDelete] = React.useState('');
  const [isDeleteList, setIsDeleteList] = React.useState(false);
  const [fileFormat, setFileFormat] = React.useState(false);
  const [errorMsg, setErrMsg] = React.useState('');
  const [csvRowCount, setCsvRowCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  const emptyRows = data.length;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // fetch Data
  const fetchData = async () => {
    const result = await axios(
      config.apiUrl + '/api/Flight/getFileData',
      { headers: { 'login-key': localStorage.getItem('token') } }
    );
    if (result.data.status == true) {
      result.data.data = result.data.data.reverse(); 
      setData(result.data)
    }
    else {
      if (result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4' || result.data.message === 'ERMWMSG2' || result.data.message === 'ERMWMSG5') {
        alert(errorData[result.data.message])
        signOut(userDispatch, props.history)
      }
      else if (result.data.status == false) {
        alert(errorData[result.data.message])
        props.history.goBack();

      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const handleClose = () => {
    setFileFormat(false)
    setOpen(false);
    setFiles(null);
  };

  const handleClickOpen = () => {
    setOpen(true)
  };
  // BulkSearch init
  const DownloadReqCsv = (e) => {
    superagent.post(config.apiUrl + '/api/Flight/bulkSearchInit')
      .send({
        "batchId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value'),
        "fileName": CommonFunction.elementSelector(e.target, 'td').getAttribute('value1')
      })
      .set('Content-Type', 'application/json')
      .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(response => {
        fetchData();


        const socket = socketIOClient(config.apiUrl);
        socket.on('bulkSearchComplete', (data) => {

          if (data.status == true && testData[0].id === data.data[0].id) {
            // console.log(data,'test')
            // setOpenMsg(true)
            // socketIO(data)
            // setSocketMsg(errorData[data.message])
            fetchData();
          }

        });


      });
  }
  // BulkSearch  Download Fn
  const downloadRequest = function (responseSoln) {
    const blob = new Blob([responseSoln], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a')
    a.setAttribute('hidden', '');
    a.setAttribute('href', url)
    a.setAttribute('download', ' download.csv')
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  //BulkSearch Complete
  const DownloadCsv = async function (e) {
    var batchId;
    batchId = CommonFunction.elementSelector(e.target, 'td').getAttribute('value');
    await superagent.post(config.apiUrl + '/api/Flight/BulkSearchComplete')
      .send({
        "batchId": batchId
      })
      .set('Content-Type', 'application/json')
      .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(res => {
        const datamap = res.text
        downloadData(datamap)
      })
  }
  // BulkSearch Complete Fn
  const downloadData = function (datamap) {
    const blob = new Blob([datamap], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a')
    a.setAttribute('hidden', '');
    a.setAttribute('href', url)
    a.setAttribute('download', ' download.csv')
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleAddFiles = async (e) => {
    setFileFormat(false)
    setFiles(e.target.files[0])


    commonFunction.csvValidator(e.target.files[0], function (count) {
      setCsvRowCount(count);
    });
  }
  // BulkSearch File Upload 
  const fileUpload = (files) => {
    const url = config.apiUrl + '/api/Flight/fileUpload';
    const formData = new FormData();
    formData.append('fileUpload', files)
    const configHeader = {
      headers: {
        'content-type': 'multipart/form-data',
        'login-key': localStorage.getItem('token')
      }
    }
    return post(url, formData, configHeader)
  }

  const handleAddFunction = (e) => {
    e.preventDefault();

    if (files == null || files.name == '') {
      setFileFormat(true);
      setErrMsg('No File Found !!!');
    } else if (files.name.substring(files.name.length - 3) !== 'csv') {
      setFileFormat(true);
      setErrMsg('Invalid Format !!')
    } else if (csvRowCount < 2 || csvRowCount > 101) {
      setFileFormat(true);
      setErrMsg('Record should be greater than 1 and less than 100');
    }
    else {
      fileUpload(files).then((res) => {
        if (res.data.status == true) {
          data.data.unshift(res.data.data[0])

          setData({ ...data })
          setOpen(false);
        }
        else {
          setFileFormat(true);
          setErrMsg(errorData[res.data.message])
        }
      })
    }



  }
  // BulkSearch File Download

  const downloadFileUpload = (e) => {
    superagent.post(config.apiUrl + '/api/Flight/fileDownload')
      .send({
        "batchId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value1'),
        "fileName": CommonFunction.elementSelector(e.target, 'td').getAttribute('value')
      })
      .set('Content-Type', 'application/json')
      .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(response => {
        const responseSoln = response.text
        downloadRequest(responseSoln)
      })
  }
  // BulkSearch Abort
  const StopRequest = (e) => {
    superagent.post(config.apiUrl + '/api/Flight/bulkSearchAbort')
      .send({
        "batchId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value'),
      })
      .set('Content-Type', 'application/json')
      .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(response => {
        setAlertOPen(true)
        setAlertDelete(errorData[response.body.message])
        // setAlertBox(true)
        fetchData();
      })
  }
  //BulSearch Delete
  const deleteReq = (e) => {
    superagent.post(config.apiUrl + '/api/Flight/bulkSearchDelete')
      .send({
        "batchId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value'),
      })
      .set('Content-Type', 'application/json')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(response => {
        fetchData();
      })
  }
  // BulkSearch DeletedFile
  const listDeletedFiles = (e) => {
    var dataTemp = data;
    dataTemp = {};
    setData({ ...dataTemp });
    if (isDeleteList == false || e == undefined) {
      superagent.post(config.apiUrl + '/api/Flight/bulkSearchDeletedData')
        .set('Content-Type', 'application/json')
        .set({ 'login-key': localStorage.getItem('token') })
        .then(result => {
          if (result.body.status == true) {
            result.body.data = result.body.data.reverse(); 
            setData(result.body)
          }
          else {
            if (result.body.message === 'ERMWMSG3' || result.body.message === 'ERMWMSG4' || result.body.message === 'ERMWMSG2' || result.body.message === 'ERMWMSG5') {
              alert(errorData[result.body.message])
              signOut(userDispatch, props.history)
            }
            else if (result.body.status == false) {
              // alert(errorData[result.body.message])
              result.body.data = result.body.data.reverse(); 
              setData(result.body)
              // props.history.goBack();
            }
          }
        })
      setIsDeleteList(true);
    }
    else {
      fetchData();
      setIsDeleteList(false);
    }
  }
  // BulkSearch Undo
  const undoRequest = (e) => {
    superagent.post(config.apiUrl + '/api/Flight/bulkSearchDeleteUndo')
      .send({
        "batchId": CommonFunction.elementSelector(e.target, 'td').getAttribute('value'),
      })
      .set('Content-Type', 'application/json')
      .set({ 'login-key': localStorage.getItem('token') })
      .then(response => {
        listDeletedFiles();
      })
  }
  const handleClose2 = (event, reason) => {
    // setAlertBox({ ...alert, alertOpen: false });
    if (reason === 'clickaway') {
      return;
    }
    setAlertOPen(false);
  }

  const handleCloseInit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  }



  return (
    <React.Fragment>


      <div style={{ marginBottom: '15px' }}>
        <Typography className={classes.Typography} variant="h3" component="h2" >
          Bulk Search
        </Typography>
        <div style={{ display: 'inline-block', float: 'right' }}>
          <div className={classes.position}>
            <Button color='primary' variant={isDeleteList ? 'contained' : 'outlined'} onClick={listDeletedFiles}>{isDeleteList ? 'Hide' : 'Show'}{' '}Deleted List</Button>
          </div>
          <div className={classes.position}>
            <Fab color="primary" onClick={handleClickOpen} aria-label="add" disabled={isDeleteList ? true : false}>
              {/* <AddBoxIcon  variant="outlined" className={classes.position} color="primary" onClick={handleClickOpen} style={{fontSize:"xx-large"}} />  */}
              <AddIcon onClick={handleClickOpen} />
            </Fab>
          </div>
        </div>
      </div>
      {/* <AddBoxIcon  variant="outlined" className={classes.position} color="primary" onClick={handleClickOpen} style={{fontSize:"xx-large"}} />  */}
      <div>
        <Dialog open={open} onClose={handleClose} className={classes.dialog} aria-labelledby="form-dialog-title">

          <DialogContent >
            {/* <CancelIcon className ={classes.CancelIcon} onClick={handleClose}  /> */}
            <form onSubmit={handleAddFunction}>
              <Typography variant="h3" component="h2" color="primary" style={{ paddingBottom: "5%" }} >
                <center>  Add File  </center>
              </Typography>

              <Grid container spacing={1} className={classes.grid}>
                {/* <Grid item sm={12}>
                <Typography>Allowed file formats (.csv).</Typography>

              </Grid> */}
                <Grid item sm={6} style={{ marginRight: "16%" }}>


                  <input
                    accept=".csv"
                    // ref={'file-upload'}
                    // name="fileUpload"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleAddFiles}
                  />

                </Grid>

                <Grid item sm={4} >
                  <Button variant="contained" color="primary"
                    // onSubmit={handleAddFunction}
                    type="submit"
                  // action="config.apiUrl + '/api/Flight/fileUpload'"
                  // onClick={(e)=>  files['file-upload']}

                  > Add</Button>
                </Grid>
              </Grid>
              {fileFormat ? (<Typography color="secondary">{errorMsg} </Typography>) : ('')}
            </form>
          </DialogContent>

        </Dialog>
      </div>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose2} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose2} color="warning">
          {alertDelete}
        </Alert>
      </Snackbar>




      {/* 
      <Snackbar open={openMsg} autoHideDuration={6000} onClose={handleCloseInit} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseInit} color="success">

          {socketMsg}
        </Alert>
      </Snackbar> */}


      {data.status != undefined ?

        <div>
          {data.status == false || data.status == undefined ? (<Typography color="secondary" variant="h4" style={{ textAlign: 'center' }} >{errorData[data.message]}</Typography>) : (
            <Paper>
              <Table className={classes.table} >
                <Thead className={classes.TableHeadStyle}>
                  <Tr>
                    {/* <Th style={{ padding: '15px' }}>S.No</Th> */}
                    {/* <Th className={classes.empty}></Th> */}
                    <Th style={{ padding: '15px' }}> File</Th>
                    <Th style={{ padding: '15px' }} > Name</Th>
                    <Th style={{ padding: '15px' }}>Status</Th>
                    <Th style={{ padding: '15px' }}>Date Created</Th>
                    <Th style={{ padding: '15px' }} >Date Completed</Th>
                    <Th style={{ padding: '15px' }}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody className={classes.root1}>

            {(rowsPerPage > 0
            ? data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data.data
          ).map((item, itInx) => {
                  
                    return (

                      <Tr key={'item' + itInx}>

                        {/* <Td style={{ padding: '15px' }}>{itInx + 1}</Td> */}
                        <Td value={item.file_name} value1={item.batch_id} style={{ padding: '15px', overflowX: 'auto' }}>   {item.file_name}
                          < ArrowDownwardIcon className={classes.Pointer} color="primary" onClick={downloadFileUpload} />
                        </Td>
                        <Td className={classes.tableCell} style={{ padding: '15px' }}>{item.user_name}</Td>
                        {/* <Td className={classes.status}></Td>  */}
                        {/* <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.date_created.slice(0, 10) + ' ' + item.date_created.slice(11, 16)}</Td>  */}
                        {/* <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.notification_type}</Td> */}
                        <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.status ? commonFunction.capitalize(item.status) : '_'}</Td>
                        <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.date_created ? item.date_created : '_'}</Td>
                        <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.date_completed ? item.date_completed.slice(0, 10) + ' ' + item.date_completed.slice(11, 19) : '_'}</Td>
                        <Td ClassName={classes.tableCell} style={{ padding: '15px' }} value={item.batch_id} value1={item.file_name} >


                          {isDeleteList ? <span>
                            <SettingsBackupRestoreIcon onClick={undoRequest} style={{ color: green[500] }} className={classes.Pointer} ></SettingsBackupRestoreIcon>
                          </span> : <span>
                              {
                                item.status == null || item.status == '' || item.status == 'aborted' ? <PlayCircleFilledIcon className={classes.Pointer} color="primary" onClick={DownloadReqCsv} /> : ''}
                              {item.status == 'completed' || item.data_exist == 1 ? <GetAppIcon className={classes.Pointer} color="primary" onClick={DownloadCsv} /> : ''}
                              {item.status == 'in progress' ? <HighlightOffIcon className={classes.Pointer} color="secondary" onClick={StopRequest} /> : ''}
                              {item.status == 'completed' || item.status == 'aborted' ? <DeleteRoundedIcon onClick={deleteReq} className={classes.Pointer} color="secondary" /> : ''}
                            </span>}
                        </Td>
                      </Tr>
                    )
                  }
                  )
                  }
        
                </Tbody>
                {emptyRows > 0 && (
            <Tr style={{ height: 53 * emptyRows }}>
              <Td colSpan={6} />
            </Tr>
          )}
                <TableFooter>
          <TableRow>
            <TablePagination className={classes.paginationRow}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={10}
              count={data.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>

              </Table>

            </Paper>
          )}
        </div> : null
      }
    </React.Fragment>
  )
}