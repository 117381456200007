import React, { useState, useEffect } from "react";
import superagent from 'superagent';

//styles
// import { makeStyles } from '@material-ui/core/styles';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from "@material-ui/styles";

//state and dispatch
import { useUserDispatch, signOut } from "../../context/UserContext";
//material ui
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { CircularProgress, Typography, Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
//icons

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//pickers
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";
import 'date-fns';

//config
// import airportList from '../../config/airports'
// import data from './MetaAPIConfig'
import { array } from "prop-types";
// import response from './response';
import config from '../../config/config';
import { useUserState } from "../../context/UserContext";
import CommonFunction from '../../lib/commonFunction';

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%',
    },
    textField1: {
        width: '100%',
    },
    menu: {
        width: 200,
    },

    button: {
        marginLeft: "40%"
    },
    loginLoader: {
        // margin: theme.spacing(10),
        marginRight: theme.spacing(10),
        marginLeft: "10%"
    },
    grid: {
        width: '100%',
    },
    buttons: {
        margin: "13%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
        fontSize: "small"
    },
    Typography: {
        // left: theme.spacing(20),

    },
    header: {
        margin: theme.spacing(0, 4),
        marginBottom: "2%",

    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 150,
        width:'90%'
        // maxWidth: 250,
      },








}));
const currencies = [
    {
        value: 'Economy',
        label: 'Economy',
    },
    {
        value: 'Business',
        label: 'Business',
    },
    {
        value: 'First',
        label: 'First',
    },

];
const Adult = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];

const Child = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },

];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);





export default function MetaPreview(props) {
    var userDispatch = useUserDispatch();
    var userContextData = useUserState();
    var errorData = JSON.parse(localStorage.getItem('errorMsg'))
    var theme = useTheme();
    const classes = useStyles();
    var currentDate = new Date();
    var startDate = new Date();
    const [returnState, setReturnState] = useState(true);
    const [onwayhandleState, setOnwayhandleState] = useState(false);
    const [multihandleState, setMultihandleState] = useState(false);
    // const [onewayState, setOnewayState] = useState(true);
    const [spaceReturn, setSpaceReturn] = useState(false);
    const [addbutton, setAddbutton] = React.useState(false);
    const [multicitymap, setMulticitymap] = React.useState([{ value: null }]);
    const [value, setValue] = React.useState('return');
    const [cabin, setCabin] = React.useState('Economy');
    const [metaValue, setMetaValue] = React.useState([]);
    const [metaValue1, setMetaValue1] = React.useState('');

    var [airportList , setAirportList] = React.useState([])
    var [adult, setAdult] = React.useState('1');
    var [child, setChild] = React.useState('0');
    var [infant, setInfant] = React.useState('0');
    const [from, setFrom] = React.useState('');
    const [FromLabel, setFromLabel] = React.useState('');
    const [to, setTo] = React.useState('');
    const [ToLabel, setToLabel] = React.useState('');
    const [selectedDeparture, setSelectedDeparture] = React.useState(currentDate);
    const [loader, setIsLoader] = React.useState(false);
    // const [selectedDate1, handleReturnDate] = React.useState(currentDate);
    // const [fields, setFields] = React.useState([{ value: null }]);
    const [isLoading, setIsLoading] = React.useState(false)
    //
    const [segmentData, setSegmentData] = React.useState([])
    const [filterSegment, setFilterSegment] = React.useState([]);
    const [metaApi, setMetaApi] = React.useState([])
    const [stateSegment, setStateSegment] = React.useState({})
    var [FopList, setFopList] = React.useState([]);
    const [responsebutton, setResponsebutton] = React.useState(false);
    const [requestData, setRequestData] = React.useState('');
    const [responseData, setResponseData] = React.useState('');
    const [shopres, setShopres] = React.useState('');
    var [isPermanent, setPermanent] = React.useState(true);

    //
    const [journeyType, setJourneyType] = React.useState('return');
    const [selectedDate2, setSelectedDate2] = React.useState(new Date());
    const [selectedDate1, handleDateChange1] = React.useState(currentDate);
    const [selectedDate, handleDateChange] = React.useState(currentDate);
    const [FromMulticity, setFromMulticity] = React.useState('');
    const [FromLabelMulticity, setFromLabelMulticity] = React.useState({});
    const [ToMulticity, setToMulticity] = React.useState('');
    const [ToLabelMulticity, setToLabelMulticity] = React.useState('');
    const [details, setDetails] = React.useState(false)
    const [party , setParty ] = React.useState([])
    const [expanded, setExpanded] = React.useState(0);
   

    const [selectedDateMulticity, handleDateChangeMulticity] = React.useState(currentDate);
    var days;
    days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    var currentDate1 = new Date();
    const [handleMulticity, setHandleMulticity] = React.useState([
        {
            from: { value: '', label: '' },
            to: { value: '', label: '' },
            departure: new Date(),
        }

    ])
    const [errMessage, setErrMessage] = React.useState(false)
    const [multicityDate, handleMulticitydate] = React.useState(currentDate);
    var [InfantValue, setInfantValue] = React.useState([{
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    }]);

    const [ filter , setFilter] = React.useState({
        airlines : [],
        stops:[],
        stopOvers:[]
    })
 




    useEffect(() => {
        // superagent.post('https://api.pmt.dev.tltid.com/api/Meta/getMeta')
        superagent.post(config.apiUrl + "/api/Meta/getMeta")

           
            .set('Content-Type', 'application/json')
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .then(response => {
             
                if (response.body.status == true) {
                    setMetaApi(response.body.data)
                }
          if(response.body.message === 'ERMWMSG3' || response.body.message === 'ERMWMSG4'||response.body.message === 'ERMWMSG2'|| response.body.message === 'ERMWMSG5' ){
               

            alert(errorData[response.body.message])
                    signOut(userDispatch, props.history)
                }

            })

    }, []);

    useEffect(() => {
        if (metaApi.length)
            handleMetaValue();
    }, [metaApi]);



    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });


    useEffect(()=>{

        var filteredAirline = [];
        var filteredStop = [];
        var filteredStopOver = [];
        
        filteredAirline = filter.airlines.filter((val) => val.checked).map((val) => val.value);
        filteredStop = filter.stops.filter((val) => val.checked).map((val) => val.value);
        filteredStopOver = filter.stopOvers.filter((val) => val.checked).map((val) => val.value);
  
        var filterItinerary = segmentData.filter((itinerary) => {
             var airlineExist = false;
             var stopOverExist = false;
             var stopCountExist = false;
  
             for(let i=0;i<itinerary.groupSegment.length;i++){
                 for(let j=0;j<itinerary.groupSegment[i].length;j++){
                   // Airline filter
                   if(filteredAirline.includes(itinerary.groupSegment[i][j].MarketingCarrier.AirlineID)){
                      airlineExist = true;
                   }
                   if(itinerary.groupSegment[i].length>1&&j>0){
                       // Stop over filter
                       if(filteredStopOver.includes(itinerary.groupSegment[i][j].Departure.AirportCode)){
                          stopOverExist = true;
                       }
                   }
                   if(airlineExist && stopOverExist){
                       break;
                   }
                 }
                 // Stop count filter
                 if(filteredStop.includes(itinerary.groupSegment[i].length-1)){
                  stopCountExist = true;
                 }
  
                 if(itinerary.groupSegment[i].length == 1){
                  stopOverExist = true;
                 }
             }
             if(airlineExist && stopOverExist && stopCountExist){
                 return true;
             }
             else {
                 return false;
             }
        });
        setFilterSegment(filterItinerary);
        
      },[filter]);


    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }



    const ReturnHandling = () => {
        setReturnState(true)
        setSpaceReturn(false);
        setAddbutton(false);
        setJourneyType('return');

        setOnwayhandleState(false)
        setMultihandleState(false)
    }

    const OnewayHandling = () => {
        setReturnState(false);
        setSpaceReturn(true);
        setAddbutton(false);
        setValue('oneway');
        setOnwayhandleState(true)
        setMultihandleState(false)

    }

    const MulticityHandling = () => {
        setReturnState(false);
        setSpaceReturn(false);
        setAddbutton(true);
        setValue('multicity');
        setOnwayhandleState(false)
        setMultihandleState(true)
        // set dep2 min date from dep1
        if (handleMulticity.length == 1) {
            handleMulticity[0].departure = selectedDate;
        }
    }
    const responseButton = () => {
        setResponsebutton(!responsebutton);
    }
    const responseButtonClose = () => {
        setResponsebutton(false);
    }



    const handleChangeRadio = event => {
       
        setValue(event.target.value);
    };

    const handleInputMulticity = (index, event, givenType, newValue) => {
        const values = [...handleMulticity];

        if (givenType != undefined && givenType == 'departure') {
            values[index].departure = event;
        } else {
            if (event.target.name === "from") {
                setFromMulticity(newValue.id);
                values[index].from = newValue.value.split('|')[2];

                values[index].from = event.target.value;
            }
            if (event.target.name === "to") {
                values[index].to = event.target.value;
            }
        }


      

        setHandleMulticity(values);
    };







    const handleChangeInfant = event => {
        setInfant(event.target.value);
    }
    const handleAdult = event => {
        setAdult(event.target.value);
        var maxInfant = [];
        var maxInfantCount = event.target.value;
        var i = 0;
        while (i <= maxInfantCount) {
            maxInfant.push({
                value: i,
                label: i
            });
            i++;
        }
        setInfantValue(maxInfant);
    
    }
    const handleCabin = event => {
        setCabin(event.target.value);

    }

    const handleChangeChild = event => {
        setChild(event.target.value);
    }



    const handleAddFields = () => {
       
        const values = [...handleMulticity];
        var code = handleMulticity[handleMulticity.length - 1].to.value;
        var label = handleMulticity[handleMulticity.length - 1].to.label;
        values.push({ from: { value: code, label: label }, to: { value: '', label: '' }, departure: handleMulticity[handleMulticity.length - 1].departure });
        setHandleMulticity(values);
    };

    const handleRemoveFields = index => {
        var values = [...handleMulticity];
      
        if (index == 0 && values.length > 1) {
            values[index + 1].departure = values[index + 1].departure || selectedDate;
        }
        if (values.length <= 1) {
            values = {
                from: { value: '', label: '' },
                to: { value: '', label: '' },
                departure: selectedDate || new Date(),
            };
            var arrValues = [];
            arrValues.push(values);
            setHandleMulticity(arrValues);
            OnewayHandling();
        }
        else {
            values.splice(index, 1);
          
            setHandleMulticity(values);
        }
    };

    const selectedDepartureMulticity = date => {
        setSelectedDeparture(date);
        // const values = [...handleMulticity];
        //  const dates=[...selectedDeparture]


        //     values[index].departure = dates;

    };

    var someDate = new Date();
    var numberOfDaysToAdd = 2;
    const setMulticityValues = (value, name, index) => {

        if ((name == 'from' || name == 'to') && typeof value == 'object') {

            handleMulticity[index][name]['value'] = value.id;
            handleMulticity[index][name]['label'] = value.value.split('|')[2] != '' ?  value.value.split('|')[2] + ' ' + '(' + value.value.split('|')[0] + ')' :value.value.split('|')[1].split(' ')[0]+ ' ' + '(' + value.value.split('|')[0] + ')';
        }
        else {
            handleMulticity[index][name]['label'] = value;
        
            
        }

        setHandleMulticity([...handleMulticity]);



    }
    // const setMulticityValues = (value, name, index) => {

    //     if (( name == 'to') && typeof value == 'object') {

    //         handleMulticity[index][name]['value'] = value.id;

    //         handleMulticity[index][name]['label'] = value.value.split('|')[2] != '' ?  value.value.split('|')[2] + ' ' + '(' + value.value.split('|')[0] + ')' :value.value.split('|')[1].split(' ')[0]+ ' ' + '(' + value.value.split('|')[0] + ')';
   
    //     }
    //     else {
    //         handleMulticity[index][name]['label'] = value;
        
            
    //     }

    //     setHandleMulticity([...handleMulticity]);



    // }
    const setMulticityValuesTo = (value, name, index) => {

        handleMulticity[index][name]['value'] = value.id;
        handleMulticity[index][name]['label'] = value.value.split('|')[2] != '' ?  value.value.split('|')[2] + ' ' + '(' + value.value.split('|')[0] + ')' :value.value.split('|')[1].split(' ')[0]+ ' ' + '(' + value.value.split('|')[0] + ')';

        
        setHandleMulticity([...handleMulticity]);
        
    }


    const handleDateChange2 = date => {
        setSelectedDate2(date);
    };

    const handleMetaValue = (event) => {
        var meta;

        if (event != undefined) {
           
            meta = event.target.value
          

        } else {
            meta = metaApi[0]
        }
      
        setMetaValue(meta)


        // setMetaValue1(event.target.value1)
    }

    const handleDetailsState = (e, index) => {

        // var offersId1 = CommonFunction.elementSelector(e.target, 'Button').getAttribute('value');
      
        // if (offersId1) {

        //     setDetails(!details)
        // }
        if (filterSegment[index].isDetail == undefined) {
            filterSegment[index].isDetail = true;
        }
        else {
            filterSegment[index].isDetail = !filterSegment[index].isDetail;
        }
        setFilterSegment([...filterSegment]);
    }
    const faresHandleLink = e => {

        e.preventDefault();
        var utmString = ''
        if (metaValue.utm_string != '') {
            utmString = '/' + metaValue.utm_string;
        }
        var offersId = CommonFunction.elementSelector(e.target, 'Button').getAttribute('value');
      
        window.open(metaValue.b2c_url + '/deeplink/' + metaValue.rsource + "/" + shopres + '/' + offersId + utmString)
    }



    const formHandling = e => {
        e.preventDefault();

        var isValidation = true;
        if (from.length != 3) {
            alert(' From and TO should be three characters')
            
            isValidation = false;
        }

        if (to.length != 3) {
            alert(' From and TO should be three characters')
            // setValidateTo(true)
            isValidation = false;
        }
        if (parseInt(adult) + parseInt(child) + parseInt(infant) > 9) {
            alert(' the passengers should not more than 9')
            // setValidateForm(true)
            isValidation = false;
        }
      
       
        if(isValidation){
            setIsLoading(false)
        var psgcount = Number(adult) + Number(child) + Number(infant);


        var reqData = {
            "AirShoppingRQ": {
                "CoreQuery": {
                    "OriginDestinations": {
                        "OriginDestination": [
                            {
                                "Departure": {
                                    "AirportCode": from,
                                    "Date": selectedDate.toISOString().slice(0, 10)
                                },
                                "Arrival": {
                                    "AirportCode": to
                                }
                            },
                        ],
                    },
                },
                "DataLists": {
                    "PassengerList": {
                        "Passenger": [

                            // {
                            //     "PTC": "ADT",
                            //     "PassengerID": "T1"
                            // }

                        ],


                    }
                },
                "MetaData": {
                    "Currency": metaValue.currency,
                    "FareGrouping": "Deal"

                },
                "Preference": {

                    "AlternateDays": "0",
                    "Cabin": cabin,
                    "DirectFlight": "",
                    "FareType": "BOTH",
                    "Refundable": "",
                    "TripType": value

                }
            }

        }



        if (returnState) {

          

            reqData.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push({

                "Departure": {
                    "AirportCode": to,
                    "Date": selectedDate1.toISOString().slice(0, 10)
                },
                "Arrival": {
                    "AirportCode": from
                },

            });
        }

        if (multihandleState) {
           
            for (var i = 0; i < handleMulticity.length; i++) {
                reqData.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push({
                    "Departure": {
                        "AirportCode": handleMulticity[i].from.value,
                        "Date": handleMulticity[i].departure.toISOString().slice(0, 10)
                    },
                    "Arrival": {
                        "AirportCode": handleMulticity[i].to.value
                    }
                });
            }


        }



        //for declaring string to number 
        adult = Number(adult)
        child = Number(child)
        infant = Number(infant)
        for (var i = 1; i <= psgcount; i++) {

            if (i <= adult) {
                reqData.AirShoppingRQ.DataLists.PassengerList.Passenger.push({
                    "PTC": "ADT",
                    "PassengerID": "T" + i
                })
            }

            else if (i <= adult + child) {
                reqData.AirShoppingRQ.DataLists.PassengerList.Passenger.push({
                    "PTC": "CHD",
                    "PassengerID": "T" + i
                })
            }

            else if (i <= adult + child + infant) {
                reqData.AirShoppingRQ.DataLists.PassengerList.Passenger.push({
                    "PTC": "INF",
                    "PassengerID": "T" + i
                })
            }

        }



        var request = {
            "apiKey": metaValue.api_key,
            "apiUrl": metaValue.api_url,
            "reqData": reqData

        }

        setErrMessage(false);
        setIsLoader(true)
        superagent.post(config.apiUrl + "/api/Meta/preview")
            .send(request)
            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
            .set({ 'login-key': localStorage.getItem('token') })
            .timeout({
                deadline: 50000
            })
            .then(response => {
                response = JSON.parse(response.text);
                if (response.AirShoppingRS.OffersGroup != undefined && Object.keys(response).length != 0) {
                    setRequestData(JSON.stringify(request))
                    setResponseData(JSON.stringify(response))
                    
                    var FlightSegment = response.AirShoppingRS.DataLists.FlightSegmentList.FlightSegment;
                    var FlightSegmentData = {};
                   
                    FlightSegment.map((val , ind) => {
                        FlightSegmentData[val.SegmentKey] = val
                       
                    })
                    setStateSegment(FlightSegmentData);

                    var FlightList = response.AirShoppingRS.DataLists.FlightList.Flight;

                    var FlightData = {};

                    FlightList.map((val) => {
                        FlightData[val.FlightKey] = val
                    })
                    var priceClassList = response.AirShoppingRS.DataLists.PriceClassList.PriceClass;
                    var priceClassData = {}
                    priceClassList.map((val) => {
                        priceClassData[val.PriceClassID] = val;
                    })
                    var responseOffer = response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer;
                    var groupSegment = {}
                    var itinerary = [];
                    responseOffer.map(function (trip, grpKey) {
                        var priceClassIternary = {};
                        var flightRef = trip.OfferItem[0].Service;
                        var priceRefs = trip.OfferItem[0].FareComponent[0].PriceClassRef;
                        priceClassIternary = priceClassData[priceRefs];
                        var maxStop= 0;
                        var flight = [];
                        var price = [];
                        flightRef.map((key) => {
                            var segmentData = FlightData[key.FlightRefs].SegmentReferences;
                            segmentData = segmentData.split(' ');
                            var segment = [];
                            segmentData.map((seg) => {
                                segment.push(FlightSegmentData[seg]);
                            })
                             
                            flight.push(segment);
                        })
                    
                        response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer[grpKey]['groupSegment'] = flight;
                        response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer[grpKey]['priceClass'] = priceClassIternary;
                        
                    })
                        


                    setFopList(response.AirShoppingRS.DataLists.FopList)
                    setParty(response.AirShoppingRS.Party.Sender.TravelAgencySender)
                    var data = response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer;
                    setShopres(response.AirShoppingRS.ShoppingResponseId)
                    // setIsLoading(false)
                    setSegmentData(data)
                    setIsLoader(false)
                    // setFade(false)
                    setIsLoading(true)
                   
                    response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer=data;
                    handleFilter(response)
                }
                else if (response.AirShoppingRS.Errors.value = 'No Results Found') {
                    setIsLoader(false)
                    setErrMessage(true);
                }

                else if (response.message === 'ERMWMSG3' || response.message === 'ERMWMSG4'||response.message === 'ERMWMSG2'|| response.message === 'ERMWMSG5' ) {
                    alert(errorData[response.message])
                    signOut(userDispatch, props.history)
                }


            }, err => {
                setIsLoading(false)
            })



        }
    }

    // const handleFromValue = (e) =>{

       
    //         superagent.post(config.apiUrl+'/api/Flight/getAirport')
    //         .send(e.target.value)
    //         .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
    //         .set({ 'login-key': localStorage.getItem('token') })
    //         .then(res => {

    //         })
        
        

    // }

    const handleFilter = response =>{
        var FlightSegment = response.AirShoppingRS.DataLists.FlightSegmentList.FlightSegment;
        var airlineArraySegment = [];
        var airlineFilter = []
        FlightSegment.map((val ,ind)=>{
        if(!airlineArraySegment.includes(val.MarketingCarrier.AirlineID)){
            airlineArraySegment.push(val.MarketingCarrier.AirlineID )
           var airlineValues = {
                    label :  val.MarketingCarrier.Name+'('+val.MarketingCarrier.AirlineID+')',
                    value: val.MarketingCarrier.AirlineID,
                    checked:true
           }
           airlineFilter.push(airlineValues);
           
        }
    });

    var responseOffer = response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer;

    var maxCount = 0;
    var stopOverAirport= []; 
    var stopOverFilter = [];
    responseOffer.map((segment ,key)=>{
        response.AirShoppingRS.OffersGroup.AirlineOffers[0].Offer[key]['groupSegment'].map((value , inx)=>{
                if(maxCount < value.length ){
                    maxCount = value.length
                }
                if(value.length > 1){
                    for(let i=1; i<value.length;i++){
                        if (!stopOverAirport.includes(value[i].Departure.AirportCode)) {
                            stopOverAirport.push(value[i].Departure.AirportCode);
                            var stopValues = {
                                label : value[i].Departure.AirportName.replace(' International Airport', '')+ '('+ value[i].Departure.AirportCode +')',
                                value: value[i].Departure.AirportCode,
                                checked:true
                            }
                            stopOverFilter.push(stopValues);
                          }
                    }
                }
        })
    })


    var stopValues = [];
    for (var i = 0; i < maxCount; i++) {
        var stopNumber = {
            label : '',
            value:'',
            checked:true
        };
        stopNumber.value = i;
        if(i == 0){
            stopNumber.label='Non-Stop';
        }
        else if(i==1){
            stopNumber.label='1 Stop';
        }
        else{
            stopNumber.label= i+' Stops';
        }
        stopValues.push(stopNumber);
    }

    airlineFilter.unshift({
            label : 'Select All',
            value:'selectall',
            checked:true
    });
    stopOverFilter.unshift({
        label : 'Select All',
        value:'selectall',
        checked:true
    });
    stopValues.unshift({
        label : 'Select All',
        value:'selectall',
        checked:true
    });

    setFilter({...filter,airlines:airlineFilter,stopOvers:stopOverFilter,stops:stopValues});

  
    }





        const handleChangeFilter = event => {
            
            var filterName = event.target.name;
            var tempFilter = filter[filterName];
             
             if(event.target.value[event.target.value.length-1]==0){
                tempFilter[0].checked?(tempFilter.map((val) => val.checked = false)):(tempFilter.map((val) => val.checked = true));
             }
             else{
                     tempFilter[event.target.value[event.target.value.length-1]].checked = !tempFilter[event.target.value[event.target.value.length-1]].checked;
             }

             var isAllSelect = true;
             for(let i=1;i<tempFilter.length;i++){
                 if(!tempFilter[i].checked){
                    isAllSelect = false;
                    break;
                   }
             }
             tempFilter[0].checked = isAllSelect;
             setFilter({...filter,[filterName]:tempFilter});  


            }
 
             
        




    const handleChangePanel = inq => (event,newExpanded) => {
   
        setExpanded(newExpanded ? inq : false);
    }










    return (
        <React.Fragment>
            <div className={classes.header}>
                <Typography className={classes.Typography} variant="h3" component="h2"  >
                    Flight Meta
      </Typography>
            </div>
            <Container className={classes.container} >

                <FormControl component="fieldset">
                    <RadioGroup aria-label="position" name="position" value={value} onChange={handleChangeRadio} row>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                onClick={ReturnHandling}
                                value="return"
                                id="returnForm"
                                control={<Radio color="primary" />}
                                label="RETURN"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            <FormControlLabel
                                value="oneway"
                                onClick={OnewayHandling}
                                id="form2"
                                control={<Radio color="primary" />}
                                label="ONEWAY"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item sm={4} xs={6} lg={4} xl={4}>
                            {/* <Tooltip title="coming soon"> */}
                            <FormControlLabel
                                value="multicity"
                                onClick={MulticityHandling}
                                // disabled
                                control={<Radio color="primary" />}
                                label="MULTICITY"
                                // onMouseOver="Coming Soon"
                                labelPlacement="start"
                            />
                            {/* </Tooltip> */}
                        </Grid>


                        <form
                            onSubmit={formHandling}
                            noValidate autoComplete="off">
                            <div className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={3} xs={6} lg={3} xl={3} >

                                 


                                            <Autocomplete
                                                id="auto-complete"
                                                options={airportList}
                                                getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')': option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}

                                                // getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}
                                                inputValue={FromLabel}

                                                className={classes.textField}
                                                onChange={(event, newValue) => {
                                                    if (newValue != null) {
                                                        
                                                        setFrom(newValue.id);
                                                        setFromLabel(newValue.value.split('|')[2] !=''? newValue.value.split('|')[2]:newValue.value.split('|')[1].split(' ')[0]);
                                                    }
                                                    else {
                                                        setFrom('');
                                                    }
                                                }}
                                                onBlur={()=>{
                                                    setAirportList([]);
                                                }}
                                                onInputChange={(event, value) => {
                                                    setFromLabel(value);
                                                    if(value.length >= 3){
                                                    superagent.post(config.apiUrl+'/api/Flight/getAirport')
                                                        .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                        .set({ 'login-key': localStorage.getItem('token') })
                                                        .send({
                                                            "code": value
                                                        })
                                                        .then(res=>{
                                                           
                                                            setAirportList(res.body)
                                                        })
                                                    }
                                                }}
                                                renderInput={params => {
                                                    return (
                                                        <TextField {...params} label="From" fullWidth />
                                                    )
                                                }}
                                            /> 
                                        </Grid>

                                        <Grid item sm={3} xs={6} lg={3} xl={3} >
                                            <Autocomplete
                                                id="auto-complete"
                                                options={airportList}
                                                getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')': option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}

                                                // getOptionLabel={option => option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')'}
                                                inputValue={ToLabel}
                                                margin="normal"
                                                className={classes.textField}
                                                onChange={(event, newValue) => {
                                                    if (newValue != null) {
                                                        setTo(newValue.id);
                                                        setToLabel(newValue.value.split('|')[2] !=''? newValue.value.split('|')[2]:newValue.value.split('|')[1].split(' ')[0]);
                                                        handleMulticity[0].from.label = newValue.value.split('|')[2] + ' ' + '(' + newValue.value.split('|')[0] + ')'
                                                        handleMulticity[0].from.value = newValue.id
                                                    }
                                                    else {
                                                        setTo('');
                                                    }
                                                }}
                                                onBlur={()=>{
                                                    setAirportList([]);
                                                }}
                                                onInputChange={(event, value) => {
                                                    setToLabel(value);
                                                    if(value.length >= 3){
                                                        superagent.post(config.apiUrl+'/api/Flight/getAirport')
                                                            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                            .set({ 'login-key': localStorage.getItem('token') })
                                                            .send({
                                                                "code": value
                                                            })
                                                            .then(res=>{
                                                               
                                                                setAirportList(res.body)
                                                            })
                                                        }
                                                    
                                                }}
                                                renderInput={params => {
                                                    return (
                                                        <TextField {...params} label="To" fullWidth />
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={3} xs={6} lg={3} xl={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    label="Departure"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    format="dd MMM yyyy"
                                                    animateYearScrolling
                                                    className={classes.textField}
                                                    minDate={startDate}
                                                    utcOffset={0}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        {returnState ?
                                            <Grid item sm={3} xs={6} lg={3} xl={3}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label="Return"
                                                        value={selectedDate1}
                                                        onChange={handleDateChange1}
                                                        format="dd MMM yyyy"
                                                        animateYearScrolling
                                                        className={classes.textField}
                                                        minDate={selectedDate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            : null
                                        }

                                        {spaceReturn ?
                                            <Grid item sm={3} xs={6} lg={3} xl={3} style={{ paddingRight: "18%" }}>
                                                <div className={classes.textField}  >
                                                    <span>&nbsp;&nbsp; &nbsp; </span>
                                                </div>
                                            </Grid>
                                            : null
                                        }

                                    </Grid>
                                    {addbutton ?
                                        <div className={classes.grid}>

                                            {handleMulticity.map((inputField, index) => (
                                             
                                               
                                                <div key={'${inputField}~${index}'}>
                                                    <Grid container spacing={2}>


                                                        {/* <Grid item xs={3}>
                                                            <TextField
                                                                id="standard-search1"
                                                                label="From"
                                                                name="from"
                                                                type="search"
                                                                onChange={event => handleInputMulticity(index, event)}

                                                                className={classes.textField}
                                                                margin="normal"
                                                                value={inputField.from}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={3}>

                                                            <TextField
                                                                id="standard-search2"
                                                                label="To"
                                                                name="to"
                                                                type="search"
                                                                onChange={event => handleInputMulticity(index, event)}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                value={inputField.to}

                                                            />
                                                        </Grid> */}
                                                        <Grid item sm={3} xs={6} lg={3} xl={3} >
                                                            <Autocomplete
                                                                id="auto-complete"
                                                                options={airportList}
                                                                getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')': option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}
                                                                inputValue={inputField.from.label}

                                                                name="from"
                                                                dataIndex={index}
                                                                margin="normal"
                                                                className={classes.textField}
                                                                onChange={(event, newValue) => {
                                                                   
                                                                    
                                                                    if (newValue != null) {
                                                                        
                                                                        // handleInputMulticity(event, newValue)
                                                                        setMulticityValues(newValue, 'from', index);
                                                                    }
                                                                    else {
                                                                        setFromMulticity('');
                                                                    }
                                                                }}
                                                                onBlur={()=>{
                                                                    setAirportList([]);
                                                                }}
                                                                onInputChange={(event, value) => {
                                                                    
                                                                    if(value.length!=0){                                                                     
                                                                    setMulticityValues(value, 'from', index);
                                                                    if(value.length >= 3){
                                                                        superagent.post(config.apiUrl+'/api/Flight/getAirport')
                                                                            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                                            .set({ 'login-key': localStorage.getItem('token') })
                                                                            .send({
                                                                                "code": value
                                                                            })
                                                                            .then(res=>{
                                                                                
                                                                                setAirportList(res.body)
                                                                            })
                                                                        }
                                                                        
                                                                    }
                                                                        
                                                                    }}
                                                                    
                                                                    
                                                                    // value = {handleMulticity.length == 1 || index == 0 ? FromMulticity: (handleMulticity[index - 1] != undefined ? handleMulticity[index - 1].to.value : handleMulticity[index - 1].from.label) }
                                                                renderInput={params => {
                                                                    return (
                                                                        <TextField {...params} label="From" fullWidth />
                                                                        )
                                                                    }}
                                                                    />
                                                                    

                                                        </Grid>
                                                        <Grid item sm={3} xs={6} lg={3} xl={3} >



                                                            <Autocomplete
                                                                id="auto-complete"
                                                                options={airportList}
                                                                getOptionLabel={isPermanent ? option => option.value.split('|')[2] != '' ? option.value.split('|')[2] + ' ' + '(' + option.value.split('|')[0] + ')': option.value.split('|')[1].split(' ')[0] + ' ' + '(' + option.value.split('|')[0] + ')' : option => option.value.split('|')[0]}

                                                                inputValue={inputField.to.label}
                                                                margin="normal"
                                                                name="to"
                                                                dataIndex={index}
                                                                className={classes.textField}
                                                                onChange={(event, newValue) => {
                                                                    if (newValue != null) {
                                                                        setMulticityValues(newValue, 'to', index);
                                                                    }
                                                                    else {
                                                                        setToMulticity('');
                                                                    }
                                                                }}
                                                                onBlur={()=>{
                                                                    setAirportList([]);
                                                                }}
                                                                onInputChange={(event, value) => {
                                                                    // setToLabelMulticity(value || inputField.to);
                                                                    setMulticityValues(value, 'to', index);
                                                                    if(value.length >= 3){
                                                                        superagent.post(config.apiUrl+'/api/Flight/getAirport')
                                                                            .set('Authorization', '98dce83da57b0395e163467c9dae521b1544094570')
                                                                            .set({ 'login-key': localStorage.getItem('token') })
                                                                            .send({
                                                                                "code": value
                                                                            })
                                                                            .then(res=>{
                                                                               
                                                                                setAirportList(res.body)
                                                                            })
                                                                        }

                                                                  
                                                                }}
                                                              
                                                                renderInput={params => {
                                                                    return (
                                                                        <TextField {...params} label="To" fullWidth />
                                                                    )
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} >

                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <DatePicker
                                                                    autoOk



                                                                    label="Departure"
                                                                    value={inputField.departure}
                                                                    //value={someDate.setDate(someDate.getDate() + numberOfDaysToAdd)}
                                                                    // onChange={handleMulticitydate}
                                                                    format="dd MMM yyyy"
                                                                    animateYearScrolling
                                                                    className={classes.textField}
                                                                    onChange={(e) => handleInputMulticity(index, e, 'departure')}
                                                                    // onChange={setMulticityValuesDeparture(value,'departure',index)}
                                                                    // minDate={selectedDate}
                                                                    minDate={handleMulticity.length == 1 || index == 0 ? selectedDate : (handleMulticity[index - 1] != undefined ? handleMulticity[index - 1].departure : startDate)}
                                                                    // shouldDisableDate={disablePrevDates(startDate)}
                                                                    utcOffset={0}

                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>

                                                        {handleMulticity.length ?
                                                            <Grid item xs={1}>

                                                                <DeleteIcon className={classes.buttons}
                                                                    onClick={() => handleRemoveFields(index)}
                                                                />


                                                            </Grid>
                                                            : null}
                                                        {handleMulticity.length < 4 && handleMulticity.length - 1 == index ?

                                                            <Grid item xs={1}>
                                                                <AddCircleIcon className={classes.buttons}
                                                                    onClick={() => handleAddFields()}
                                                                />
                                                            </Grid>


                                                            : <div>
                                                                <span>&nbsp;&nbsp;</span>
                                                            </div>}
                                                        <Grid item xs={1}>
                                                            <div>
                                                                <span>&nbsp;&nbsp;</span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))




                                            }


                                        </div>

                                        : null}









                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-cabin"
                                            select
                                            label="Cabin"
                                            name="cabin"
                                            className={classes.textField}
                                            value={cabin}
                                            onChange={handleCabin}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                            {currencies.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={3} xs={6} lg={3} xl={3}>

                                        <TextField
                                            id="standard-select-adult"
                                            select
                                            label="Adult"
                                            className={classes.textField}
                                            name="adult"
                                            value={adult}
                                            onChange={handleAdult}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                            {Adult.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}.
                                                    </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-child"
                                            select
                                            name="Childfield"
                                            label="Child"
                                            className={classes.textField}
                                            value={child}
                                            onChange={handleChangeChild}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                            {Child.map(option => (
                                                <MenuItem key={option.value} value={option.value}>

                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-Infant"
                                            select
                                            name="Infant"
                                            className={classes.textField}
                                            value={infant}
                                            label="Infant"
                                            onChange={handleChangeInfant}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                            {InfantValue.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={3} xs={6} lg={3} xl={3}>
                                        <TextField
                                            id="standard-select-MetaValue"
                                            select
                                            label="Meta API"
                                            name="MetaValue"
                                            className={classes.textField}
                                            value={metaValue}

                                            onChange={handleMetaValue}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                        >
                                        
                                            {metaApi.map((key, i) => (

                                                <MenuItem key={key} value={key}  >
                                                    {key.meta_name + '/' + key.user_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3} xs={6} lg={3} xl={3} style={{ paddingTop: "2%" }}>
                                        {loader ? (
                                            <CircularProgress size={26} className={classes.loginLoader} />
                                        ) : (
                                                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                    search
                                                 </Button>)
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </RadioGroup>
                </FormControl>
            </Container>

            {/* 
loading data  */}

            {errMessage ? (
                <center>  <Typography className="container" color="Secondary" variant="h3" >
                    No Flights Found
                </Typography></center>
            ) : ('')}
            {
                isLoading ? (
                    <Grid>
                        <div>
                            &nbsp;
                 </div>

                        {/* <Grid> */}
                        {(config.accessReqRes.includes(userContextData.data[0]['role_id']) ? (
                            <div>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={responseButton}
                                    className={classes.LinkButton}
                                >
                                    View Request/Response
    </Link>


                                {responsebutton ?
                                    <div className="container" >
                                        <Grid Container >
                                            <Grid item xl={5}>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Request"
                                                    multiline
                                                    rows="4"
                                                    value={requestData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xl={2} ></Grid>
                                            <Grid item xl={5} >
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Response"
                                                    multiline
                                                    rows="4"
                                                    value={responseData}
                                                    // className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xl={5} >
                                                <Typography> ShoppingResponseId : {shopres}</Typography>
                                                <Typography> Name : {party.Name}</Typography>
                                                <Typography> IATA_Number : {party.IATA_Number}</Typography>
                                                <Typography> AgencyID : {party.AgencyID}</Typography>



                                                <Typography> APIUrl : {metaValue.api_url}</Typography>
                                                <Typography> AUTHKey : {metaValue.api_key}</Typography>
                                                <Typography> B2CUrl : {metaValue.b2c_url}</Typography>
                                                <Typography> Rsource : {metaValue.rsource}</Typography>
                                                <Typography> OfferCount : {filterSegment.length}</Typography>





                                                

                                                </Grid>
                                        </Grid>

                                    </div> : null}
                            </div>
                        ) : null)}


                        <div>
                            &nbsp;
                         </div>

                         <Grid Container spacing={1}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panecl1a-content"
                                    id="Filter"
                                >
                                    <Typography className={classes.heading}>Filters</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>

                                    <Grid container  direction = "row" spacing={1}>
                                    <Grid item sm={4}>
                                               
                                             <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-checkbox-label">Select Airlines</InputLabel>
                                            <Select
                                            id="FilterAirline"
                                            name = 'airlines'
                                            multiple
                                            value={filter.airlines}
                                            onChange={handleChangeFilter}
                                            input={<BootstrapInput />}
                                            renderValue={() => {
                                                return filter.airlines.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Airline';
                                                }}
                                            MenuProps={MenuProps}
                                            variant="outlined"
                                            fullWidth
                                            >
                                            {filter.airlines.map((dataAirline,i) => {
                                                return(
                                                <MenuItem key={dataAirline.value} value={i}>
                                                <Checkbox checked={dataAirline.checked} value={dataAirline.value}/>
                                                <ListItemText primary={dataAirline.label} />
                                                
                                                </MenuItem>
                                                )
                                            })}
                                            </Select>
                                           </FormControl>
                                            </Grid>
                                            
                                            <Grid item sm={4}>
                                               
                                               <FormControl className={classes.formControl}>
                                              <InputLabel id="demo-mutiple-checkbox-label">Select Stops</InputLabel>
                                              <Select
                                              id="FilterStops"
                                              name='stops'
                                              multiple
                                              value={filter.stops}
                                              onChange={handleChangeFilter}
                                              variant="outlined"
                                              input={<BootstrapInput />}
                                            //   renderValue={selected => selected.join(', ')}
                                            renderValue={() => {
                                                return filter.stops.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select Stops';
                                                }}
                                              MenuProps={MenuProps}
                                              fullWidth
                                              >
                                              {filter.stops.map((dataStops,i) => {
                                                  return(
                                                    
                                                  <MenuItem key={dataStops.value} value={i}>
                                                  <Checkbox checked={dataStops.checked} value={dataStops.value} />
                                                  <ListItemText primary={dataStops.label} />
                                                  </MenuItem>
                                                
                                                  )
                                              })}
                                              </Select>
                                             </FormControl>
                                              </Grid>
                                              <Grid item sm={4}>
                                               
                                               <FormControl className={classes.formControl}>
                                              <InputLabel id="demo-mutiple-checkbox-label">Select Stop Overs</InputLabel>
                                              <Select
                                              id="FilterStopOvers"
                                              name='stopOvers'
                                              multiple
                                              value={filter.stopOvers}
                                              variant="outlined"
                                           
                                              onChange={handleChangeFilter}
                                              input={<BootstrapInput />}
                                            //   renderValue={selected => selected.join(', ')}
                                            renderValue={() => {
                                                return filter.stopOvers.slice(1).filter((val) => val.checked).map((val) => val.value).join(', ') || 'Select stop Flights';
                                                }}
                                              MenuProps={MenuProps}
                                              fullWidth
                                              >
                                              {filter.stopOvers.map((dataStopsOver,i) => {
                                                  return(
                                                 
                                                  <MenuItem key={dataStopsOver.value} value={i}>
                                                  <Checkbox checked={dataStopsOver.checked} value={dataStopsOver.value}/>
                                                  <ListItemText primary={dataStopsOver.label} />
                                                  </MenuItem>
                                                
                                                  )
                                              })}
                                              </Select>
                                             </FormControl>
                                              </Grid>
                                          
                                        
                                                    
                      
                            
                                                   
                                                    
                                        
                                    
                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                </Grid>





                        <div>
                            &nbsp;
                         </div>
                     

                        {filterSegment.map((iternary, index) => {
                            //   if(metaValue.utmstring != ''){
                            //             metaValue.utmstring = '/'+metaValue.utmstring
                            //         }
                            var groupSegment = iternary.groupSegment;
                            // var currencyName  ='';
                            // var amount = '';
                            var offId = '';
                            var FareType = '';
                            var owner = '';
                            var currencyName = iternary.EquivCurrencyCode;
                            var amount = iternary.TotalPrice.EquivCurrencyPrice;
                            offId += iternary.OfferID
                            FareType += iternary.FareType;
                            owner += iternary.Owner;

                            return (
                                <Grid Container spacing={1}>



                                    <Card spacing={2} >

                                        <Grid container style={{ padding: 15 }}>

                                            <Grid item xs={1} style={{textAlignLast: "center"}}>
                                                {index + 1}
                                            </Grid>

                                            <Grid item xs={8}>




                                                {
                                                    groupSegment.map((segmentRef, inx) => {
                                                        var numberStop = '';
                                                        var stopFlight = '';
                                                        var tempArray = [];
                                                        var airLinrStr = '';
                                                        var airlineSeg = '';
                                                        return (



                                                            <Grid
                                                                container
                                                                direction="row"
                                                            >
                                                                {
                                                                    segmentRef.map((stopFlig, stKey) => {
                                                                        if (stKey > 0) {
                                                                            stopFlight += segmentRef[stKey].Departure.AirportCode;
                                                                            
                                                                            if(stKey < segmentRef.length - 1){
                                                                                stopFlight += ', '
                                                                            }
                                                                        }
                                                                        //  if (stKey < segmentRef.length-1){
                                                                        //     stopFlight+='/';
                                                                        // }
                                                                    })
                                                                }

                                                                <Grid item sm={2} xs={6}>
                                                                    {segmentRef.map((arr, segInd) => {
                                                                        if (!tempArray.includes(segmentRef[segInd].MarketingCarrier.AirlineID)) {
                                                                            tempArray.push(segmentRef[segInd].MarketingCarrier.AirlineID);
                                                                            return (
                                                                                <img src={config.iconUrl + segmentRef[segInd].MarketingCarrier.AirlineID + ".png"}  onError={(e)=>{e.target.onerror = null; e.target.src=config.iconUrl+'plane.png';e.target.style.height='25px';e.target.style.width='25px';}} alt={segmentRef[segInd].MarketingCarrier.AirlineID} height="30px" width="30px" />
                                                                            )

                                                                        }
                                                                    })}
                                                                    <Typography variant="body2" > {airLinrStr}</Typography>
                                                                </Grid>
                                                                <Grid item sm={3} xs={6}>
                                                                    <Typography  variant="body2" style={{marginBottom:"5px"}}> {segmentRef[0].Departure.Date}{' '}{segmentRef[0].Departure.Time}</Typography>
                                                                    <Typography variant="body2" style={{fontWeight:"bold",marginBottom:"5px"}} >  {segmentRef[0].Departure.AirportCode}</Typography>
                                                                </Grid>
                                                                <Grid item sm={3} xs={6} style={{overflowWrap: 'break-word'}}>
                                                                    {/* {segmentRef.map((keyStop, inxKey) => {
                                                                     if (segmentRef.length == 1) {
                                                                        numberStop += 'Non Stop'
                                                                    } 
                                                                    
                                                                    // if(segmentRef.length == 2) {
                                                                    //     numberStop += '1 Stop'
                                                                    // }
                                                                  else if (inxKey < segmentRef.length - 1) {
                                                                        numberStop += Number(segmentRef.length - 1) + ' stop';
                                                                    }
                                                                    })} */}
                                                                    <Typography  variant="body2" style={{marginBottom:"5px"}}> {segmentRef.length == 1 ? 'Non Stop' : (segmentRef.length == 2 ? '1 Stop' :Number(segmentRef.length - 1)+ ' Stops') }</Typography>
                                                                    <Typography variant="body2"   style={{fontWeight:"bold",marginBottom:"5px"}}>{stopFlight}</Typography>
                                                                </Grid>













                                                                <Grid item sm={3} xs={6}>
                                                                    <Typography variant="body2" style={{marginBottom:"5px"}}> {segmentRef[segmentRef.length - 1].Arrival.Date}{' '}{segmentRef[segmentRef.length - 1].Arrival.Time}</Typography>
                                                                    <Typography variant="body2"   style={{fontWeight:"bold",marginBottom:"5px"}}>  {segmentRef[segmentRef.length - 1].Arrival.AirportCode}</Typography>

                                                                </Grid>

                                                                <Grid item sm={12} xs={12}>
                                                                    {
                                                                        segmentRef.map((segArr, SegInd) => {
                                                                            airlineSeg += segmentRef[SegInd].MarketingCarrier.AirlineID + segmentRef[SegInd].MarketingCarrier.FlightNumber + '-' + segmentRef[SegInd].Departure.AirportCode + segmentRef[SegInd].Arrival.AirportCode;
                                                                            if (SegInd < segmentRef.length - 1)
                                                                                airlineSeg += '/';
                                                                        })
                                                                    }
                                                                    <Typography  variant="body2" style={{fontStyle:"italic"}} > {airlineSeg}</Typography>

                                                                </Grid>



                                                                {/* <Divider color="primary" ></Divider> */}
                                                            </Grid>


                                                        )
                                                    })
                                                }


                                            </Grid>
                                            <Grid item xs={3}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                >

                                                    <Grid item xs={6}>

                                                        <Button variant="contained" color="primary" style={{ marginBottom: "49%" }} value={iternary.OfferID} onClick={faresHandleLink} >{currencyName}{' '}{amount.toFixed(2)}</Button>
                                                        {/* <a href= {'"'+metaValue.b2curl + '/deeplink/' + metaValue.rsource + '/' + shopres + '/' + offId + metaValue.utmstring+'"'} target="blank"> */}
                                                        {/* <Button
                                                variant="contained" color="primary" style={{ marginBottom: "49%" }}  value={ iternary.OfferID}>{currencyName}{' '}{amount.toFixed(2)}
                                                </Button> */}

                                                        {/* </a> */}


                                                        <Button value={iternary.OfferID}
                                                            // onClick={() => setDetails(!details)}
                                                            onClick={(e)=> handleDetailsState(e, index)}
                                                        > Show Details
                                                        
                                                        
                                                        </Button>
                                                        
                                                    </Grid>



                                                </Grid>

                                            </Grid>



                                            {
                                                iternary.isDetail != undefined && iternary.isDetail ? (
                                                    <Grid container style={{ padding: 15 }}>

                                                        {isPermanent ? <Grid item xs={1}>
                                                            <Typography style={{ fontWeight: "bold", paddingBottom: "15%" }}>Offer</Typography>
                                                        </Grid> : ''}

                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bold", paddingBottom: "15%", textAlignLast: 'center' }}>Flight</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bold", paddingBottom: "15%", textAlignLast: 'center' }}>Fare</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography style={{ fontWeight: "bold", paddingBottom: "15%", textAlignLast: 'center' }}>Supplier</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}  >
                                                            <Typography style={{ fontWeight: "bold", paddingBottom: "15%" }}>Source</Typography>
                                                        </Grid>


                                                        {isPermanent ?
                                                            <Grid item xs={1} style={{ paddingRight: "10px" }}>


                                                                <Typography style={{ wordBreak: "break-all", marginBottom: "28%" }}>{offId}</Typography>

                                                                <TextareaAutosize aria-label="minimum height" rowsMax={4} placeholder="Minimum 3 rows" style={{ maxWidth: "75%" }}>{JSON.stringify(iternary)}</TextareaAutosize>
                                                            </Grid> : ''}


                                                        <Grid item xs={3} style={{ maxWidth: '75%', marginRight: '4%' }}>
                                                            <Typography style={{ paddingBottom: '3%', textAlignLast: 'center' }}>{owner}{' ('}{FareType}{')'}</Typography>

                                                            <Typography style={{ paddingRight: "55px" }}>{iternary.priceClass.ClassOfService.map((val ,inq) => {
                                                                return (
                                                                    <Grid>
                                                                    
                                                                      <ExpansionPanel square expanded={expanded === inq} onChange={handleChangePanel(inq)}>
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Typography className={classes.heading}>{stateSegment[val.SegementRef].Departure.AirportCode + ' - ' + stateSegment[val.SegementRef].Arrival.AirportCode}</Typography>
                                                                                {/* <b>
                                                                                    {
                                                                                        stateSegment[val.SegementRef].Departure.AirportCode + ' - ' + stateSegment[val.SegementRef].Arrival.AirportCode
                                                                                    }
                                                                                </b> */}
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails>
                                                                                <Typography className={classes.content}>
                                                                                    <br />
                                                                                    {'Baggage: ' + val.Baggage.Allowance + val.Baggage.Unit}
                                                                                    <br />
                                                                                    {'SeatsLeft: ' + val.Code.SeatsLeft + ' ' + 'BookingClass: ' + val.Code.Value}
                                                                                    <br />
                                                                                    {'MarketingCarrier: ' + stateSegment[val.SegementRef].MarketingCarrier.AirlineID + ' ' + stateSegment[val.SegementRef].MarketingCarrier.FlightNumber}
                                                                                    <br />
                                                                                    {'OperatingCarrier: ' + stateSegment[val.SegementRef].OperatingCarrier.AirlineID + ' ' + stateSegment[val.SegementRef].OperatingCarrier.FlightNumber}
                                                                                    <br />
                                                                                    {'Duration: ' + stateSegment[val.SegementRef].FlightDetail.FlightDuration.Value.split(' ')[0] + stateSegment[val.SegementRef].FlightDetail.FlightDuration.Value.split(' ')[1] + ' ' + stateSegment[val.SegementRef].FlightDetail.FlightDuration.Value.split(' ')[2] + stateSegment[val.SegementRef].FlightDetail.FlightDuration.Value.split(' ')[3]}
                                                                                    <br />
                                                                                    {stateSegment[val.SegementRef].Departure.Date}{' '}{stateSegment[val.SegementRef].Departure.Time}{' - '}{stateSegment[val.SegementRef].Arrival.Date}{'  '}{stateSegment[val.SegementRef].Arrival.Time}
                                                                                </Typography>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                    </Grid>
                                                                )
                                                            })}</Typography>
                                                        </Grid>



                                                        <Grid item xs={3} style={{ marginRight: '4%', maxWidth: '32%' }}>


                                                            <Typography className={classes.content}>
                                                                <paper style={{fontStyle:"italic", fontWeight:"bold"}}>TotalPrice</paper>
                                                                 <br />
                                                                {'Booking: ' + iternary.TotalPrice.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.TotalPrice.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>





                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>TaxPrice</paper>
                                                                
                                                                <br />
                                                                {'Booking: ' + iternary.TaxPrice.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.TaxPrice.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>





                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>PortalDiscount</paper>

                                                                
                                                                 <br />
                                                                {'Booking: ' + iternary.PortalDiscount.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.PortalDiscount.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>





                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>PerPerson</paper>

                                                                
                                                                 <br />
                                                                {'Booking: ' + iternary.PerPerson.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.PerPerson.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>

                                                           

                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>BasePrice</paper>

                                                                
                                                                 <br />
                                                                {'Booking: ' + iternary.BasePrice.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.BasePrice.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>


                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>AgencyCommission</paper>
                                                                  
                                                                
                                                                 <br />
                                                                {'Booking: ' + iternary.BasePrice.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.BasePrice.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>

                                                            <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>AgencyYqCommission</paper>

                                                                
                                                                 <br />
                                                                {'Booking: ' + iternary.AgencyYqCommission.BookingCurrencyPrice}{' '}{iternary.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.AgencyYqCommission.EquivCurrencyPrice}{' '}{iternary.EquivCurrencyCode}
                                                            </Typography>


                                                            {iternary.PortalMarkup != undefined ? (

                                                                <Typography className={classes.content}>
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>PortalMarkup</paper>

                                                                    
                                                                 <br />
                                                                    {'Booking: ' + iternary.PortalMarkup.BookingCurrencyPrice}{' '}{iternary.PortalSurcharge.BookingCurrencyCode}{' '}{'Equiv: ' + iternary.PortalMarkup.EquivCurrencyPrice}{' '}{iternary.PortalSurcharge.EquivCurrencyCode}
                                                                </Typography>

                                                            ) : ('')
                                                            }


                                                            {/* <Typography className={classes.heading}>  Fare BasisCode</Typography> */}


                                                            <Typography className={classes.content} >
                                                            <paper style={{fontStyle:"italic", fontWeight:"bold"}}>FareBasisCode</paper>


                                                                
                                                                 <br />
                                                                {iternary.priceClass.ClassOfService.map((fareVal) => {
                                                                    return (


                                                                        <Typography className={classes.content}>
                                                                            {stateSegment[fareVal.SegementRef].Departure.AirportCode + ' - ' + stateSegment[fareVal.SegementRef].Arrival.AirportCode}{'/'}{fareVal.FareBasisCode}

                                                                        </Typography>
                                                                    )

                                                                })
                                                                }
                                                            </Typography>



                                                            <Typography className={classes.content} style={{fontStyle:"italic", fontWeight:"bold"}} >
                                                                {'Payment: ' + iternary.PaymentMode + '-' + Object.keys(FopList[0].CC.Types).join()}

                                                            </Typography>
                                                            <Typography className={classes.content}  style={{fontStyle:"italic", fontWeight:"bold"}}>
                                                                {'Refundable: ' + iternary.OfferItem[0].Refundable}

                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography className={classes.content}>{'ApiCurrency: ' + iternary.ApiCurrency}</Typography>
                                                            <Typography className={classes.content}>{'ApiCurrencyExRate: ' + iternary.ApiCurrencyExRate}</Typography>
                                                            <Typography className={classes.content}>{'ReqCurrency: ' + iternary.ReqCurrency}</Typography>
                                                            <Typography className={classes.content}>{'ReqCurrencyExRate: ' + iternary.ReqCurrencyExRate}</Typography>
                                                            <Typography className={classes.content}>{'PosCurrency: ' + iternary.PosCurrency}</Typography>
                                                            <Typography className={classes.content}>{'PosCurrencyExRate: ' + iternary.PosCurrencyExRate}</Typography>
                                                            <Typography className={classes.content}>{'PosCurrencyExRate: ' + iternary.PosCurrencyExRate}</Typography>
                                                            <Typography className={classes.content}>{'SupplierId: ' + iternary.SupplierId}</Typography>
                                                            <Typography className={classes.content}>{'FopRef: ' + iternary.FopRef}</Typography>


                                                        </Grid>





                                                    </Grid>
                                                ) : ('')
                                            }


                                        </Grid>

                                    </Card>

                                    <div >
                                        &nbsp;

                                    </div>
                                </Grid>


                            )

                        }




                        )
                        }
                    </Grid>
                )

                    : ('')
            }



        </React.Fragment>
    )


}
































