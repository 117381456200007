import React from "react";
import ReactDom from "react-dom"
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import SocketIO from "./lib/socketIo"

ReactDom.render(
    <LayoutProvider>
        <UserProvider>
            <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <App />
                <SocketIO />
            </ThemeProvider>
        </UserProvider>
    </LayoutProvider>,
    document.getElementById("root"),
);

serviceWorker.unregister();