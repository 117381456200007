
export default {
    elementSelector: function (elem, selector) {
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    },
    capitalize: function (str) {
        return str.replace(/\b\w/g, l => l.toUpperCase());
    },
    csvValidator: function (file,callback) {
        if (window.FileReader) {
            var reader = new FileReader();
            // Read file into memory as UTF-8      
            reader.readAsText(file);
            // Handle errors load
            reader.onload = loadHandler;
            reader.onerror = errorHandler;
        } else {
            alert('FileReader are not supported in this browser.');
        }
   

        function loadHandler(event) {
            var csv = event.target.result;
            console.log(csv.split(/\r\n|\n/))
            callback(csv.split(/\r\n|\n/).length-1);
        }

        function errorHandler(evt) {
            if (evt.target.error.name == "NotReadableError") {
                alert("Canno't read file !");
            }
        }
    }
}