import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
// import { makeStyles } from '@material-ui/core/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { wrap } from "module";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useUserDispatch, signOut } from "../../context/UserContext";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommonFunction from '../../lib/commonFunction';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import superagent from 'superagent';
import Fade from '@material-ui/core/Fade';
import { VerticalAlignTop } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
// import { config } from "react-transition-group";
import config  from '../../config/config';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Tooltip from '@material-ui/core/Tooltip';




const useStyles = makeStyles(theme => ({

  tableCell: {
    // paddingRight: '20px',
    padding: "10px !important",
    // paddingTop:10,
    // paddingBottom:10,
    // wordBreak:"break-word",

  },
  // overflowWrap:"break",
  table: {
    // display: 'block',
    // borderCollapse:'seperate',
    // borderSpacing:'50px ,0'
    // width: '100%',
    // overflowX: 'auto',
    // minWidth: 100,
    //   borderCollapse:'collapse',
    //   wordBreak:"break-word",
    
  },
  root1: {
    // width: '100%',
    //   paddingTop:'22px',
    //   overflowWrap:"break",
    //   paddingBottom:'26px',
    //   paddingLeft:'16px',
    // overflowX: "scroll",
    wordBreak: "break-word",

  },
  empty: {
    width: '50px'
  },
  position:
  {
    position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(2),
    cursor:'pointer'
 
    // minBlockSize:5%,
  },
  Typography: {
    left: theme.spacing(8),
    marginBottom: "2%"
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),

    width: '55%',
  },
  padding:
  {
    padding: "5px"
  },
  TableHeadStyle:{
    verticalAlign:'Top'
  },
  dataCreated:{
    paddingTop: '15px',
    paddingRight:' 25px',
    paddingLeft: '25px',
    paddingBottom: '25px'
  },
  Pointer:{
    cursor:'Pointer'
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paginationRow:{
    minWidth:'100%'
  }

}));
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };



  return (
    <div className={classes.pagination}>
     <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}



export default function SesEmail(props) {
  var userDispatch = useUserDispatch();
  const [data, setData] = React.useState([])
  const classes = useStyles();
  var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  const [open, setOpen] = React.useState(false);
  var startDate = new Date();
  const [From, handleFrom] = useState(new Date());
  const [To, handleTo] = useState(new Date());
  const [error, setError] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  const emptyRows = data.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);

  };


  const handleClose = () => {
    setOpen(false);
    setError(false)
  };
  const filterResponse = () => {


    superagent.post(config.apiUrl+"/api/Flight/getSesEmail")
      .set('login-key', localStorage.getItem('token'))
      .send({
        "fromDate": From.toISOString().slice(0, 10),
        "toDate": To.toISOString().slice(0, 10),
      })
      .then((res) => {
        if (res.body.status == true) {
          setData([...res.body.data])
          handleClose();

        } else if (res.body.status == false) {
          // alert(errorData[res.body.message])
          setError(true)
        }
        //  res.body.message == ""
      })


  }

  const fetchData = async () => {

    const result = await axios(
      config.apiUrl+'/api/Flight/getSesEmail',
      { headers: { 'login-key': localStorage.getItem('token') } }
    );
    if (result.data.status == true) {
      result.data.data = result.data.data.reverse(); 
      setData(result.data.data)
    }
    else {

      if(result.data.message === 'ERMWMSG3' || result.data.message === 'ERMWMSG4'|| result.data.message === 'ERMWMSG2'|| result.data.message === 'ERMWMSG5' ){

        alert(errorData[result.data.message])
        signOut(userDispatch, props.history)
      }
      else if (result.data.status == false) {
        alert(errorData[result.data.message])
        props.history.goBack();

      }
    }
  };

  useEffect(() => {
    fetchData();

  }, []);

  const ViewDetails = (e) => {
    e.preventDefault();
    var messageId = CommonFunction.elementSelector(e.target, 'td').getAttribute('value');
    var dateFolder = CommonFunction.elementSelector(e.target, 'td').getAttribute('value1')

    window.open(config.apiUrl+"/logs/" + dateFolder + "/" + messageId + ".txt");
  }


  return (
    <React.Fragment>
      <Typography className={classes.Typography} variant="h3" component="h2" >
        SES Email
      </Typography>
      <div>
        {/* <Button variant="outlined" className={classes.position} color="primary" onClick={handleClickOpen}> */}
        <SearchIcon  variant="outlined" className={classes.position} color="primary" onClick={handleClickOpen} style={{fontSize:"xx-large"}} /> 
    {/* </Button> */}

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <div className={classes.padding}>
            <DialogContent>
              <Typography className={classes.Typography} variant="h3" component="h2" >
                Search
      </Typography>
              <div className={classes.padding}>
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label="From"
                        value={From}
                        onChange={handleFrom}
                        format="yyyy-MM-dd"
                        animateYearScrolling
                        className={classes.textField}

                      // minDate={startDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <DatePicker
                        autoOk
                        label="To"
                        value={To}
                        format="yyyy-MM-dd"
                        onChange={handleTo}
                        animateYearScrolling
                        className={classes.textField}
                        minDate={From + 1}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                </Grid>


              </div>
              {error ?
                <Fade in={error}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    No Data Found
                 </Typography>
                </Fade> : ''}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" className={classes.position} color="primary" onClick={filterResponse}>
                Submit
    </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>



      <div>

        <Paper>
          <Table className={classes.table} >

            <Thead className = {classes.TableHeadStyle}>

              <Tr>






                <Th className={classes.dataCreated}>Date Created</Th>
                {/* <Th className={classes.empty}></Th> */}
                <Th style={{ padding: '15px' }}> Notification</Th>
                <Th style={{ padding: '15px' }}>Status</Th>
                <Th style={{ padding: '15px' }}> Email From</Th>
                <Th style={{ padding: '15px' }} >Email Reply To</Th>
                <Th style={{ padding: '15px' }}>Email Date</Th>
                <Th style={{ padding: '15px' }}>Email To</Th>
                <Th style={{ padding: '15px' }}>Email Subject</Th>
                <Th style={{ padding: '15px' }}>Email From Name</Th>







              </Tr>

            </Thead>


            <Tbody className={classes.root1}>
            {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((item, itInx) => {
             

                return (

                  <Tr key={'item' + itInx}>

                    {/* <Td >{itInx + 1}</Td> */}
                    {/* <Td >{item.id}</Td> */}
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.date_created.slice(0, 10) + ' ' + item.date_created.slice(11, 16)}</Td>
                    {/* <Td className={classes.empty}></Td> */}
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.notification_type}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.status}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_from ? item.email_from : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_replyto ? item.email_replyto : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_date ? item.email_date : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_to ? item.email_to.split(',').map((val, i) => { return (<span>{val}{' '}</span>) }) : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_subject ? item.email_subject : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }}>{item.email_from_name ? item.email_from_name : '_'}</Td>
                    <Td ClassName={classes.tableCell} style={{ padding: '15px' }} value={item.message_id} value1={item.date_folder}>


                      <span onClick={ViewDetails} >

                        <VisibilityIcon className={classes.Pointer} color="primary" />

                      </span>
                    </Td>




                  </Tr>

                )
              }
              )

              }
              </Tbody>
                  {emptyRows > 0 && (
            <Tr style={{ height: 53 * emptyRows }}>
              <Td colSpan={6} />
            </Tr>
          )}
                <TableFooter>
          <TableRow>
            <TablePagination className={classes.paginationRow}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={10}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>

          </Table>

        </Paper>

      </div>

    </React.Fragment>
  )
}

