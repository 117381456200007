import React from "react";
import superagent from "superagent";
// import { config } from "react-transition-group";
import config from '../config/config'
import Errors from '../config/Errors'

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, token: action.token, roleMenu: action.roleMenu, data: action.data,notifications:action.notifications };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, token: '', roleMenu: [], data: [],notifications : [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}





function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    roleMenu: JSON.parse(localStorage.getItem('roleMenu')) || [],
    data: JSON.parse(localStorage.getItem('data')) || [],
    notifications:JSON.parse(localStorage.getItem('notification')) || [],
  });


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}

      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, Dashboard, ForgotPassword, LoginPage, ErrorRes };

// ###########################################################

function loginUser(
  dispatch, login, password, history, setIsLoading, setError) {


  setIsLoading(true);

  superagent.post(config.apiUrl + "/api/Flight/login")
    .send({
      "email": login,
      "password": password
    })
    .then((response) => {

      if (response.body.status === true) {
        setError(false);
        setTimeout(() => {
          // var roleMenu = response.body.roleMenu
          // const MyContext = React.createContext(roleMenu);
          localStorage.setItem('roleMenu', JSON.stringify(response.body.roleMenu))
          localStorage.setItem('token', response.body.token)
          localStorage.setItem('data', JSON.stringify(response.body.data))
          localStorage.setItem('notification', JSON.stringify(response.body.notification));
          // window.location.reload();

          dispatch({ type: "LOGIN_SUCCESS", token: response.body.token, roleMenu: response.body.roleMenu, data: response.body.data });
          setError(null);
          setIsLoading(false);
          var roleMenus = ''
          var roles = response.body.roleMenu.roles
          var menus = response.body.roleMenu.menus
          var link = '';
          var menuCode = menus[0]['menu_code'];
          link = config.loginReq[menuCode];
          history.push(link);
        }, 60);
        



      }
      else {
        setError(true);
        alert(JSON.parse(localStorage.getItem('errorMsg'))[response.body.message])
        history.push("/app/login");

      }
    })

}



function signOut(dispatch, history) {

  localStorage.removeItem("token");

  localStorage.removeItem("roleMenu");
  localStorage.removeItem("data");
  localStorage.removeItem("notification");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

function Dashboard(dispatch, history) {
  var roleMenus = ''
  var data = JSON.parse(localStorage.getItem('roleMenu'))

  var roles = data.roles;
  var menus = data.menus;
  var link = '';
  var menuCode = menus[0]['menu_code'];
  link = config.loginReq[menuCode];
  history.push(link);
  // history.push("/app/lcc");
}
function ForgotPassword(dispatch, history) {
  history.push("/forgotPassword");
}
function LoginPage(dispatch, history) {
  history.push("/login");
}
function ErrorRes() {
  superagent.post(config.apiUrl + '/api/Flight/messages')

    .then((res) => {
      if (res.status == 200) {
        localStorage.setItem('errorMsg', JSON.stringify(res.body))
      }
    })

}
ErrorRes();