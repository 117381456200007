import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";

import { email as emailRegex } from "./regexes.js";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import UserForm from "../../pages/User_form/userform"
import superagent from 'superagent';
import config from '../../config/config'
import Errors from '../../config/Errors'

// styles
import useStyles from "./styles";

// logo
import logo from "./NDC.jpg";


// context
import { useUserDispatch, LoginPage } from "../../context/UserContext";

function ForgetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
var errorData = JSON.parse(localStorage.getItem('errorMsg'))
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  // var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [validateEmailErr, setValidateEmailErr] = useState(false)
  var [validatePasswordErr, setValidatePasswordErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [alert , setAlert ]  = React.useState(false);
  var [mess , setMess] = useState('')
var [errmsg ,setErrmsg] = useState('')
  // const [ global , setGlobal]=useState('');

  const validation = () => {
    setMessage(false)
    setAlert(false)
    if (!emailRegex.test(emailValue)) {
      setError(true)
    }

    else {
      superagent.post(config.apiUrl + '/api/flight/forgotPassword')
        .send({
          "email": emailValue,
        })

        .then((response) => {
          // console.log(response)
          if (response.body.status == true) {
            setMessage(true)
            setMess(errorData[response.body.message])
          } else if( response.body.status == false){
            setAlert(true)
            setErrmsg(errorData[response.body.message])
          }
        })
    }
    // else {
    //   forgotPassword(
    //       userDispatch,
    //       emailValue,

    //       props.history,
    //       setIsLoading,
    //       setError,
    //       )

    // }


  }
  const handleEmail = (e) => {
    setValidateEmailErr(false)
    setEmailValue(e.target.value)
  }
  const validateEmail = () => {
    var email = emailValue;
    if (!emailRegex.test(email)) {
      setValidateEmailErr(true)
    }
    else {
      setValidateEmailErr(false)

    }
  }

  // const handlePasswordChange = (e) =>{
  //     setPasswordValue( passwordValue.length < 8 ? (null) : (setError(true)));
  // }


  return (
    <Grid container className={classes.container}>

      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Clarity Insight</Typography>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="forgot password" classes={{ root: classes.tab }} />
            {/* <Tab label="New User" classes={{ root: classes.tab }} /> */}
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>

              <div className={classes.formDividerContainer}>
              </div>

              {message ?
                <Typography color="secondary" >{mess}</Typography> : ''}
                              {alert ? (<Typography color="secondary">{errmsg}</Typography>):(null)}

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                // onChange={e => setEmailValue(e.target.value)}
                onChange={handleEmail}

                margin="normal"
                placeholder="Email Adress"
                type="email"
                onBlur={validateEmail}
                helperText={validateEmailErr ? <Typography color="Secondary">{Errors.EmailErrors} </Typography> : ''}
                fullWidth
              />

              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                      disabled={
                        emailValue.length === 0 || validateEmailErr == true
                      }
                      // onChange={validation}

                      onClick={
                        validation
                        // loginUser(
                        //   userDispatch,
                        //   emailValue,
                        //   passwordValue,
                        //   props.history,
                        //   setIsLoading,
                        //   setError,
                        //   )

                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                    >
                      Submit
                  </Button>
                  )}
                <Button
                  // hidden
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => LoginPage(userDispatch, props.history)}
                >
                 Back to Login
                </Button>


              </div>
            </React.Fragment>
          )}

        </div>

      </div>
    </Grid>
  );
}

export default withRouter(ForgetPassword);
