import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { email as emailRegex } from "./regexes.js";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import Errors from "../../config/Errors"


// styles
import useStyles from "./styles";

// logo
import logo from "./NDC.jpg";


// context
import { useUserDispatch, loginUser, ForgotPassword } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  // var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [validateEmailErr, setValidateEmailErr] = useState(false)
  var [validatePasswordErr, setValidatePasswordErr] = useState(false);


  const validation = () => {
    if (passwordValue.length < 8) {
      setError(true)
    } else if (!emailRegex.test(emailValue)) {
      setError(true)
    }


    else {
      loginUser(
        userDispatch,
        emailValue,
        passwordValue,
        props.history,
        setIsLoading,
        setError,
      )

    }

  }
  const handleEmail = (e) => {
    setValidateEmailErr(false)
    setEmailValue(e.target.value)
  }
  const validateEmail = () => {
    var email = emailValue;
    if (!emailRegex.test(email)) {
      setValidateEmailErr(true)
    }
    else {
      setValidateEmailErr(false)

    }
  }
  const handlePassword = (e) => {
    setValidatePasswordErr(false)
    setPasswordValue(e.target.value)
  }
  const validatePassword = () => {
    var password = passwordValue;
    if (password.length < 8) {
      setValidatePasswordErr(true)
    }
  }

  // const handlePasswordChange = (e) =>{
  //     setPasswordValue( passwordValue.length < 8 ? (null) : (setError(true)));
  // }


  return (
    <Grid container className={classes.container}>

      <div className={classes.logotypeContainer}>
        <span style={{ backgroundColor: '#FFFFFF', borderRadius: '8px' }}><img src={logo} alt="logo" className={classes.logotypeImage} /></span>
        <Typography className={classes.logotypeText}>Clarity Insight</Typography>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            {/* <Tab label="New User" classes={{ root: classes.tab }} /> */}
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>

              <div className={classes.formDividerContainer}>
              </div>

              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {/* Something is wrong with your login or password :( */}

                  {/* <div>
                     Password value should be 8 characters

                     </div>   */}

                </Typography>
              </Fade>


              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                // onChange={e => setEmailValue(e.target.value)}
                onChange={handleEmail}

                margin="normal"
                placeholder="Email Address"
                // autoComplete="off"
                type="email"
                onBlur={validateEmail}
                helperText={validateEmailErr ? <Typography color="Secondary">{Errors.EmailErrors}</Typography> : ''}
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                // onChange={e => setPasswordValue(e.target.value)}
                onChange={handlePassword}
                onBlur={validatePassword}
                helperText={validatePasswordErr ? <Typography color="Secondary"> {Errors.passwordErrors} </Typography> : ''}

                // onChange = {handlePasswordChange}
                margin="normal"
                placeholder="Password"
                type="password"

                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                      disabled={
                        emailValue.length === 0 || passwordValue.length < 8 || validatePasswordErr == true || validateEmailErr == true
                      }
                      // onChange={validation}

                      onClick={
                        validation
                        // loginUser(
                        //   userDispatch,
                        //   emailValue,
                        //   passwordValue,
                        //   props.history,
                        //   setIsLoading,
                        //   setError,
                        //   )

                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                    >
                      Login
                  </Button>
                  )}
                <Button
                  // hidden
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => ForgotPassword(userDispatch, props.history)}
                >
                  Forget Password
                </Button>
              </div>
            </React.Fragment>
          )}

        </div>

      </div>
    </Grid>
  );
}

export default withRouter(Login);